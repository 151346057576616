

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Int from "../../../node_modules/rescript/lib/es6/belt_Int.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Toolkit__Identifier from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/identifier/Toolkit__Identifier.bs.js";

var StoreId = Toolkit__Identifier.MakeString({});

var StoreCode = Toolkit__Identifier.MakeString({});

var ClientId = Toolkit__Identifier.MakeString({});

var ContactIdInt = Toolkit__Identifier.MakeInt({});

var ContactIdString = Toolkit__Identifier.MakeString({});

var DeliveryId = Toolkit__Identifier.MakeString({});

var DeliveryUuid = Toolkit__Identifier.MakeString({});

var RouteId = Toolkit__Identifier.MakeString({});

var RoutePlanId = Toolkit__Identifier.MakeString({});

var PacketId = Toolkit__Identifier.MakeString({});

var CommentId = Toolkit__Identifier.MakeString({});

var PictureId = Toolkit__Identifier.MakeString({});

var TransporterId = Toolkit__Identifier.MakeString({});

var include = Toolkit__Identifier.MakeInt({});

var toString = include.toString;

var toTransporterId = Curry.__1(toString);

function makeFromTransporterId(id) {
  return Belt_Option.getExn(Belt_Int.fromString(id));
}

var ProviderId_Id = include.Id;

var ProviderId_t_encode = include.t_encode;

var ProviderId_t_decode = include.t_decode;

var ProviderId_castAsString = include.castAsString;

var ProviderId = {
  Id: ProviderId_Id,
  t_encode: ProviderId_t_encode,
  t_decode: ProviderId_t_decode,
  toString: toString,
  castAsString: ProviderId_castAsString,
  toTransporterId: toTransporterId,
  makeFromTransporterId: makeFromTransporterId
};

var PlanId = Toolkit__Identifier.MakeString({});

var ZoneId = Toolkit__Identifier.MakeString({});

var ProjectId = Toolkit__Identifier.MakeString({});

var CourierId = Toolkit__Identifier.MakeString({});

var ClientCRMReference = Toolkit__Identifier.MakeString({});

var ClientRuleId = Toolkit__Identifier.MakeString({});

var ProviderRuleId = Toolkit__Identifier.MakeString({});

var CarrierId = Toolkit__Identifier.MakeString({});

var include$1 = Toolkit__Identifier.MakeInt({});

var toString$1 = include$1.toString;

var toCarrierId = Curry.__1(toString$1);

var toCourierId = Curry.__1(toString$1);

var ProviderCarrierId_Id = include$1.Id;

var ProviderCarrierId_t_encode = include$1.t_encode;

var ProviderCarrierId_t_decode = include$1.t_decode;

var ProviderCarrierId_castAsString = include$1.castAsString;

var ProviderCarrierId = {
  Id: ProviderCarrierId_Id,
  t_encode: ProviderCarrierId_t_encode,
  t_decode: ProviderCarrierId_t_decode,
  toString: toString$1,
  castAsString: ProviderCarrierId_castAsString,
  toCarrierId: toCarrierId,
  toCourierId: toCourierId
};

var ZoneTypeId = Toolkit__Identifier.MakeString({});

var TransporterAuthToken = Toolkit__Identifier.MakeString({});

var SkillId = Toolkit__Identifier.MakeString({});

var FeedbackSurveyToken = Toolkit__Identifier.MakeString({});

var DeliveryIdC1 = Toolkit__Identifier.MakeInt({});

var DeliverySlotRequestIdC1 = Toolkit__Identifier.MakeString({});

var SlotOptionId = Toolkit__Identifier.MakeString({});

var HubId = Toolkit__Identifier.MakeString({});

var HubSlugId = Toolkit__Identifier.MakeString({});

var OrderId = Toolkit__Identifier.MakeString({});

var OrderReference = Toolkit__Identifier.MakeString({});

var ParameterId = Toolkit__Identifier.MakeString({});

var CountryCode = Toolkit__Identifier.MakeString({});

var AreaId = Toolkit__Identifier.MakeString({});

var OrderJobTriggerId = Toolkit__Identifier.MakeString({});

var WarehouseCode = Toolkit__Identifier.MakeString({});

var ProviderAccountId = Toolkit__Identifier.MakeString({});

var include$2 = Toolkit__Identifier.MakeInt({});

var toString$2 = include$2.toString;

var toCarrierId$1 = Curry.__1(toString$2);

function makeFromCarrierId(id) {
  return Belt_Option.getExn(Belt_Int.fromString(id));
}

function makeFromProviderCarrierId(id) {
  return Belt_Option.getExn(Belt_Int.fromString(Curry._1(toString$1, id)));
}

var EmployeeId_Id = include$2.Id;

var EmployeeId_t_encode = include$2.t_encode;

var EmployeeId_t_decode = include$2.t_decode;

var EmployeeId_castAsString = include$2.castAsString;

var EmployeeId = {
  Id: EmployeeId_Id,
  t_encode: EmployeeId_t_encode,
  t_decode: EmployeeId_t_decode,
  toString: toString$2,
  castAsString: EmployeeId_castAsString,
  toCarrierId: toCarrierId$1,
  makeFromCarrierId: makeFromCarrierId,
  makeFromProviderCarrierId: makeFromProviderCarrierId
};

var UserId = Toolkit__Identifier.MakeString({});

var OrderDeliveryPointId = Toolkit__Identifier.MakeString({});

var DamagePicturesId = Toolkit__Identifier.MakeInt({});

var include$3 = Toolkit__Identifier.MakeString({});

function make(barcode) {
  return decodeURIComponent(barcode);
}

function display(barcode) {
  return barcode;
}

function toString$3(barcode) {
  return encodeURIComponent(barcode);
}

var Barcode_Id = include$3.Id;

var Barcode_t_encode = include$3.t_encode;

var Barcode_t_decode = include$3.t_decode;

var Barcode_Dict = include$3.Dict;

var Barcode = {
  Id: Barcode_Id,
  t_encode: Barcode_t_encode,
  t_decode: Barcode_t_decode,
  Dict: Barcode_Dict,
  make: make,
  display: display,
  toString: toString$3
};

var include$4 = Toolkit__Identifier.MakeString({});

function make$1(ref) {
  return decodeURIComponent(ref);
}

function display$1(ref) {
  return ref;
}

function toString$4(ref) {
  return encodeURIComponent(ref);
}

var DeliveryReference_Id = include$4.Id;

var DeliveryReference_t_encode = include$4.t_encode;

var DeliveryReference_t_decode = include$4.t_decode;

var DeliveryReference_Dict = include$4.Dict;

var DeliveryReference = {
  Id: DeliveryReference_Id,
  t_encode: DeliveryReference_t_encode,
  t_decode: DeliveryReference_t_decode,
  Dict: DeliveryReference_Dict,
  make: make$1,
  display: display$1,
  toString: toString$4
};

var BillingEntityId = Toolkit__Identifier.MakeInt({});

var DeliveryRequestUUID = Toolkit__Identifier.MakeString({});

var PaymentTransactionUUID = Toolkit__Identifier.MakeString({});

var FreightForwarderId = Toolkit__Identifier.MakeInt({});

var VehicleFormatId = Toolkit__Identifier.MakeStringOrInt({});

export {
  StoreId ,
  StoreCode ,
  ClientId ,
  ContactIdInt ,
  ContactIdString ,
  DeliveryId ,
  DeliveryUuid ,
  RouteId ,
  RoutePlanId ,
  PacketId ,
  CommentId ,
  PictureId ,
  TransporterId ,
  ProviderId ,
  PlanId ,
  ZoneId ,
  ProjectId ,
  CourierId ,
  ClientCRMReference ,
  ClientRuleId ,
  ProviderRuleId ,
  CarrierId ,
  ProviderCarrierId ,
  ZoneTypeId ,
  TransporterAuthToken ,
  SkillId ,
  FeedbackSurveyToken ,
  DeliveryIdC1 ,
  DeliverySlotRequestIdC1 ,
  SlotOptionId ,
  HubId ,
  HubSlugId ,
  OrderId ,
  OrderReference ,
  ParameterId ,
  CountryCode ,
  AreaId ,
  OrderJobTriggerId ,
  WarehouseCode ,
  ProviderAccountId ,
  EmployeeId ,
  UserId ,
  OrderDeliveryPointId ,
  DamagePicturesId ,
  Barcode ,
  DeliveryReference ,
  BillingEntityId ,
  DeliveryRequestUUID ,
  PaymentTransactionUUID ,
  FreightForwarderId ,
  VehicleFormatId ,
}
/* StoreId Not a pure module */
