

import * as Ky from "ky";
import * as Qs from "qs";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";

var kyInstance = Ky.default.create({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/geo",
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

var keyBase = "V6_Geo";

function t_encode(v) {
  switch (v) {
    case "streetAddress" :
        return "streetAddress";
    case "route" :
        return "route";
    case "postalCode" :
        return "postalCode";
    case "city" :
        return "city";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("streetAddress" === v) {
          return {
                  TAG: "Ok",
                  _0: "streetAddress"
                };
        } else if ("route" === v) {
          return {
                  TAG: "Ok",
                  _0: "route"
                };
        } else if ("postalCode" === v) {
          return {
                  TAG: "Ok",
                  _0: "postalCode"
                };
        } else if ("city" === v) {
          return {
                  TAG: "Ok",
                  _0: "city"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Level = {
  t_encode: t_encode,
  t_decode: t_decode
};

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "location",
                    false,
                    Spice.stringToJson(v.location)
                  ],
                  [
                    "level",
                    false,
                    Spice.optionToJson(t_encode, v.level)
                  ],
                  [
                    "center",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Coordinates.t_encode, v.center)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var $$location = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "location"), null));
  if ($$location.TAG === "Ok") {
    var level = Spice.optionFromJson(t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "level"), null));
    if (level.TAG === "Ok") {
      var center = Spice.optionFromJson(Toolkit__Decoders.Coordinates.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "center"), null));
      if (center.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  location: $$location._0,
                  level: level._0,
                  center: center._0
                }
              };
      }
      var e = center._0;
      return {
              TAG: "Error",
              _0: {
                path: ".center" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = level._0;
    return {
            TAG: "Error",
            _0: {
              path: ".level" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = $$location._0;
  return {
          TAG: "Error",
          _0: {
            path: ".location" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function administrativeArea_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ],
                  [
                    "code2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.code2)
                  ],
                  [
                    "code3",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.code3)
                  ],
                  [
                    "countryCode",
                    false,
                    Spice.stringToJson(v.countryCode)
                  ]
                ]));
}

function location_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ]
                ]));
}

function location_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
    if (line2.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
        if (city.TAG === "Ok") {
          var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
          if (country.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      line1: line1._0,
                      line2: line2._0,
                      postalCode: postalCode._0,
                      city: city._0,
                      country: country._0
                    }
                  };
          }
          var e = country._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".country" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = city._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".city" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = line2._0;
    return {
            TAG: "Error",
            _0: {
              path: ".line2" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function administrativeArea_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
    if (code.TAG === "Ok") {
      var code2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "code2"), null));
      if (code2.TAG === "Ok") {
        var code3 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "code3"), null));
        if (code3.TAG === "Ok") {
          var countryCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "countryCode"), null));
          if (countryCode.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      name: name._0,
                      code: code._0,
                      code2: code2._0,
                      code3: code3._0,
                      countryCode: countryCode._0
                    }
                  };
          }
          var e = countryCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".countryCode" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = code3._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".code3" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = code2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".code2" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = code._0;
    return {
            TAG: "Error",
            _0: {
              path: ".code" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function matchingLocation_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var coordinates = Toolkit__Decoders.Coordinates.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "coordinates"), null));
  if (coordinates.TAG === "Ok") {
    var $$location = location_decode(Belt_Option.getWithDefault(Js_dict.get(v, "location"), null));
    if ($$location.TAG === "Ok") {
      var administrativeArea = Spice.optionFromJson(administrativeArea_decode, Belt_Option.getWithDefault(Js_dict.get(v, "administrativeArea"), null));
      if (administrativeArea.TAG === "Ok") {
        var level = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "level"), null));
        if (level.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    coordinates: coordinates._0,
                    location: $$location._0,
                    administrativeArea: administrativeArea._0,
                    level: level._0
                  }
                };
        }
        var e = level._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".level" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = administrativeArea._0;
      return {
              TAG: "Error",
              _0: {
                path: ".administrativeArea" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = $$location._0;
    return {
            TAG: "Error",
            _0: {
              path: ".location" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = coordinates._0;
  return {
          TAG: "Error",
          _0: {
            path: ".coordinates" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function matchingLocation_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "coordinates",
                    false,
                    Toolkit__Decoders.Coordinates.t_encode(v.coordinates)
                  ],
                  [
                    "location",
                    false,
                    location_encode(v.location)
                  ],
                  [
                    "administrativeArea",
                    false,
                    Spice.optionToJson(administrativeArea_encode, v.administrativeArea)
                  ],
                  [
                    "level",
                    false,
                    t_encode(v.level)
                  ]
                ]));
}

function response_encode(v) {
  return Spice.optionToJson(matchingLocation_encode, v);
}

function response_decode(v) {
  return Spice.optionFromJson(matchingLocation_decode, v);
}

function config(body) {
  return {
          kyInstance: kyInstance,
          path: "geocoding",
          requestOptions: {
            method: "POST",
            json: body_encode(body)
          },
          mapCustomErrors: (function (error) {
              var match = error.response;
              return $$Promise.resolved(match !== undefined && match.status === 404 ? ({
                              TAG: "Custom",
                              _0: "notFound"
                            }) : ({
                              TAG: "Unknown",
                              _0: error
                            }));
            })
        };
}

var GeocodeLocation = {
  Level: Level,
  body_encode: body_encode,
  body_decode: body_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  matchingLocation_encode: matchingLocation_encode,
  matchingLocation_decode: matchingLocation_decode,
  location_encode: location_encode,
  location_decode: location_decode,
  administrativeArea_encode: administrativeArea_encode,
  administrativeArea_decode: administrativeArea_decode,
  config: config
};

function argument_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "q",
                    false,
                    Spice.stringToJson(v.q)
                  ],
                  [
                    "center",
                    true,
                    Spice.optionToJson(Spice.stringToJson, v.center)
                  ],
                  [
                    "limit",
                    false,
                    Spice.intToJson(v.limit)
                  ],
                  [
                    "lang",
                    false,
                    Spice.stringToJson(v.lang)
                  ]
                ]));
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var q = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "q"), null));
  if (q.TAG === "Ok") {
    var center = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "center"), null));
    if (center.TAG === "Ok") {
      var limit = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "limit"), null));
      if (limit.TAG === "Ok") {
        var lang = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lang"), null));
        if (lang.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    q: q._0,
                    center: center._0,
                    limit: limit._0,
                    lang: lang._0
                  }
                };
        }
        var e = lang._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".lang" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = limit._0;
      return {
              TAG: "Error",
              _0: {
                path: ".limit" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = center._0;
    return {
            TAG: "Error",
            _0: {
              path: ".center" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = q._0;
  return {
          TAG: "Error",
          _0: {
            path: ".q" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function suggestion_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var houseNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "houseNumber"), null));
  if (houseNumber.TAG === "Ok") {
    var street = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "street"), null));
    if (street.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
        if (city.TAG === "Ok") {
          var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
          if (country.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      houseNumber: houseNumber._0,
                      street: street._0,
                      postalCode: postalCode._0,
                      city: city._0,
                      country: country._0
                    }
                  };
          }
          var e = country._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".country" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = city._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".city" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = street._0;
    return {
            TAG: "Error",
            _0: {
              path: ".street" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = houseNumber._0;
  return {
          TAG: "Error",
          _0: {
            path: ".houseNumber" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function suggestion_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "houseNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.houseNumber)
                  ],
                  [
                    "street",
                    false,
                    Spice.stringToJson(v.street)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ]
                ]));
}

function response_encode$1(v) {
  return Spice.arrayToJson(suggestion_encode, v);
}

function response_decode$1(v) {
  return Spice.arrayFromJson(suggestion_decode, v);
}

function config$1(params) {
  return {
          kyInstance: kyInstance,
          path: "autocomplete",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(argument_encode(params))
          },
          key: [
            keyBase,
            "Autocomplete",
            JSON.stringify(argument_encode(params))
          ]
        };
}

var Autocomplete = {
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  suggestion_encode: suggestion_encode,
  suggestion_decode: suggestion_decode,
  config: config$1
};

var baseUrl = "external/countries";

var argument_encode$1 = Spice.unitToJson;

var argument_decode$1 = Spice.unitFromJson;

function areaType_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "level",
                    false,
                    Spice.intToJson(v.level)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function areaType_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var level = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "level"), null));
  if (level.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                level: level._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = level._0;
  return {
          TAG: "Error",
          _0: {
            path: ".level" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function country_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    Curry._1(Identifiers.CountryCode.t_encode, v.code)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function country_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Curry._1(Identifiers.CountryCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                code: code._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function countryWithAreaTypes_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "country",
                    false,
                    country_encode(v.country)
                  ],
                  [
                    "areasTypes",
                    false,
                    Spice.arrayToJson(areaType_encode, v.areasTypes)
                  ]
                ]));
}

function countryWithAreaTypes_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var country = country_decode(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
  if (country.TAG === "Ok") {
    var areasTypes = Spice.arrayFromJson(areaType_decode, Belt_Option.getWithDefault(Js_dict.get(v, "areasTypes"), null));
    if (areasTypes.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                country: country._0,
                areasTypes: areasTypes._0
              }
            };
    }
    var e = areasTypes._0;
    return {
            TAG: "Error",
            _0: {
              path: ".areasTypes" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = country._0;
  return {
          TAG: "Error",
          _0: {
            path: ".country" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$2(v) {
  return Spice.arrayToJson(countryWithAreaTypes_encode, v);
}

function response_decode$2(v) {
  return Spice.arrayFromJson(countryWithAreaTypes_decode, v);
}

function config$2(param) {
  return {
          kyInstance: kyInstance,
          path: baseUrl,
          requestOptions: {
            method: "GET"
          },
          key: [
            keyBase,
            "GetCountries"
          ]
        };
}

var GetCountries = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  areaType_encode: areaType_encode,
  areaType_decode: areaType_decode,
  country_encode: country_encode,
  country_decode: country_decode,
  countryWithAreaTypes_encode: countryWithAreaTypes_encode,
  countryWithAreaTypes_decode: countryWithAreaTypes_decode,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  config: config$2
};

function argument_encode$2(v) {
  return Curry._1(Identifiers.CountryCode.t_encode, v);
}

function argument_decode$2(v) {
  return Curry._1(Identifiers.CountryCode.t_decode, v);
}

function subLevel_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function subLevel_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                code: code._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function countryLevel_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "subLevels",
                    false,
                    Spice.arrayToJson(subLevel_encode, v.subLevels)
                  ]
                ]));
}

function countryLevel_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var subLevels = Spice.arrayFromJson(subLevel_decode, Belt_Option.getWithDefault(Js_dict.get(v, "subLevels"), null));
      if (subLevels.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  code: code._0,
                  name: name._0,
                  subLevels: subLevels._0
                }
              };
      }
      var e = subLevels._0;
      return {
              TAG: "Error",
              _0: {
                path: ".subLevels" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$3(v) {
  return Spice.arrayToJson(countryLevel_encode, v);
}

function response_decode$3(v) {
  return Spice.arrayFromJson(countryLevel_decode, v);
}

var error_encode = Spice.unitToJson;

var error_decode = Spice.unitFromJson;

function config$3(countryCode) {
  return {
          kyInstance: kyInstance,
          path: baseUrl + "/" + countryCode + "/levels",
          requestOptions: {
            method: "GET"
          },
          key: [
            keyBase,
            "GetCountryLevels",
            countryCode
          ]
        };
}

var GetCountryLevels = {
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  subLevel_encode: subLevel_encode,
  subLevel_decode: subLevel_decode,
  countryLevel_encode: countryLevel_encode,
  countryLevel_decode: countryLevel_decode,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  error_encode: error_encode,
  error_decode: error_decode,
  config: config$3
};

var Zones = {
  baseUrl: baseUrl,
  GetCountries: GetCountries,
  GetCountryLevels: GetCountryLevels
};

var kyInstance$1 = kyInstance.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/geo/public"
    });

var argument_encode$3 = argument_encode;

var argument_decode$3 = argument_decode;

function response_encode$4(v) {
  return Spice.arrayToJson(suggestion_encode, v);
}

function response_decode$4(v) {
  return Spice.arrayFromJson(suggestion_decode, v);
}

function config$4(params) {
  return {
          kyInstance: kyInstance$1,
          path: "autocomplete",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(argument_encode(params))
          },
          key: [
            keyBase,
            "PublicAutocomplete",
            JSON.stringify(argument_encode(params))
          ]
        };
}

var Autocomplete$1 = {
  argument_encode: argument_encode$3,
  argument_decode: argument_decode$3,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  config: config$4
};

var Public = {
  kyInstance: kyInstance$1,
  Autocomplete: Autocomplete$1
};

var Decoders;

export {
  Decoders ,
  kyInstance ,
  keyBase ,
  GeocodeLocation ,
  Autocomplete ,
  Zones ,
  Public ,
}
/* kyInstance Not a pure module */
