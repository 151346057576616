

import * as Ky from "ky";
import * as Axios from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Axios.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Toolkit__Request.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as ColiswebApi__V5_Utils from "./ColiswebApi__V5_Utils.bs.js";
import * as Decoders__UnitMeasure from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Decoders__UnitMeasure.bs.js";
import * as ColiswebApi__V5_Shared from "./ColiswebApi__V5_Shared.bs.js";

var arg = ColiswebApi__Env.v5ApiUrl;

var arg$1 = ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000;

function config(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  var partial_arg = true;
  return function (param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
    var tmp = {};
    if (param !== undefined) {
      tmp.url = param;
    }
    if (param$1 !== undefined) {
      tmp._method = param$1;
    }
    if (arg !== undefined) {
      tmp.baseURL = arg;
    }
    if (param$2 !== undefined) {
      tmp.transformRequest = Caml_option.valFromOption(param$2);
    }
    if (param$3 !== undefined) {
      tmp.transformResponse = Caml_option.valFromOption(param$3);
    }
    if (param$4 !== undefined) {
      tmp.headers = Caml_option.valFromOption(param$4);
    }
    if (param$5 !== undefined) {
      tmp.params = Caml_option.valFromOption(param$5);
    }
    if (param$6 !== undefined) {
      tmp.paramsSerializer = Caml_option.valFromOption(param$6);
    }
    if (param$7 !== undefined) {
      tmp.data = Caml_option.valFromOption(param$7);
    }
    if (arg$1 !== undefined) {
      tmp.timeout = arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.withCredentials = partial_arg;
    }
    if (param$8 !== undefined) {
      tmp.adapter = Caml_option.valFromOption(param$8);
    }
    if (param$9 !== undefined) {
      tmp.auth = Caml_option.valFromOption(param$9);
    }
    if (param$10 !== undefined) {
      tmp.responseType = param$10;
    }
    if (param$11 !== undefined) {
      tmp.xsrfCookieName = param$11;
    }
    if (param$12 !== undefined) {
      tmp.xsrfHeaderName = param$12;
    }
    if (param$13 !== undefined) {
      tmp.onUploadProgress = Caml_option.valFromOption(param$13);
    }
    if (param$14 !== undefined) {
      tmp.onDownloadProgress = Caml_option.valFromOption(param$14);
    }
    if (param$15 !== undefined) {
      tmp.maxContentLength = param$15;
    }
    if (param$16 !== undefined) {
      tmp.validateStatus = Caml_option.valFromOption(param$16);
    }
    if (param$17 !== undefined) {
      tmp.maxRedirects = param$17;
    }
    if (param$18 !== undefined) {
      tmp.socketPath = param$18;
    }
    if (param$19 !== undefined) {
      tmp.proxy = Caml_option.valFromOption(param$19);
    }
    if (param$20 !== undefined) {
      tmp.cancelToken = Caml_option.valFromOption(param$20);
    }
    return tmp;
  };
}

function baseUrl(transporterId) {
  return "/transporters/" + transporterId;
}

function deliveriesBaseUrl(transporterId, deliveryId) {
  return "/transporters/" + transporterId + "/deliveries/" + deliveryId;
}

var prefixBaseUrl = ColiswebApi__Env.v5ApiUrl + "/transporters";

var kyInstance = Ky.default.create({
      prefixUrl: prefixBaseUrl,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

function t_encode(v) {
  switch (v) {
    case "unavailability" :
        return "unavailability";
    case "slot_too_short" :
        return "slot_too_short";
    case "distance" :
        return "distance";
    case "price" :
        return "price";
    case "maladjusted" :
        return "maladjusted";
    case "other" :
        return "other";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("unavailability" === v) {
          return {
                  TAG: "Ok",
                  _0: "unavailability"
                };
        } else if ("slot_too_short" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_too_short"
                };
        } else if ("distance" === v) {
          return {
                  TAG: "Ok",
                  _0: "distance"
                };
        } else if ("price" === v) {
          return {
                  TAG: "Ok",
                  _0: "price"
                };
        } else if ("maladjusted" === v) {
          return {
                  TAG: "Ok",
                  _0: "maladjusted"
                };
        } else if ("other" === v) {
          return {
                  TAG: "Ok",
                  _0: "other"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var enumArray = [
  "unavailability",
  "slot_too_short",
  "distance",
  "price",
  "maladjusted",
  "other"
];

var RefusalCause = {
  t_encode: t_encode,
  t_decode: t_decode,
  enumArray: enumArray
};

function t_encode$1(v) {
  if (v === "heavy") {
    return "heavy";
  } else {
    return "lightweight";
  }
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "heavy"
                };
        } else if ("lightweight" === v) {
          return {
                  TAG: "Ok",
                  _0: "lightweight"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var arrayEnum = [
  "heavy",
  "lightweight"
];

var DeliveryFlowType = {
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  arrayEnum: arrayEnum
};

function t_encode$2(v) {
  if (v === "terminated") {
    return ["terminated"];
  } else if (v === "proposed") {
    return ["proposed"];
  } else {
    return ["accepted"];
  }
}

function t_decode$2(v) {
  var json_arr = Js_json.classify(v);
  if (typeof json_arr !== "object") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  if (json_arr.TAG !== "JSONArray") {
    return Spice.error(undefined, "Not a polyvariant", v);
  }
  var json_arr$1 = json_arr._0;
  if (json_arr$1.length === 0) {
    return Spice.error(undefined, "Expected polyvariant, found empty array", v);
  }
  var tagged = Js_array.map(Js_json.classify, json_arr$1);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match === "object" && match.TAG === "JSONString") {
    switch (match._0) {
      case "accepted" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "accepted"
                  };
          }
      case "proposed" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "proposed"
                  };
          }
      case "terminated" :
          if (tagged.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to polyvariant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "terminated"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid polymorphic constructor", Belt_Array.getExn(json_arr$1, 0));
}

var PlanState = {
  t_encode: t_encode$2,
  t_decode: t_decode$2
};

function t_encode$3(v) {
  switch (v) {
    case "maybe_lift" :
        return "maybe_lift";
    case "with_lift" :
        return "with_lift";
    case "no_lift" :
        return "no_lift";
    
  }
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("maybe_lift" === v) {
          return {
                  TAG: "Ok",
                  _0: "maybe_lift"
                };
        } else if ("with_lift" === v) {
          return {
                  TAG: "Ok",
                  _0: "with_lift"
                };
        } else if ("no_lift" === v) {
          return {
                  TAG: "Ok",
                  _0: "no_lift"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function toBoolean(value) {
  switch (value) {
    case "with_lift" :
        return true;
    case "maybe_lift" :
    case "no_lift" :
        return false;
    
  }
}

function toString(value) {
  switch (value) {
    case "maybe_lift" :
        return "maybe_lift";
    case "with_lift" :
        return "with_lift";
    case "no_lift" :
        return "without_lift";
    
  }
}

var AddressLift = {
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  toBoolean: toBoolean,
  toString: toString
};

function t_encode$4(v) {
  switch (v) {
    case "inadapated_vehicle" :
        return "inadapated_vehicle";
    case "handling_not_possible" :
        return "handling_not_possible";
    case "unavailable_order" :
        return "unavailable_order";
    case "waiting" :
        return "waiting";
    case "closed_pickup_point" :
        return "closed_pickup_point";
    case "packaging_missing" :
        return "packaging_missing";
    case "wrong_pickup_address" :
        return "wrong_pickup_address";
    case "insufficient_packaging" :
        return "insufficient_packaging";
    case "non_available_recipient" :
        return "non_available_recipient";
    case "damaged_packages" :
        return "damaged_packages";
    case "non_compliant_packages" :
        return "non_compliant_packages";
    case "other_prestation_asked" :
        return "other_prestation_asked";
    case "delivery_impossible" :
        return "delivery_impossible";
    case "wrong_shipping_address" :
        return "wrong_shipping_address";
    case "recovery_refusal" :
        return "recovery_refusal";
    case "lack_of_capacity" :
        return "lack_of_capacity";
    case "truck_problem" :
        return "truck_problem";
    case "cost_not_worth" :
        return "cost_not_worth";
    case "road_closed" :
        return "road_closed";
    case "unserved_zone" :
        return "unserved_zone";
    case "missing_packets" :
        return "missing_packets";
    
  }
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("inadapated_vehicle" === v) {
          return {
                  TAG: "Ok",
                  _0: "inadapated_vehicle"
                };
        } else if ("handling_not_possible" === v) {
          return {
                  TAG: "Ok",
                  _0: "handling_not_possible"
                };
        } else if ("unavailable_order" === v) {
          return {
                  TAG: "Ok",
                  _0: "unavailable_order"
                };
        } else if ("waiting" === v) {
          return {
                  TAG: "Ok",
                  _0: "waiting"
                };
        } else if ("closed_pickup_point" === v) {
          return {
                  TAG: "Ok",
                  _0: "closed_pickup_point"
                };
        } else if ("packaging_missing" === v) {
          return {
                  TAG: "Ok",
                  _0: "packaging_missing"
                };
        } else if ("wrong_pickup_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "wrong_pickup_address"
                };
        } else if ("insufficient_packaging" === v) {
          return {
                  TAG: "Ok",
                  _0: "insufficient_packaging"
                };
        } else if ("non_available_recipient" === v) {
          return {
                  TAG: "Ok",
                  _0: "non_available_recipient"
                };
        } else if ("damaged_packages" === v) {
          return {
                  TAG: "Ok",
                  _0: "damaged_packages"
                };
        } else if ("non_compliant_packages" === v) {
          return {
                  TAG: "Ok",
                  _0: "non_compliant_packages"
                };
        } else if ("other_prestation_asked" === v) {
          return {
                  TAG: "Ok",
                  _0: "other_prestation_asked"
                };
        } else if ("delivery_impossible" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_impossible"
                };
        } else if ("wrong_shipping_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "wrong_shipping_address"
                };
        } else if ("recovery_refusal" === v) {
          return {
                  TAG: "Ok",
                  _0: "recovery_refusal"
                };
        } else if ("lack_of_capacity" === v) {
          return {
                  TAG: "Ok",
                  _0: "lack_of_capacity"
                };
        } else if ("truck_problem" === v) {
          return {
                  TAG: "Ok",
                  _0: "truck_problem"
                };
        } else if ("cost_not_worth" === v) {
          return {
                  TAG: "Ok",
                  _0: "cost_not_worth"
                };
        } else if ("road_closed" === v) {
          return {
                  TAG: "Ok",
                  _0: "road_closed"
                };
        } else if ("unserved_zone" === v) {
          return {
                  TAG: "Ok",
                  _0: "unserved_zone"
                };
        } else if ("missing_packets" === v) {
          return {
                  TAG: "Ok",
                  _0: "missing_packets"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Problem = {
  t_encode: t_encode$4,
  t_decode: t_decode$4
};

function response_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.CourierId.t_encode, v.id)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.stringToJson(v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "phone",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone)
                  ]
                ]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.CourierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var firstName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
    if (firstName.TAG === "Ok") {
      var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
      if (lastName.TAG === "Ok") {
        var phone = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone"), null));
        if (phone.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    firstName: firstName._0,
                    lastName: lastName._0,
                    phone: phone._0
                  }
                };
        }
        var e = phone._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phone" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = lastName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastName" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = firstName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstName" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function exec(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode, undefined, ColiswebApi__Env.v5ApiUrl + "/transporters/" + param[0] + "/carriers/" + param[1]);
}

var Config = {
  response_encode: response_encode,
  response_decode: response_decode,
  exec: exec
};

var $$Request = Toolkit__Request.Make({
      exec: exec
    });

var GetCourierDetails = {
  Config: Config,
  $$Request: $$Request
};

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.TransporterId.t_encode, v.id)
                  ],
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "role",
                    false,
                    Spice.stringToJson(v.role)
                  ],
                  [
                    "authToken",
                    false,
                    Curry._1(Identifiers.TransporterAuthToken.t_encode, v.authToken)
                  ],
                  [
                    "freightForwarderName",
                    false,
                    Spice.stringToJson(v.freightForwarderName)
                  ],
                  [
                    "transporterName",
                    false,
                    Spice.stringToJson(v.transporterName)
                  ]
                ]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
    if (email.TAG === "Ok") {
      var role = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
      if (role.TAG === "Ok") {
        var authToken = Curry._1(Identifiers.TransporterAuthToken.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "authToken"), null));
        if (authToken.TAG === "Ok") {
          var freightForwarderName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "freightForwarderName"), null));
          if (freightForwarderName.TAG === "Ok") {
            var transporterName = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "transporterName"), Spice.stringFromJson), {
                  TAG: "Ok",
                  _0: ""
                });
            if (transporterName.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        id: id._0,
                        email: email._0,
                        role: role._0,
                        authToken: authToken._0,
                        freightForwarderName: freightForwarderName._0,
                        transporterName: transporterName._0
                      }
                    };
            }
            var e = transporterName._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".transporterName" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = freightForwarderName._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".freightForwarderName" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = authToken._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".authToken" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = role._0;
      return {
              TAG: "Error",
              _0: {
                path: ".role" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = email._0;
    return {
            TAG: "Error",
            _0: {
              path: ".email" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function exec$1(transporterId) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$1, undefined, ColiswebApi__Env.v5ApiUrl + "/transporters/" + transporterId + "/user");
}

var Config$1 = {
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  exec: exec$1
};

var $$Request$1 = Toolkit__Request.Make({
      exec: exec$1
    });

var GetTransporterInformations = {
  Config: Config$1,
  $$Request: $$Request$1
};

function t_encode$5(v) {
  switch (v) {
    case "proposed" :
        return "proposed";
    case "accepted" :
        return "accepted";
    case "terminated" :
        return "terminated";
    
  }
}

function t_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("proposed" === v) {
          return {
                  TAG: "Ok",
                  _0: "proposed"
                };
        } else if ("accepted" === v) {
          return {
                  TAG: "Ok",
                  _0: "accepted"
                };
        } else if ("terminated" === v) {
          return {
                  TAG: "Ok",
                  _0: "terminated"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var TabFilter = {
  t_encode: t_encode$5,
  t_decode: t_decode$5
};

function filters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "timeSlot",
                    false,
                    ColiswebApi__V5_Utils.TimeSlot.t_encode(v.timeSlot)
                  ],
                  [
                    "deliveryId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.deliveryId)
                  ],
                  [
                    "ref",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.ref)
                  ],
                  [
                    "pickup",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.pickup)
                  ],
                  [
                    "dropoff",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.dropoff)
                  ],
                  [
                    "statusProvider",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(ColiswebApi__V5_Shared.StatusProvider.t_encode, param);
                          }), v.statusProvider)
                  ],
                  [
                    "carrierIds",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Identifiers.CourierId.t_encode, param);
                          }), v.carrierIds)
                  ],
                  [
                    "flowTypes",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(Spice.stringToJson, param);
                          }), v.flowTypes)
                  ],
                  [
                    "withRoute",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Toolkit__Decoders.$$Boolean.t_encode, v.withRoute)
                  ]
                ]));
}

function filters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var timeSlot = ColiswebApi__V5_Utils.TimeSlot.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
  if (timeSlot.TAG === "Ok") {
    var deliveryId = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
    if (deliveryId.TAG === "Ok") {
      var ref = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "ref"), null));
      if (ref.TAG === "Ok") {
        var pickup = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
        if (pickup.TAG === "Ok") {
          var dropoff = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "dropoff"), null));
          if (dropoff.TAG === "Ok") {
            var statusProvider = Toolkit__Decoders.$$Option.t_decode((function (param) {
                    return Spice.arrayFromJson(ColiswebApi__V5_Shared.StatusProvider.t_decode, param);
                  }), Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
            if (statusProvider.TAG === "Ok") {
              var carrierIds = Toolkit__Decoders.$$Option.t_decode((function (param) {
                      return Spice.arrayFromJson(Identifiers.CourierId.t_decode, param);
                    }), Belt_Option.getWithDefault(Js_dict.get(v, "carrierIds"), null));
              if (carrierIds.TAG === "Ok") {
                var flowTypes = Toolkit__Decoders.$$Option.t_decode((function (param) {
                        return Spice.arrayFromJson(Spice.stringFromJson, param);
                      }), Belt_Option.getWithDefault(Js_dict.get(v, "flowTypes"), null));
                if (flowTypes.TAG === "Ok") {
                  var withRoute = Toolkit__Decoders.$$Option.t_decode(Toolkit__Decoders.$$Boolean.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "withRoute"), null));
                  if (withRoute.TAG === "Ok") {
                    return {
                            TAG: "Ok",
                            _0: {
                              timeSlot: timeSlot._0,
                              deliveryId: deliveryId._0,
                              ref: ref._0,
                              pickup: pickup._0,
                              dropoff: dropoff._0,
                              statusProvider: statusProvider._0,
                              carrierIds: carrierIds._0,
                              flowTypes: flowTypes._0,
                              withRoute: withRoute._0
                            }
                          };
                  }
                  var e = withRoute._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".withRoute" + e.path,
                            message: e.message,
                            value: e.value
                          }
                        };
                }
                var e$1 = flowTypes._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".flowTypes" + e$1.path,
                          message: e$1.message,
                          value: e$1.value
                        }
                      };
              }
              var e$2 = carrierIds._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".carrierIds" + e$2.path,
                        message: e$2.message,
                        value: e$2.value
                      }
                    };
            }
            var e$3 = statusProvider._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".statusProvider" + e$3.path,
                      message: e$3.message,
                      value: e$3.value
                    }
                  };
          }
          var e$4 = dropoff._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".dropoff" + e$4.path,
                    message: e$4.message,
                    value: e$4.value
                  }
                };
        }
        var e$5 = pickup._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".pickup" + e$5.path,
                  message: e$5.message,
                  value: e$5.value
                }
              };
      }
      var e$6 = ref._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ref" + e$6.path,
                message: e$6.message,
                value: e$6.value
              }
            };
    }
    var e$7 = deliveryId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryId" + e$7.path,
              message: e$7.message,
              value: e$7.value
            }
          };
  }
  var e$8 = timeSlot._0;
  return {
          TAG: "Error",
          _0: {
            path: ".timeSlot" + e$8.path,
            message: e$8.message,
            value: e$8.value
          }
        };
}

function sort_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "timeSlot",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.timeSlot)
                  ],
                  [
                    "pickup",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.pickup)
                  ],
                  [
                    "dropoff",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.dropoff)
                  ]
                ]));
}

function sort_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var timeSlot = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
  if (timeSlot.TAG === "Ok") {
    var pickup = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
    if (pickup.TAG === "Ok") {
      var dropoff = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "dropoff"), null));
      if (dropoff.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  timeSlot: timeSlot._0,
                  pickup: pickup._0,
                  dropoff: dropoff._0
                }
              };
      }
      var e = dropoff._0;
      return {
              TAG: "Error",
              _0: {
                path: ".dropoff" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = pickup._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pickup" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = timeSlot._0;
  return {
          TAG: "Error",
          _0: {
            path: ".timeSlot" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode(v.sort)
                  ],
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
      if (page.TAG === "Ok") {
        var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
        if (pageSize.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    filters: filters._0,
                    sort: sort._0,
                    page: page._0,
                    pageSize: pageSize._0
                  }
                };
        }
        var e = pageSize._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".pageSize" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = page._0;
      return {
              TAG: "Error",
              _0: {
                path: ".page" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function carrier_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.CourierId.t_encode, v.id)
                  ],
                  [
                    "carrierNumber",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.carrierNumber)
                  ],
                  [
                    "isDeleted",
                    false,
                    Spice.boolToJson(v.isDeleted)
                  ]
                ]));
}

function carrier_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.CourierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var carrierNumber = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "carrierNumber"), null));
    if (carrierNumber.TAG === "Ok") {
      var isDeleted = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isDeleted"), Spice.boolFromJson), {
            TAG: "Ok",
            _0: false
          });
      if (isDeleted.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  carrierNumber: carrierNumber._0,
                  isDeleted: isDeleted._0
                }
              };
      }
      var e = isDeleted._0;
      return {
              TAG: "Error",
              _0: {
                path: ".isDeleted" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = carrierNumber._0;
    return {
            TAG: "Error",
            _0: {
              path: ".carrierNumber" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function dimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lengthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
  if (lengthCm.TAG === "Ok") {
    var widthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
    if (widthCm.TAG === "Ok") {
      var heightCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
      if (heightCm.TAG === "Ok") {
        var volumeM3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
        if (volumeM3.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    lengthCm: lengthCm._0,
                    widthCm: widthCm._0,
                    heightCm: heightCm._0,
                    volumeM3: volumeM3._0
                  }
                };
        }
        var e = volumeM3._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".volumeM3" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = heightCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".heightCm" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = widthCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".widthCm" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = lengthCm._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lengthCm" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function dimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lengthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.widthCm)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.heightCm)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeM3)
                  ]
                ]));
}

function packaging_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var dimensions = dimensions_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dimensions"), null));
  if (dimensions.TAG === "Ok") {
    var weightKg = Curry._1(Decoders__UnitMeasure.Weight.Kg.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
    if (weightKg.TAG === "Ok") {
      var heaviestWeightKg = Spice.optionFromJson(Decoders__UnitMeasure.Weight.Kg.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "heaviestWeightKg"), null));
      if (heaviestWeightKg.TAG === "Ok") {
        var longestPacketLength = Spice.optionFromJson(Decoders__UnitMeasure.Dimension.Cm.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "longestPacketLength"), null));
        if (longestPacketLength.TAG === "Ok") {
          var largestVolumeCm3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "largestVolumeCm3"), null));
          if (largestVolumeCm3.TAG === "Ok") {
            var packetsCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "packetsCount"), null));
            if (packetsCount.TAG === "Ok") {
              var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
              if (description.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          dimensions: dimensions._0,
                          weightKg: weightKg._0,
                          heaviestWeightKg: heaviestWeightKg._0,
                          longestPacketLength: longestPacketLength._0,
                          largestVolumeCm3: largestVolumeCm3._0,
                          packetsCount: packetsCount._0,
                          description: description._0
                        }
                      };
              }
              var e = description._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".description" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = packetsCount._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".packetsCount" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = largestVolumeCm3._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".largestVolumeCm3" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = longestPacketLength._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".longestPacketLength" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = heaviestWeightKg._0;
      return {
              TAG: "Error",
              _0: {
                path: ".heaviestWeightKg" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = weightKg._0;
    return {
            TAG: "Error",
            _0: {
              path: ".weightKg" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = dimensions._0;
  return {
          TAG: "Error",
          _0: {
            path: ".dimensions" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function heftingOptions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var isSidewalk = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSidewalk"), null));
  if (isSidewalk.TAG === "Ok") {
    var isInRoom = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isInRoom"), null));
    if (isInRoom.TAG === "Ok") {
      var isTrailed = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isTrailed"), null));
      if (isTrailed.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  isSidewalk: isSidewalk._0,
                  isInRoom: isInRoom._0,
                  isTrailed: isTrailed._0
                }
              };
      }
      var e = isTrailed._0;
      return {
              TAG: "Error",
              _0: {
                path: ".isTrailed" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = isInRoom._0;
    return {
            TAG: "Error",
            _0: {
              path: ".isInRoom" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = isSidewalk._0;
  return {
          TAG: "Error",
          _0: {
            path: ".isSidewalk" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function point_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var line1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
    if (line1.TAG === "Ok") {
      var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
      if (firstName.TAG === "Ok") {
        var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
        if (lastName.TAG === "Ok") {
          var postalCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
          if (postalCode.TAG === "Ok") {
            var city = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "city"), (function (param) {
                        return Spice.optionFromJson(Spice.stringFromJson, param);
                      })), {
                  TAG: "Ok",
                  _0: undefined
                });
            if (city.TAG === "Ok") {
              var coords = Spice.optionFromJson(Toolkit__Decoders.Coordinates.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "coords"), null));
              if (coords.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          name: name._0,
                          line1: line1._0,
                          firstName: firstName._0,
                          lastName: lastName._0,
                          postalCode: postalCode._0,
                          city: city._0,
                          coords: coords._0
                        }
                      };
              }
              var e = coords._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".coords" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = city._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".city" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = postalCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".postalCode" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = lastName._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".lastName" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = firstName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".firstName" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = line1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".line1" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function heftingOptions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "isSidewalk",
                    false,
                    Spice.boolToJson(v.isSidewalk)
                  ],
                  [
                    "isInRoom",
                    false,
                    Spice.boolToJson(v.isInRoom)
                  ],
                  [
                    "isTrailed",
                    false,
                    Spice.boolToJson(v.isTrailed)
                  ]
                ]));
}

function point_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.name)
                  ],
                  [
                    "line1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line1)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.city)
                  ],
                  [
                    "coords",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Coordinates.t_encode, v.coords)
                  ]
                ]));
}

function packaging_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "dimensions",
                    false,
                    dimensions_encode(v.dimensions)
                  ],
                  [
                    "weightKg",
                    false,
                    Curry._1(Decoders__UnitMeasure.Weight.Kg.t_encode, v.weightKg)
                  ],
                  [
                    "heaviestWeightKg",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Weight.Kg.t_encode, v.heaviestWeightKg)
                  ],
                  [
                    "longestPacketLength",
                    false,
                    Spice.optionToJson(Decoders__UnitMeasure.Dimension.Cm.t_encode, v.longestPacketLength)
                  ],
                  [
                    "largestVolumeCm3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.largestVolumeCm3)
                  ],
                  [
                    "packetsCount",
                    false,
                    Spice.intToJson(v.packetsCount)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ]
                ]));
}

function delivery_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "isArchived",
                    false,
                    Spice.boolToJson(v.isArchived)
                  ],
                  [
                    "reference",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.reference)
                  ],
                  [
                    "secondaryReference",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.secondaryReference)
                  ],
                  [
                    "pickup",
                    false,
                    Spice.optionToJson(point_encode, v.pickup)
                  ],
                  [
                    "shipping",
                    false,
                    Spice.optionToJson(point_encode, v.shipping)
                  ],
                  [
                    "haveTransporterComments",
                    false,
                    Spice.boolToJson(v.haveTransporterComments)
                  ],
                  [
                    "timeSlot",
                    false,
                    ColiswebApi__V5_Utils.TimeSlot.t_encode(v.timeSlot)
                  ],
                  [
                    "previousTimeSlot",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.TimeSlot.t_encode, v.previousTimeSlot)
                  ],
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "priceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithoutTaxEur)
                  ],
                  [
                    "priceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithTaxEur)
                  ],
                  [
                    "heftingOptions",
                    false,
                    heftingOptions_encode(v.heftingOptions)
                  ],
                  [
                    "skills",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(ColiswebApi__V5_Utils.Skill.t_encode, param);
                          }), v.skills)
                  ],
                  [
                    "carrier",
                    false,
                    Spice.optionToJson(carrier_encode, v.carrier)
                  ],
                  [
                    "flowType",
                    false,
                    Spice.optionToJson(t_encode$1, v.flowType)
                  ],
                  [
                    "packaging",
                    false,
                    packaging_encode(v.packaging)
                  ]
                ]));
}

function delivery_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var isArchived = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isArchived"), Spice.boolFromJson), {
          TAG: "Ok",
          _0: false
        });
    if (isArchived.TAG === "Ok") {
      var reference = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "reference"), null));
      if (reference.TAG === "Ok") {
        var secondaryReference = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "secondaryReference"), null));
        if (secondaryReference.TAG === "Ok") {
          var pickup = Spice.optionFromJson(point_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
          if (pickup.TAG === "Ok") {
            var shipping = Spice.optionFromJson(point_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shipping"), null));
            if (shipping.TAG === "Ok") {
              var haveTransporterComments = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "haveTransporterComments"), null));
              if (haveTransporterComments.TAG === "Ok") {
                var timeSlot = ColiswebApi__V5_Utils.TimeSlot.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
                if (timeSlot.TAG === "Ok") {
                  var previousTimeSlot = Spice.optionFromJson(ColiswebApi__V5_Utils.TimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "previousTimeSlot"), null));
                  if (previousTimeSlot.TAG === "Ok") {
                    var statusProvider = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
                    if (statusProvider.TAG === "Ok") {
                      var priceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEur"), null));
                      if (priceWithoutTaxEur.TAG === "Ok") {
                        var priceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithTaxEur"), null));
                        if (priceWithTaxEur.TAG === "Ok") {
                          var heftingOptions = heftingOptions_decode(Belt_Option.getWithDefault(Js_dict.get(v, "heftingOptions"), null));
                          if (heftingOptions.TAG === "Ok") {
                            var skills = Spice.optionFromJson((function (param) {
                                    return Spice.arrayFromJson(ColiswebApi__V5_Utils.Skill.t_decode, param);
                                  }), Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                            if (skills.TAG === "Ok") {
                              var carrier = Spice.optionFromJson(carrier_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrier"), null));
                              if (carrier.TAG === "Ok") {
                                var flowType = Spice.optionFromJson(t_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "flowType"), null));
                                if (flowType.TAG === "Ok") {
                                  var packaging = packaging_decode(Belt_Option.getWithDefault(Js_dict.get(v, "packaging"), null));
                                  if (packaging.TAG === "Ok") {
                                    return {
                                            TAG: "Ok",
                                            _0: {
                                              id: id._0,
                                              isArchived: isArchived._0,
                                              reference: reference._0,
                                              secondaryReference: secondaryReference._0,
                                              pickup: pickup._0,
                                              shipping: shipping._0,
                                              haveTransporterComments: haveTransporterComments._0,
                                              timeSlot: timeSlot._0,
                                              previousTimeSlot: previousTimeSlot._0,
                                              statusProvider: statusProvider._0,
                                              priceWithoutTaxEur: priceWithoutTaxEur._0,
                                              priceWithTaxEur: priceWithTaxEur._0,
                                              heftingOptions: heftingOptions._0,
                                              skills: skills._0,
                                              carrier: carrier._0,
                                              flowType: flowType._0,
                                              packaging: packaging._0
                                            }
                                          };
                                  }
                                  var e = packaging._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".packaging" + e.path,
                                            message: e.message,
                                            value: e.value
                                          }
                                        };
                                }
                                var e$1 = flowType._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".flowType" + e$1.path,
                                          message: e$1.message,
                                          value: e$1.value
                                        }
                                      };
                              }
                              var e$2 = carrier._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".carrier" + e$2.path,
                                        message: e$2.message,
                                        value: e$2.value
                                      }
                                    };
                            }
                            var e$3 = skills._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".skills" + e$3.path,
                                      message: e$3.message,
                                      value: e$3.value
                                    }
                                  };
                          }
                          var e$4 = heftingOptions._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".heftingOptions" + e$4.path,
                                    message: e$4.message,
                                    value: e$4.value
                                  }
                                };
                        }
                        var e$5 = priceWithTaxEur._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".priceWithTaxEur" + e$5.path,
                                  message: e$5.message,
                                  value: e$5.value
                                }
                              };
                      }
                      var e$6 = priceWithoutTaxEur._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".priceWithoutTaxEur" + e$6.path,
                                message: e$6.message,
                                value: e$6.value
                              }
                            };
                    }
                    var e$7 = statusProvider._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".statusProvider" + e$7.path,
                              message: e$7.message,
                              value: e$7.value
                            }
                          };
                  }
                  var e$8 = previousTimeSlot._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".previousTimeSlot" + e$8.path,
                            message: e$8.message,
                            value: e$8.value
                          }
                        };
                }
                var e$9 = timeSlot._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".timeSlot" + e$9.path,
                          message: e$9.message,
                          value: e$9.value
                        }
                      };
              }
              var e$10 = haveTransporterComments._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".haveTransporterComments" + e$10.path,
                        message: e$10.message,
                        value: e$10.value
                      }
                    };
            }
            var e$11 = shipping._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".shipping" + e$11.path,
                      message: e$11.message,
                      value: e$11.value
                    }
                  };
          }
          var e$12 = pickup._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".pickup" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
        }
        var e$13 = secondaryReference._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".secondaryReference" + e$13.path,
                  message: e$13.message,
                  value: e$13.value
                }
              };
      }
      var e$14 = reference._0;
      return {
              TAG: "Error",
              _0: {
                path: ".reference" + e$14.path,
                message: e$14.message,
                value: e$14.value
              }
            };
    }
    var e$15 = isArchived._0;
    return {
            TAG: "Error",
            _0: {
              path: ".isArchived" + e$15.path,
              message: e$15.message,
              value: e$15.value
            }
          };
  }
  var e$16 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$16.path,
            message: e$16.message,
            value: e$16.value
          }
        };
}

function response_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveries",
                    false,
                    Spice.arrayToJson(delivery_encode, v.deliveries)
                  ],
                  [
                    "deliveriesCount",
                    false,
                    Spice.intToJson(v.deliveriesCount)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ]
                ]));
}

function response_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveries = Spice.arrayFromJson(delivery_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveries"), null));
  if (deliveries.TAG === "Ok") {
    var deliveriesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveriesCount"), null));
    if (deliveriesCount.TAG === "Ok") {
      var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
      if (pagesCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  deliveries: deliveries._0,
                  deliveriesCount: deliveriesCount._0,
                  pagesCount: pagesCount._0
                }
              };
      }
      var e = pagesCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagesCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = deliveriesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveriesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = deliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveries" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$2(param) {
  return Axios.WithResult.post(undefined, body_encode(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$2, undefined, "/transporters/" + param[0] + "/deliveries");
}

var Config$2 = {
  filters_encode: filters_encode,
  filters_decode: filters_decode,
  sort_encode: sort_encode,
  sort_decode: sort_decode,
  body_encode: body_encode,
  body_decode: body_decode,
  carrier_encode: carrier_encode,
  carrier_decode: carrier_decode,
  delivery_encode: delivery_encode,
  delivery_decode: delivery_decode,
  point_encode: point_encode,
  point_decode: point_decode,
  heftingOptions_encode: heftingOptions_encode,
  heftingOptions_decode: heftingOptions_decode,
  packaging_encode: packaging_encode,
  packaging_decode: packaging_decode,
  dimensions_encode: dimensions_encode,
  dimensions_decode: dimensions_decode,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  exec: exec$2
};

var $$Request$2 = Toolkit__Request.Make({
      exec: exec$2
    });

var GetDeliveries = {
  TabFilter: TabFilter,
  Config: Config$2,
  $$Request: $$Request$2
};

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "filters",
                    false,
                    filters_encode(v.filters)
                  ]]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              filters: filters._0
            }
          };
  }
  var e = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function argument_encode(v) {
  return [
          Curry._1(Identifiers.TransporterId.t_encode, v[0]),
          body_encode$1(v[1])
        ];
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.TransporterId.t_decode, v0);
  var match$1 = body_decode$1(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "count",
                    false,
                    Spice.intToJson(v.count)
                  ]]));
}

function response_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var count = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "count"), null));
  if (count.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              count: count._0
            }
          };
  }
  var e = count._0;
  return {
          TAG: "Error",
          _0: {
            path: ".count" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$3(param) {
  return Axios.WithResult.post(undefined, body_encode$1(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$3, undefined, "/transporters/" + param[0] + "/deliveries/count");
}

var Config$3 = {
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  exec: exec$3
};

var $$Request$3 = Toolkit__Request.Make({
      exec: exec$3
    });

var GetDeliveriesCounts = {
  Config: Config$3,
  $$Request: $$Request$3
};

var response_encode$4 = Spice.unitToJson;

var response_decode$4 = Spice.unitFromJson;

function exec$4(param) {
  return Axios.WithResult.post(undefined, Object.assign(body_encode(param[1]), {
                  email: param[2],
                  includeAddress: param[3]
                }), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$4, undefined, "/transporters/" + param[0] + "/deliveries/export");
}

var Config$4 = {
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  exec: exec$4
};

var $$Request$4 = Toolkit__Request.Make({
      exec: exec$4
    });

var PostExcelExport = {
  Config: Config$4,
  $$Request: $$Request$4
};

function t_encode$6(v) {
  switch (v) {
    case "not_authorized" :
        return "not_authorized";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "non_idle_delivery" :
        return "non_idle_delivery";
    
  }
}

function t_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("not_authorized" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_authorized"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("non_idle_delivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "non_idle_delivery"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var ErrorCause = {
  t_encode: t_encode$6,
  t_decode: t_decode$6
};

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "refusalCause",
                    false,
                    t_encode(v.refusalCause)
                  ],
                  [
                    "deliveryIds",
                    false,
                    Spice.arrayToJson(Identifiers.DeliveryId.t_encode, v.deliveryIds)
                  ]
                ]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var refusalCause = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "refusalCause"), null));
  if (refusalCause.TAG === "Ok") {
    var deliveryIds = Spice.arrayFromJson(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryIds"), null));
    if (deliveryIds.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                refusalCause: refusalCause._0,
                deliveryIds: deliveryIds._0
              }
            };
    }
    var e = deliveryIds._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryIds" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = refusalCause._0;
  return {
          TAG: "Error",
          _0: {
            path: ".refusalCause" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function nonAssignedDelivery_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var errorCause = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "errorCause"), null));
  if (errorCause.TAG === "Ok") {
    var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
    if (id.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                errorCause: errorCause._0,
                id: id._0
              }
            };
    }
    var e = id._0;
    return {
            TAG: "Error",
            _0: {
              path: ".id" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = errorCause._0;
  return {
          TAG: "Error",
          _0: {
            path: ".errorCause" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function nonAssignedDelivery_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "errorCause",
                    false,
                    t_encode$6(v.errorCause)
                  ],
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ]
                ]));
}

function response_encode$5(v) {
  return Spice.arrayToJson(nonAssignedDelivery_encode, v);
}

function response_decode$5(v) {
  return Spice.arrayFromJson(nonAssignedDelivery_decode, v);
}

function exec$5(param) {
  return Axios.WithResult.put(undefined, body_encode$2(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$5, undefined, "/transporters/" + param[0] + "/deliveries/refuse");
}

var Config$5 = {
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  nonAssignedDelivery_encode: nonAssignedDelivery_encode,
  nonAssignedDelivery_decode: nonAssignedDelivery_decode,
  exec: exec$5
};

var $$Request$5 = Toolkit__Request.Make({
      exec: exec$5
    });

var RefuseDelivery = {
  ErrorCause: ErrorCause,
  Config: Config$5,
  $$Request: $$Request$5
};

function t_encode$7(v) {
  if (v === "not_authorized") {
    return "not_authorized";
  } else {
    return "delivery_not_found";
  }
}

function t_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("not_authorized" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_authorized"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var ErrorCause$1 = {
  t_encode: t_encode$7,
  t_decode: t_decode$7
};

function body_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "carrierId",
                    false,
                    Curry._1(Identifiers.CourierId.t_encode, v.carrierId)
                  ],
                  [
                    "deliveryIds",
                    false,
                    Spice.arrayToJson(Identifiers.DeliveryId.t_encode, v.deliveryIds)
                  ]
                ]));
}

function body_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carrierId = Curry._1(Identifiers.CourierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrierId"), null));
  if (carrierId.TAG === "Ok") {
    var deliveryIds = Spice.arrayFromJson(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryIds"), null));
    if (deliveryIds.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                carrierId: carrierId._0,
                deliveryIds: deliveryIds._0
              }
            };
    }
    var e = deliveryIds._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryIds" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = carrierId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carrierId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function nonAssignedDelivery_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var errorCause = t_decode$7(Belt_Option.getWithDefault(Js_dict.get(v, "errorCause"), null));
  if (errorCause.TAG === "Ok") {
    var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
    if (id.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                errorCause: errorCause._0,
                id: id._0
              }
            };
    }
    var e = id._0;
    return {
            TAG: "Error",
            _0: {
              path: ".id" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = errorCause._0;
  return {
          TAG: "Error",
          _0: {
            path: ".errorCause" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function nonAssignedDelivery_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "errorCause",
                    false,
                    t_encode$7(v.errorCause)
                  ],
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ]
                ]));
}

function response_encode$6(v) {
  return Spice.arrayToJson(nonAssignedDelivery_encode$1, v);
}

function response_decode$6(v) {
  return Spice.arrayFromJson(nonAssignedDelivery_decode$1, v);
}

function exec$6(param) {
  return Axios.WithResult.post(undefined, body_encode$3(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$6, undefined, "/transporters/" + param[0] + "/deliveries/assign");
}

var Config$6 = {
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  nonAssignedDelivery_encode: nonAssignedDelivery_encode$1,
  nonAssignedDelivery_decode: nonAssignedDelivery_decode$1,
  exec: exec$6
};

var $$Request$6 = Toolkit__Request.Make({
      exec: exec$6
    });

var AcceptAndAssignDelivery = {
  ErrorCause: ErrorCause$1,
  Config: Config$6,
  $$Request: $$Request$6
};

function body_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "carrierId",
                    false,
                    Curry._1(Identifiers.CourierId.t_encode, v.carrierId)
                  ],
                  [
                    "deliveryIds",
                    false,
                    Spice.arrayToJson(Identifiers.DeliveryId.t_encode, v.deliveryIds)
                  ]
                ]));
}

function body_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carrierId = Curry._1(Identifiers.CourierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrierId"), null));
  if (carrierId.TAG === "Ok") {
    var deliveryIds = Spice.arrayFromJson(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryIds"), null));
    if (deliveryIds.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                carrierId: carrierId._0,
                deliveryIds: deliveryIds._0
              }
            };
    }
    var e = deliveryIds._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryIds" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = carrierId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carrierId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$7(v) {
  return Spice.arrayToJson(nonAssignedDelivery_encode$1, v);
}

function response_decode$7(v) {
  return Spice.arrayFromJson(nonAssignedDelivery_decode$1, v);
}

function exec$7(param) {
  return Axios.WithResult.put(undefined, body_encode$4(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$7, undefined, "/transporters/" + param[0] + "/deliveries/assign");
}

var Config$7 = {
  body_encode: body_encode$4,
  body_decode: body_decode$4,
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  exec: exec$7
};

var $$Request$7 = Toolkit__Request.Make({
      exec: exec$7
    });

var AssignDelivery = {
  Config: Config$7,
  $$Request: $$Request$7
};

function body_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "date",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.date)
                  ],
                  [
                    "toDelete",
                    false,
                    Spice.boolToJson(v.toDelete)
                  ]
                ]));
}

function body_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var date = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "date"), null));
  if (date.TAG === "Ok") {
    var toDelete = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "toDelete"), null));
    if (toDelete.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                date: date._0,
                toDelete: toDelete._0
              }
            };
    }
    var e = toDelete._0;
    return {
            TAG: "Error",
            _0: {
              path: ".toDelete" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = date._0;
  return {
          TAG: "Error",
          _0: {
            path: ".date" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$8 = Spice.unitToJson;

var response_decode$8 = Spice.unitFromJson;

function exec$8(param) {
  return Axios.WithResult.post(undefined, body_encode$5(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$8, undefined, "/transporters/" + param[0] + "/working_exceptions");
}

var Config$8 = {
  body_encode: body_encode$5,
  body_decode: body_decode$5,
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  exec: exec$8
};

var $$Request$8 = Toolkit__Request.Make({
      exec: exec$8
    });

var PostAutoRefusal = {
  Config: Config$8,
  $$Request: $$Request$8
};

function point_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "coords",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Coordinates.t_encode, v.coords)
                  ],
                  [
                    "name",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.name)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.country)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ],
                  [
                    "lift",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.Lift.t_encode, v.lift)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ],
                  [
                    "locationType",
                    false,
                    ColiswebApi__V5_Utils.LocationType.t_encode(v.locationType)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "hubSlugId",
                    false,
                    Spice.optionToJson(Identifiers.HubSlugId.t_encode, v.hubSlugId)
                  ]
                ]));
}

function point_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var coords = Spice.optionFromJson(Toolkit__Decoders.Coordinates.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "coords"), null));
  if (coords.TAG === "Ok") {
    var name = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
      if (firstName.TAG === "Ok") {
        var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
        if (lastName.TAG === "Ok") {
          var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
          if (postalCode.TAG === "Ok") {
            var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
            if (city.TAG === "Ok") {
              var country = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
              if (country.TAG === "Ok") {
                var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
                if (line1.TAG === "Ok") {
                  var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
                  if (line2.TAG === "Ok") {
                    var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
                    if (comment.TAG === "Ok") {
                      var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                      if (floor.TAG === "Ok") {
                        var lift = Spice.optionFromJson(ColiswebApi__V5_Utils.Lift.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lift"), null));
                        if (lift.TAG === "Ok") {
                          var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
                          if (phone1.TAG === "Ok") {
                            var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
                            if (phone2.TAG === "Ok") {
                              var locationType = ColiswebApi__V5_Utils.LocationType.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "locationType"), null));
                              if (locationType.TAG === "Ok") {
                                var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
                                if (storeId.TAG === "Ok") {
                                  var hubSlugId = Spice.optionFromJson(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubSlugId"), null));
                                  if (hubSlugId.TAG === "Ok") {
                                    return {
                                            TAG: "Ok",
                                            _0: {
                                              coords: coords._0,
                                              name: name._0,
                                              firstName: firstName._0,
                                              lastName: lastName._0,
                                              postalCode: postalCode._0,
                                              city: city._0,
                                              country: country._0,
                                              line1: line1._0,
                                              line2: line2._0,
                                              comment: comment._0,
                                              floor: floor._0,
                                              lift: lift._0,
                                              phone1: phone1._0,
                                              phone2: phone2._0,
                                              locationType: locationType._0,
                                              storeId: storeId._0,
                                              hubSlugId: hubSlugId._0
                                            }
                                          };
                                  }
                                  var e = hubSlugId._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".hubSlugId" + e.path,
                                            message: e.message,
                                            value: e.value
                                          }
                                        };
                                }
                                var e$1 = storeId._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".storeId" + e$1.path,
                                          message: e$1.message,
                                          value: e$1.value
                                        }
                                      };
                              }
                              var e$2 = locationType._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".locationType" + e$2.path,
                                        message: e$2.message,
                                        value: e$2.value
                                      }
                                    };
                            }
                            var e$3 = phone2._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".phone2" + e$3.path,
                                      message: e$3.message,
                                      value: e$3.value
                                    }
                                  };
                          }
                          var e$4 = phone1._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".phone1" + e$4.path,
                                    message: e$4.message,
                                    value: e$4.value
                                  }
                                };
                        }
                        var e$5 = lift._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".lift" + e$5.path,
                                  message: e$5.message,
                                  value: e$5.value
                                }
                              };
                      }
                      var e$6 = floor._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".floor" + e$6.path,
                                message: e$6.message,
                                value: e$6.value
                              }
                            };
                    }
                    var e$7 = comment._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".comment" + e$7.path,
                              message: e$7.message,
                              value: e$7.value
                            }
                          };
                  }
                  var e$8 = line2._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".line2" + e$8.path,
                            message: e$8.message,
                            value: e$8.value
                          }
                        };
                }
                var e$9 = line1._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".line1" + e$9.path,
                          message: e$9.message,
                          value: e$9.value
                        }
                      };
              }
              var e$10 = country._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".country" + e$10.path,
                        message: e$10.message,
                        value: e$10.value
                      }
                    };
            }
            var e$11 = city._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".city" + e$11.path,
                      message: e$11.message,
                      value: e$11.value
                    }
                  };
          }
          var e$12 = postalCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".postalCode" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
        }
        var e$13 = lastName._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".lastName" + e$13.path,
                  message: e$13.message,
                  value: e$13.value
                }
              };
      }
      var e$14 = firstName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".firstName" + e$14.path,
                message: e$14.message,
                value: e$14.value
              }
            };
    }
    var e$15 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$15.path,
              message: e$15.message,
              value: e$15.value
            }
          };
  }
  var e$16 = coords._0;
  return {
          TAG: "Error",
          _0: {
            path: ".coords" + e$16.path,
            message: e$16.message,
            value: e$16.value
          }
        };
}

function nullableDimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lengthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.widthCm)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.heightCm)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeM3)
                  ]
                ]));
}

function nullableDimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lengthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
  if (lengthCm.TAG === "Ok") {
    var widthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
    if (widthCm.TAG === "Ok") {
      var heightCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
      if (heightCm.TAG === "Ok") {
        var volumeM3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
        if (volumeM3.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    lengthCm: lengthCm._0,
                    widthCm: widthCm._0,
                    heightCm: heightCm._0,
                    volumeM3: volumeM3._0
                  }
                };
        }
        var e = volumeM3._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".volumeM3" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = heightCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".heightCm" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = widthCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".widthCm" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = lengthCm._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lengthCm" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function dimensions_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lengthCm",
                    false,
                    Spice.floatToJson(v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.floatToJson(v.widthCm)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.floatToJson(v.heightCm)
                  ]
                ]));
}

function dimensions_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lengthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
  if (lengthCm.TAG === "Ok") {
    var widthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
    if (widthCm.TAG === "Ok") {
      var heightCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
      if (heightCm.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  lengthCm: lengthCm._0,
                  widthCm: widthCm._0,
                  heightCm: heightCm._0
                }
              };
      }
      var e = heightCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".heightCm" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = widthCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".widthCm" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = lengthCm._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lengthCm" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function packaging_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "dimensions",
                    false,
                    nullableDimensions_encode(v.dimensions)
                  ],
                  [
                    "weightKg",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.weightKg)
                  ],
                  [
                    "packetsCount",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.packetsCount)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "heaviestWeightKg",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.heaviestWeightKg)
                  ],
                  [
                    "largestVolumeCm3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.largestVolumeCm3)
                  ],
                  [
                    "longestPacketLength",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.longestPacketLength)
                  ],
                  [
                    "biggestPacketDimensions",
                    false,
                    Spice.optionToJson(dimensions_encode$1, v.biggestPacketDimensions)
                  ],
                  [
                    "longestPacketDimensions",
                    false,
                    Spice.optionToJson(dimensions_encode$1, v.longestPacketDimensions)
                  ],
                  [
                    "volumeCm3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeCm3)
                  ]
                ]));
}

function packaging_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var dimensions = nullableDimensions_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dimensions"), null));
  if (dimensions.TAG === "Ok") {
    var weightKg = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
    if (weightKg.TAG === "Ok") {
      var packetsCount = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "packetsCount"), null));
      if (packetsCount.TAG === "Ok") {
        var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
        if (description.TAG === "Ok") {
          var heaviestWeightKg = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heaviestWeightKg"), null));
          if (heaviestWeightKg.TAG === "Ok") {
            var largestVolumeCm3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "largestVolumeCm3"), null));
            if (largestVolumeCm3.TAG === "Ok") {
              var longestPacketLength = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "longestPacketLength"), null));
              if (longestPacketLength.TAG === "Ok") {
                var biggestPacketDimensions = Spice.optionFromJson(dimensions_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "biggestPacketDimensions"), null));
                if (biggestPacketDimensions.TAG === "Ok") {
                  var longestPacketDimensions = Spice.optionFromJson(dimensions_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "longestPacketDimensions"), null));
                  if (longestPacketDimensions.TAG === "Ok") {
                    var volumeCm3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeCm3"), null));
                    if (volumeCm3.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: {
                                dimensions: dimensions._0,
                                weightKg: weightKg._0,
                                packetsCount: packetsCount._0,
                                description: description._0,
                                heaviestWeightKg: heaviestWeightKg._0,
                                largestVolumeCm3: largestVolumeCm3._0,
                                longestPacketLength: longestPacketLength._0,
                                biggestPacketDimensions: biggestPacketDimensions._0,
                                longestPacketDimensions: longestPacketDimensions._0,
                                volumeCm3: volumeCm3._0
                              }
                            };
                    }
                    var e = volumeCm3._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".volumeCm3" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = longestPacketDimensions._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".longestPacketDimensions" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = biggestPacketDimensions._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".biggestPacketDimensions" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = longestPacketLength._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".longestPacketLength" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = largestVolumeCm3._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".largestVolumeCm3" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = heaviestWeightKg._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".heaviestWeightKg" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = description._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".description" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = packetsCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".packetsCount" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = weightKg._0;
    return {
            TAG: "Error",
            _0: {
              path: ".weightKg" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = dimensions._0;
  return {
          TAG: "Error",
          _0: {
            path: ".dimensions" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function signatureTransition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "mobileStateUpdatedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileStateUpdatedAt)
                  ],
                  [
                    "problem",
                    false,
                    Spice.optionToJson(ColiswebApi__Utils.TransitionProblem.t_encode, v.problem)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "isSuccessful",
                    false,
                    Spice.boolToJson(v.isSuccessful)
                  ]
                ]));
}

function signatureTransition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var mobileStateUpdatedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileStateUpdatedAt"), null));
    if (mobileStateUpdatedAt.TAG === "Ok") {
      var problem = Spice.optionFromJson(ColiswebApi__Utils.TransitionProblem.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "problem"), null));
      if (problem.TAG === "Ok") {
        var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
        if (comment.TAG === "Ok") {
          var isSuccessful = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSuccessful"), null));
          if (isSuccessful.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      createdAt: createdAt._0,
                      mobileStateUpdatedAt: mobileStateUpdatedAt._0,
                      problem: problem._0,
                      comment: comment._0,
                      isSuccessful: isSuccessful._0
                    }
                  };
          }
          var e = isSuccessful._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".isSuccessful" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = comment._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".comment" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = problem._0;
      return {
              TAG: "Error",
              _0: {
                path: ".problem" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = mobileStateUpdatedAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".mobileStateUpdatedAt" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function signature_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "transition",
                    false,
                    Spice.optionToJson(signatureTransition_encode, v.transition)
                  ]
                ]));
}

function signature_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
  if (url.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var transition = Spice.optionFromJson(signatureTransition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transition"), null));
      if (transition.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  url: url._0,
                  name: name._0,
                  transition: transition._0
                }
              };
      }
      var e = transition._0;
      return {
              TAG: "Error",
              _0: {
                path: ".transition" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = url._0;
  return {
          TAG: "Error",
          _0: {
            path: ".url" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function heftingOptions_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "isSidewalk",
                    false,
                    Spice.boolToJson(v.isSidewalk)
                  ],
                  [
                    "isInRoom",
                    false,
                    Spice.boolToJson(v.isInRoom)
                  ],
                  [
                    "isTrailed",
                    false,
                    Spice.boolToJson(v.isTrailed)
                  ]
                ]));
}

function heftingOptions_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var isSidewalk = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSidewalk"), null));
  if (isSidewalk.TAG === "Ok") {
    var isInRoom = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isInRoom"), null));
    if (isInRoom.TAG === "Ok") {
      var isTrailed = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isTrailed"), Spice.boolFromJson), {
            TAG: "Ok",
            _0: false
          });
      if (isTrailed.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  isSidewalk: isSidewalk._0,
                  isInRoom: isInRoom._0,
                  isTrailed: isTrailed._0
                }
              };
      }
      var e = isTrailed._0;
      return {
              TAG: "Error",
              _0: {
                path: ".isTrailed" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = isInRoom._0;
    return {
            TAG: "Error",
            _0: {
              path: ".isInRoom" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = isSidewalk._0;
  return {
          TAG: "Error",
          _0: {
            path: ".isSidewalk" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function timeSlot_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end_)
                  ]
                ]));
}

function timeSlot_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end_: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function skill_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ],
                  [
                    "isDefault",
                    false,
                    Spice.boolToJson(v.isDefault)
                  ]
                ]));
}

function skill_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
    if (tag.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var isDefault = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isDefault"), Spice.boolFromJson), {
              TAG: "Ok",
              _0: false
            });
        if (isDefault.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    tag: tag._0,
                    description: description._0,
                    isDefault: isDefault._0
                  }
                };
        }
        var e = isDefault._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".isDefault" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = tag._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tag" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function skills_encode(v) {
  return Spice.arrayToJson(skill_encode, v);
}

function skills_decode(v) {
  return Spice.arrayFromJson(skill_decode, v);
}

function transition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "event",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.event)
                  ],
                  [
                    "dateTime",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.dateTime)
                  ],
                  [
                    "problem",
                    false,
                    Spice.optionToJson(ColiswebApi__Utils.TransitionProblem.t_encode, v.problem)
                  ],
                  [
                    "mobileDateTime",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileDateTime)
                  ]
                ]));
}

function transition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var $$event = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "event"), null));
  if ($$event.TAG === "Ok") {
    var dateTime = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dateTime"), null));
    if (dateTime.TAG === "Ok") {
      var problem = Spice.optionFromJson(ColiswebApi__Utils.TransitionProblem.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "problem"), null));
      if (problem.TAG === "Ok") {
        var mobileDateTime = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileDateTime"), null));
        if (mobileDateTime.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    event: $$event._0,
                    dateTime: dateTime._0,
                    problem: problem._0,
                    mobileDateTime: mobileDateTime._0
                  }
                };
        }
        var e = mobileDateTime._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".mobileDateTime" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = problem._0;
      return {
              TAG: "Error",
              _0: {
                path: ".problem" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = dateTime._0;
    return {
            TAG: "Error",
            _0: {
              path: ".dateTime" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = $$event._0;
  return {
          TAG: "Error",
          _0: {
            path: ".event" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function carrier_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.CourierId.t_encode, v.id)
                  ],
                  [
                    "carrierNumber",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.carrierNumber)
                  ]
                ]));
}

function carrier_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.CourierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var carrierNumber = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "carrierNumber"), null));
    if (carrierNumber.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                carrierNumber: carrierNumber._0
              }
            };
    }
    var e = carrierNumber._0;
    return {
            TAG: "Error",
            _0: {
              path: ".carrierNumber" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function priceComposition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var amount = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "amount"), null));
  if (amount.TAG === "Ok") {
    var label = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                amount: amount._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function priceComposition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "amount",
                    false,
                    Spice.floatToJson(v.amount)
                  ],
                  [
                    "label",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.label)
                  ]
                ]));
}

function priceWithoutTaxEur_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var value = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "value"), null));
  if (value.TAG === "Ok") {
    var composition = Spice.arrayFromJson(priceComposition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "composition"), null));
    if (composition.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                value: value._0,
                composition: composition._0
              }
            };
    }
    var e = composition._0;
    return {
            TAG: "Error",
            _0: {
              path: ".composition" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = value._0;
  return {
          TAG: "Error",
          _0: {
            path: ".value" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function priceWithoutTaxEur_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "value",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.value)
                  ],
                  [
                    "composition",
                    false,
                    Spice.arrayToJson(priceComposition_encode, v.composition)
                  ]
                ]));
}

function response_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "route",
                    false,
                    Spice.optionToJson(Identifiers.RouteId.t_encode, v.route)
                  ],
                  [
                    "routePlan",
                    false,
                    Spice.optionToJson(Identifiers.RoutePlanId.t_encode, v.routePlan)
                  ],
                  [
                    "ref1",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref2)
                  ],
                  [
                    "ref3",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref3)
                  ],
                  [
                    "ref4",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref4)
                  ],
                  [
                    "heftingOptions",
                    false,
                    heftingOptions_encode$1(v.heftingOptions)
                  ],
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "distanceKm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.distanceKm)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode, v.skills)
                  ],
                  [
                    "timeSlot",
                    false,
                    timeSlot_encode(v.timeSlot)
                  ],
                  [
                    "pickup",
                    false,
                    Spice.optionToJson(point_encode$1, v.pickup)
                  ],
                  [
                    "shipping",
                    false,
                    Spice.optionToJson(point_encode$1, v.shipping)
                  ],
                  [
                    "carrier",
                    false,
                    Spice.optionToJson(carrier_encode$1, v.carrier)
                  ],
                  [
                    "priceWithoutTaxEur",
                    false,
                    priceWithoutTaxEur_encode(v.priceWithoutTaxEur)
                  ],
                  [
                    "packaging",
                    false,
                    Spice.optionToJson(packaging_encode$1, v.packaging)
                  ],
                  [
                    "pickupSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.pickupSignature)
                  ],
                  [
                    "shippingSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.shippingSignature)
                  ],
                  [
                    "returnSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.returnSignature)
                  ],
                  [
                    "deliveryStateTransitions",
                    false,
                    Spice.arrayToJson(transition_encode, v.deliveryStateTransitions)
                  ],
                  [
                    "isArchived",
                    false,
                    Spice.boolToJson(v.isArchived)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "numberOfHandlers",
                    false,
                    Spice.intToJson(v.numberOfHandlers)
                  ],
                  [
                    "lightweight",
                    false,
                    Spice.boolToJson(v.lightweight)
                  ],
                  [
                    "availableRefinedSlots",
                    false,
                    Spice.arrayToJson(Toolkit__Decoders.DatetimeTimeSlot.t_encode, v.availableRefinedSlots)
                  ]
                ]));
}

function response_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var route = Spice.optionFromJson(Identifiers.RouteId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "route"), null));
    if (route.TAG === "Ok") {
      var routePlan = Spice.optionFromJson(Identifiers.RoutePlanId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "routePlan"), null));
      if (routePlan.TAG === "Ok") {
        var ref1 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
        if (ref1.TAG === "Ok") {
          var ref2 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
          if (ref2.TAG === "Ok") {
            var ref3 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref3"), null));
            if (ref3.TAG === "Ok") {
              var ref4 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref4"), null));
              if (ref4.TAG === "Ok") {
                var heftingOptions = heftingOptions_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "heftingOptions"), null));
                if (heftingOptions.TAG === "Ok") {
                  var statusProvider = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
                  if (statusProvider.TAG === "Ok") {
                    var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
                    if (createdAt.TAG === "Ok") {
                      var distanceKm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "distanceKm"), null));
                      if (distanceKm.TAG === "Ok") {
                        var skills = Spice.arrayFromJson(skill_decode, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                        if (skills.TAG === "Ok") {
                          var timeSlot = timeSlot_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
                          if (timeSlot.TAG === "Ok") {
                            var pickup = Spice.optionFromJson(point_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
                            if (pickup.TAG === "Ok") {
                              var shipping = Spice.optionFromJson(point_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "shipping"), null));
                              if (shipping.TAG === "Ok") {
                                var carrier = Spice.optionFromJson(carrier_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "carrier"), null));
                                if (carrier.TAG === "Ok") {
                                  var priceWithoutTaxEur = priceWithoutTaxEur_decode(Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEur"), null));
                                  if (priceWithoutTaxEur.TAG === "Ok") {
                                    var packaging = Spice.optionFromJson(packaging_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "packaging"), null));
                                    if (packaging.TAG === "Ok") {
                                      var pickupSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickupSignature"), null));
                                      if (pickupSignature.TAG === "Ok") {
                                        var shippingSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shippingSignature"), null));
                                        if (shippingSignature.TAG === "Ok") {
                                          var returnSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "returnSignature"), null));
                                          if (returnSignature.TAG === "Ok") {
                                            var deliveryStateTransitions = Spice.arrayFromJson(transition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryStateTransitions"), null));
                                            if (deliveryStateTransitions.TAG === "Ok") {
                                              var isArchived = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isArchived"), null));
                                              if (isArchived.TAG === "Ok") {
                                                var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
                                                if (clientId.TAG === "Ok") {
                                                  var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
                                                  if (storeId.TAG === "Ok") {
                                                    var numberOfHandlers = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "numberOfHandlers"), null));
                                                    if (numberOfHandlers.TAG === "Ok") {
                                                      var lightweight = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lightweight"), null));
                                                      if (lightweight.TAG === "Ok") {
                                                        var availableRefinedSlots = Spice.arrayFromJson(Toolkit__Decoders.DatetimeTimeSlot.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "availableRefinedSlots"), null));
                                                        if (availableRefinedSlots.TAG === "Ok") {
                                                          return {
                                                                  TAG: "Ok",
                                                                  _0: {
                                                                    id: id._0,
                                                                    route: route._0,
                                                                    routePlan: routePlan._0,
                                                                    ref1: ref1._0,
                                                                    ref2: ref2._0,
                                                                    ref3: ref3._0,
                                                                    ref4: ref4._0,
                                                                    heftingOptions: heftingOptions._0,
                                                                    statusProvider: statusProvider._0,
                                                                    createdAt: createdAt._0,
                                                                    distanceKm: distanceKm._0,
                                                                    skills: skills._0,
                                                                    timeSlot: timeSlot._0,
                                                                    pickup: pickup._0,
                                                                    shipping: shipping._0,
                                                                    carrier: carrier._0,
                                                                    priceWithoutTaxEur: priceWithoutTaxEur._0,
                                                                    packaging: packaging._0,
                                                                    pickupSignature: pickupSignature._0,
                                                                    shippingSignature: shippingSignature._0,
                                                                    returnSignature: returnSignature._0,
                                                                    deliveryStateTransitions: deliveryStateTransitions._0,
                                                                    isArchived: isArchived._0,
                                                                    clientId: clientId._0,
                                                                    storeId: storeId._0,
                                                                    numberOfHandlers: numberOfHandlers._0,
                                                                    lightweight: lightweight._0,
                                                                    availableRefinedSlots: availableRefinedSlots._0
                                                                  }
                                                                };
                                                        }
                                                        var e = availableRefinedSlots._0;
                                                        return {
                                                                TAG: "Error",
                                                                _0: {
                                                                  path: ".availableRefinedSlots" + e.path,
                                                                  message: e.message,
                                                                  value: e.value
                                                                }
                                                              };
                                                      }
                                                      var e$1 = lightweight._0;
                                                      return {
                                                              TAG: "Error",
                                                              _0: {
                                                                path: ".lightweight" + e$1.path,
                                                                message: e$1.message,
                                                                value: e$1.value
                                                              }
                                                            };
                                                    }
                                                    var e$2 = numberOfHandlers._0;
                                                    return {
                                                            TAG: "Error",
                                                            _0: {
                                                              path: ".numberOfHandlers" + e$2.path,
                                                              message: e$2.message,
                                                              value: e$2.value
                                                            }
                                                          };
                                                  }
                                                  var e$3 = storeId._0;
                                                  return {
                                                          TAG: "Error",
                                                          _0: {
                                                            path: ".storeId" + e$3.path,
                                                            message: e$3.message,
                                                            value: e$3.value
                                                          }
                                                        };
                                                }
                                                var e$4 = clientId._0;
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          path: ".clientId" + e$4.path,
                                                          message: e$4.message,
                                                          value: e$4.value
                                                        }
                                                      };
                                              }
                                              var e$5 = isArchived._0;
                                              return {
                                                      TAG: "Error",
                                                      _0: {
                                                        path: ".isArchived" + e$5.path,
                                                        message: e$5.message,
                                                        value: e$5.value
                                                      }
                                                    };
                                            }
                                            var e$6 = deliveryStateTransitions._0;
                                            return {
                                                    TAG: "Error",
                                                    _0: {
                                                      path: ".deliveryStateTransitions" + e$6.path,
                                                      message: e$6.message,
                                                      value: e$6.value
                                                    }
                                                  };
                                          }
                                          var e$7 = returnSignature._0;
                                          return {
                                                  TAG: "Error",
                                                  _0: {
                                                    path: ".returnSignature" + e$7.path,
                                                    message: e$7.message,
                                                    value: e$7.value
                                                  }
                                                };
                                        }
                                        var e$8 = shippingSignature._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".shippingSignature" + e$8.path,
                                                  message: e$8.message,
                                                  value: e$8.value
                                                }
                                              };
                                      }
                                      var e$9 = pickupSignature._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".pickupSignature" + e$9.path,
                                                message: e$9.message,
                                                value: e$9.value
                                              }
                                            };
                                    }
                                    var e$10 = packaging._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".packaging" + e$10.path,
                                              message: e$10.message,
                                              value: e$10.value
                                            }
                                          };
                                  }
                                  var e$11 = priceWithoutTaxEur._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".priceWithoutTaxEur" + e$11.path,
                                            message: e$11.message,
                                            value: e$11.value
                                          }
                                        };
                                }
                                var e$12 = carrier._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".carrier" + e$12.path,
                                          message: e$12.message,
                                          value: e$12.value
                                        }
                                      };
                              }
                              var e$13 = shipping._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".shipping" + e$13.path,
                                        message: e$13.message,
                                        value: e$13.value
                                      }
                                    };
                            }
                            var e$14 = pickup._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".pickup" + e$14.path,
                                      message: e$14.message,
                                      value: e$14.value
                                    }
                                  };
                          }
                          var e$15 = timeSlot._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".timeSlot" + e$15.path,
                                    message: e$15.message,
                                    value: e$15.value
                                  }
                                };
                        }
                        var e$16 = skills._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".skills" + e$16.path,
                                  message: e$16.message,
                                  value: e$16.value
                                }
                              };
                      }
                      var e$17 = distanceKm._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".distanceKm" + e$17.path,
                                message: e$17.message,
                                value: e$17.value
                              }
                            };
                    }
                    var e$18 = createdAt._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".createdAt" + e$18.path,
                              message: e$18.message,
                              value: e$18.value
                            }
                          };
                  }
                  var e$19 = statusProvider._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".statusProvider" + e$19.path,
                            message: e$19.message,
                            value: e$19.value
                          }
                        };
                }
                var e$20 = heftingOptions._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".heftingOptions" + e$20.path,
                          message: e$20.message,
                          value: e$20.value
                        }
                      };
              }
              var e$21 = ref4._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".ref4" + e$21.path,
                        message: e$21.message,
                        value: e$21.value
                      }
                    };
            }
            var e$22 = ref3._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".ref3" + e$22.path,
                      message: e$22.message,
                      value: e$22.value
                    }
                  };
          }
          var e$23 = ref2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".ref2" + e$23.path,
                    message: e$23.message,
                    value: e$23.value
                  }
                };
        }
        var e$24 = ref1._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".ref1" + e$24.path,
                  message: e$24.message,
                  value: e$24.value
                }
              };
      }
      var e$25 = routePlan._0;
      return {
              TAG: "Error",
              _0: {
                path: ".routePlan" + e$25.path,
                message: e$25.message,
                value: e$25.value
              }
            };
    }
    var e$26 = route._0;
    return {
            TAG: "Error",
            _0: {
              path: ".route" + e$26.path,
              message: e$26.message,
              value: e$26.value
            }
          };
  }
  var e$27 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$27.path,
            message: e$27.message,
            value: e$27.value
          }
        };
}

function t_encode$8(v) {
  return "not_authorized";
}

function t_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("not_authorized" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_authorized"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error = {
  t_encode: t_encode$8,
  t_decode: t_decode$8
};

function errorResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    "not_authorized"
                  ],
                  [
                    "error",
                    false,
                    Spice.stringToJson(v.error)
                  ]
                ]));
}

function errorResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$8(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var error = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "error"), null));
    if (error.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                type_: type_._0,
                error: error._0
              }
            };
    }
    var e = error._0;
    return {
            TAG: "Error",
            _0: {
              path: ".error" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$1.path),
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$9(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$9, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var err = errorResponse_decode(match.data);
                if (err.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: err._0.type_
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            err._0
                          ]
                        };
                }
              }), deliveriesBaseUrl(param[0], param[1]));
}

var Config$9 = {
  point_encode: point_encode$1,
  point_decode: point_decode$1,
  nullableDimensions_encode: nullableDimensions_encode,
  nullableDimensions_decode: nullableDimensions_decode,
  packaging_encode: packaging_encode$1,
  packaging_decode: packaging_decode$1,
  dimensions_encode: dimensions_encode$1,
  dimensions_decode: dimensions_decode$1,
  signature_encode: signature_encode,
  signature_decode: signature_decode,
  signatureTransition_encode: signatureTransition_encode,
  signatureTransition_decode: signatureTransition_decode,
  heftingOptions_encode: heftingOptions_encode$1,
  heftingOptions_decode: heftingOptions_decode$1,
  timeSlot_encode: timeSlot_encode,
  timeSlot_decode: timeSlot_decode,
  skills_encode: skills_encode,
  skills_decode: skills_decode,
  skill_encode: skill_encode,
  skill_decode: skill_decode,
  transition_encode: transition_encode,
  transition_decode: transition_decode,
  carrier_encode: carrier_encode$1,
  carrier_decode: carrier_decode$1,
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  priceWithoutTaxEur_encode: priceWithoutTaxEur_encode,
  priceWithoutTaxEur_decode: priceWithoutTaxEur_decode,
  priceComposition_encode: priceComposition_encode,
  priceComposition_decode: priceComposition_decode,
  $$Error: $$Error,
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  exec: exec$9
};

var $$Request$9 = Toolkit__Request.Make({
      exec: exec$9
    });

var GetDelivery = {
  Config: Config$9,
  $$Request: $$Request$9
};

function t_encode$9(v) {
  switch (v) {
    case "line1" :
        return "line1";
    case "line2" :
        return "line2";
    case "city" :
        return "city";
    case "country" :
        return "country";
    case "society" :
        return "society";
    case "floor" :
        return "floor";
    case "state_or_province" :
        return "state_or_province";
    case "postal_code" :
        return "postal_code";
    case "starts_at" :
        return "starts_at";
    case "ends_at" :
        return "ends_at";
    case "lift_information" :
        return "lift_information";
    case "additional_informations" :
        return "additional_informations";
    case "administrative_area_code2" :
        return "administrative_area_code2";
    case "administrative_area_code3" :
        return "administrative_area_code3";
    
  }
}

function t_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("line1" === v) {
          return {
                  TAG: "Ok",
                  _0: "line1"
                };
        } else if ("line2" === v) {
          return {
                  TAG: "Ok",
                  _0: "line2"
                };
        } else if ("city" === v) {
          return {
                  TAG: "Ok",
                  _0: "city"
                };
        } else if ("country" === v) {
          return {
                  TAG: "Ok",
                  _0: "country"
                };
        } else if ("society" === v) {
          return {
                  TAG: "Ok",
                  _0: "society"
                };
        } else if ("floor" === v) {
          return {
                  TAG: "Ok",
                  _0: "floor"
                };
        } else if ("state_or_province" === v) {
          return {
                  TAG: "Ok",
                  _0: "state_or_province"
                };
        } else if ("postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "postal_code"
                };
        } else if ("starts_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "starts_at"
                };
        } else if ("ends_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "ends_at"
                };
        } else if ("lift_information" === v) {
          return {
                  TAG: "Ok",
                  _0: "lift_information"
                };
        } else if ("additional_informations" === v) {
          return {
                  TAG: "Ok",
                  _0: "additional_informations"
                };
        } else if ("administrative_area_code2" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code2"
                };
        } else if ("administrative_area_code3" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code3"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var FieldName = {
  t_encode: t_encode$9,
  t_decode: t_decode$9
};

function t_encode$10(v) {
  switch (v) {
    case "destination_address" :
        return "destination_address";
    case "origin_address" :
        return "origin_address";
    case "slot" :
        return "slot";
    
  }
}

function t_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("destination_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "destination_address"
                };
        } else if ("origin_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "origin_address"
                };
        } else if ("slot" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var ChangeType = {
  t_encode: t_encode$10,
  t_decode: t_decode$10
};

function t_encode$11(v) {
  switch (v) {
    case "admin" :
        return "admin";
    case "sav_admin" :
        return "sav_admin";
    case "client_admin" :
        return "client_admin";
    case "store_admin" :
        return "store_admin";
    case "transporter_admin" :
        return "transporter_admin";
    case "e_retailer" :
        return "e_retailer";
    case "carrier" :
        return "carrier";
    case "tech_admin" :
        return "tech_admin";
    case "super_admin" :
        return "super_admin";
    case "transporter_pole_admin" :
        return "transporter_pole_admin";
    case "customer" :
        return "customer";
    case "supplier" :
        return "supplier";
    
  }
}

function t_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "admin"
                };
        } else if ("sav_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "sav_admin"
                };
        } else if ("client_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_admin"
                };
        } else if ("store_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_admin"
                };
        } else if ("transporter_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter_admin"
                };
        } else if ("e_retailer" === v) {
          return {
                  TAG: "Ok",
                  _0: "e_retailer"
                };
        } else if ("carrier" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrier"
                };
        } else if ("tech_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "tech_admin"
                };
        } else if ("super_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "super_admin"
                };
        } else if ("transporter_pole_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter_pole_admin"
                };
        } else if ("customer" === v) {
          return {
                  TAG: "Ok",
                  _0: "customer"
                };
        } else if ("supplier" === v) {
          return {
                  TAG: "Ok",
                  _0: "supplier"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var UserRole = {
  t_encode: t_encode$11,
  t_decode: t_decode$11
};

function modification_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "fieldName",
                    false,
                    t_encode$9(v.fieldName)
                  ],
                  [
                    "valueBefore",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.valueBefore)
                  ],
                  [
                    "valueAfter",
                    false,
                    Spice.stringToJson(v.valueAfter)
                  ]
                ]));
}

function modification_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var fieldName = t_decode$9(Belt_Option.getWithDefault(Js_dict.get(v, "fieldName"), null));
  if (fieldName.TAG === "Ok") {
    var valueBefore = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "valueBefore"), null));
    if (valueBefore.TAG === "Ok") {
      var valueAfter = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "valueAfter"), null));
      if (valueAfter.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  fieldName: fieldName._0,
                  valueBefore: valueBefore._0,
                  valueAfter: valueAfter._0
                }
              };
      }
      var e = valueAfter._0;
      return {
              TAG: "Error",
              _0: {
                path: ".valueAfter" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = valueBefore._0;
    return {
            TAG: "Error",
            _0: {
              path: ".valueBefore" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = fieldName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".fieldName" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function modificationHistory_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var modifiedBy = Spice.optionFromJson(t_decode$11, Belt_Option.getWithDefault(Js_dict.get(v, "modifiedBy"), null));
    if (modifiedBy.TAG === "Ok") {
      var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var modificationType = t_decode$10(Belt_Option.getWithDefault(Js_dict.get(v, "modificationType"), null));
        if (modificationType.TAG === "Ok") {
          var modifications = Spice.arrayFromJson(modification_decode, Belt_Option.getWithDefault(Js_dict.get(v, "modifications"), null));
          if (modifications.TAG === "Ok") {
            var modificationReason = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "modificationReason"), null));
            if (modificationReason.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        createdAt: createdAt._0,
                        modifiedBy: modifiedBy._0,
                        email: email._0,
                        modificationType: modificationType._0,
                        modifications: modifications._0,
                        modificationReason: modificationReason._0
                      }
                    };
            }
            var e = modificationReason._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".modificationReason" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = modifications._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".modifications" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = modificationType._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".modificationType" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = modifiedBy._0;
    return {
            TAG: "Error",
            _0: {
              path: ".modifiedBy" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function modificationHistory_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "modifiedBy",
                    false,
                    Spice.optionToJson(t_encode$11, v.modifiedBy)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "modificationType",
                    false,
                    t_encode$10(v.modificationType)
                  ],
                  [
                    "modifications",
                    false,
                    Spice.arrayToJson(modification_encode, v.modifications)
                  ],
                  [
                    "modificationReason",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.modificationReason)
                  ]
                ]));
}

function response_encode$10(v) {
  return Spice.arrayToJson(modificationHistory_encode, v);
}

function response_decode$10(v) {
  return Spice.arrayFromJson(modificationHistory_decode, v);
}

function exec$10(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$10, undefined, deliveriesBaseUrl(param[0], param[1]) + "/modificationsHistory");
}

var Config$10 = {
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  modificationHistory_encode: modificationHistory_encode,
  modificationHistory_decode: modificationHistory_decode,
  modification_encode: modification_encode,
  modification_decode: modification_decode,
  exec: exec$10
};

var $$Request$10 = Toolkit__Request.Make({
      exec: exec$10
    });

var GetChangesHistory = {
  FieldName: FieldName,
  ChangeType: ChangeType,
  UserRole: UserRole,
  Config: Config$10,
  $$Request: $$Request$10
};

function argument_encode$1(v) {
  return [
          Curry._1(Identifiers.TransporterId.t_encode, v[0]),
          Curry._1(Identifiers.DeliveryId.t_encode, v[1])
        ];
}

function argument_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.TransporterId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.DeliveryId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function picture_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.PictureId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
    if (url.TAG === "Ok") {
      var status = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
      if (status.TAG === "Ok") {
        var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
        if (createdAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    url: url._0,
                    status: status._0,
                    createdAt: createdAt._0
                  }
                };
        }
        var e = createdAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".createdAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = status._0;
      return {
              TAG: "Error",
              _0: {
                path: ".status" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = url._0;
    return {
            TAG: "Error",
            _0: {
              path: ".url" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function picture_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.PictureId.t_encode, v.id)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "status",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.status)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ]
                ]));
}

function response_encode$11(v) {
  return Spice.arrayToJson(picture_encode, v);
}

function response_decode$11(v) {
  return Spice.arrayFromJson(picture_decode, v);
}

var error_encode = Spice.unitToJson;

var error_decode = Spice.unitFromJson;

function exec$11(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$11, undefined, deliveriesBaseUrl(param[0], param[1]) + "/pictures");
}

var Config$11 = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  picture_encode: picture_encode,
  picture_decode: picture_decode,
  error_encode: error_encode,
  error_decode: error_decode,
  exec: exec$11
};

var $$Request$11 = Toolkit__Request.Make({
      exec: exec$11
    });

var GetPictures = {
  Config: Config$11,
  $$Request: $$Request$11
};

function argument_encode$2(v) {
  return [
          Curry._1(Identifiers.TransporterId.t_encode, v[0]),
          Curry._1(Identifiers.DeliveryId.t_encode, v[1])
        ];
}

function argument_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.TransporterId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.DeliveryId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function packet_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.PacketId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
    if (description.TAG === "Ok") {
      var weightKg = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
      if (weightKg.TAG === "Ok") {
        var heightCm = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
        if (heightCm.TAG === "Ok") {
          var widthCm = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
          if (widthCm.TAG === "Ok") {
            var lengthCm = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
            if (lengthCm.TAG === "Ok") {
              var volumeM3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
              if (volumeM3.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          id: id._0,
                          description: description._0,
                          weightKg: weightKg._0,
                          heightCm: heightCm._0,
                          widthCm: widthCm._0,
                          lengthCm: lengthCm._0,
                          volumeM3: volumeM3._0
                        }
                      };
              }
              var e = volumeM3._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".volumeM3" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = lengthCm._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".lengthCm" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = widthCm._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".widthCm" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = heightCm._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".heightCm" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = weightKg._0;
      return {
              TAG: "Error",
              _0: {
                path: ".weightKg" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = description._0;
    return {
            TAG: "Error",
            _0: {
              path: ".description" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function packet_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.PacketId.t_encode, v.id)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "weightKg",
                    false,
                    Spice.stringToJson(v.weightKg)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.heightCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.widthCm)
                  ],
                  [
                    "lengthCm",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lengthCm)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeM3)
                  ]
                ]));
}

function response_encode$12(v) {
  return Spice.arrayToJson(packet_encode, v);
}

function response_decode$12(v) {
  return Spice.arrayFromJson(packet_decode, v);
}

var error_encode$1 = Spice.unitToJson;

var error_decode$1 = Spice.unitFromJson;

function exec$12(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$12, undefined, deliveriesBaseUrl(param[0], param[1]) + "/packets");
}

var Config$12 = {
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  packet_encode: packet_encode,
  packet_decode: packet_decode,
  error_encode: error_encode$1,
  error_decode: error_decode$1,
  exec: exec$12
};

var $$Request$12 = Toolkit__Request.Make({
      exec: exec$12
    });

var GetPackets = {
  Config: Config$12,
  $$Request: $$Request$12
};

function argument_encode$3(v) {
  return [
          Curry._1(Identifiers.TransporterId.t_encode, v[0]),
          Curry._1(Identifiers.DeliveryId.t_encode, v[1]),
          ColiswebApi__V5_Shared.StatusProvider.t_encode(v[2]),
          Spice.optionToJson(t_encode$4, v[3])
        ];
}

function argument_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 4) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var v2 = v[2];
  var v3 = v[3];
  var match = Curry._1(Identifiers.TransporterId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.DeliveryId.t_decode, v1);
  var match$2 = ColiswebApi__V5_Shared.StatusProvider.t_decode(v2);
  var match$3 = Spice.optionFromJson(t_decode$4, v3);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      if (match$2.TAG === "Ok") {
        if (match$3.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: [
                    match._0,
                    match$1._0,
                    match$2._0,
                    match$3._0
                  ]
                };
        }
        var e = match$3._0;
        return {
                TAG: "Error",
                _0: {
                  path: "[3]" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = match$2._0;
      return {
              TAG: "Error",
              _0: {
                path: "[2]" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var response_encode$13 = Spice.unitToJson;

var response_decode$13 = Spice.unitFromJson;

function body_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "reason",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(t_encode$4, v.reason)
                  ]
                ]));
}

function body_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var statusProvider = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
  if (statusProvider.TAG === "Ok") {
    var reason = Toolkit__Decoders.$$Option.t_decode(t_decode$4, Belt_Option.getWithDefault(Js_dict.get(v, "reason"), null));
    if (reason.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                statusProvider: statusProvider._0,
                reason: reason._0
              }
            };
    }
    var e = reason._0;
    return {
            TAG: "Error",
            _0: {
              path: ".reason" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = statusProvider._0;
  return {
          TAG: "Error",
          _0: {
            path: ".statusProvider" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var error_encode$2 = Spice.unitToJson;

var error_decode$2 = Spice.unitFromJson;

function exec$13(param) {
  return Axios.WithResult.post(undefined, body_encode$6({
                  statusProvider: param[2],
                  reason: param[3]
                }), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$13, undefined, deliveriesBaseUrl(param[0], param[1]) + "/statusProvider");
}

var Config$13 = {
  argument_encode: argument_encode$3,
  argument_decode: argument_decode$3,
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  body_encode: body_encode$6,
  body_decode: body_decode$6,
  error_encode: error_encode$2,
  error_decode: error_decode$2,
  exec: exec$13
};

var $$Request$13 = Toolkit__Request.Make({
      exec: exec$13
    });

var PostStatusProvider = {
  Config: Config$13,
  $$Request: $$Request$13
};

function body_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ]]));
}

function body_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
  if (message.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              message: message._0
            }
          };
  }
  var e = message._0;
  return {
          TAG: "Error",
          _0: {
            path: ".message" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$14 = Spice.unitToJson;

var response_decode$14 = Spice.unitFromJson;

function exec$14(param) {
  return Axios.WithResult.post(undefined, body_encode$7(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$14, undefined, deliveriesBaseUrl(param[0], param[1]) + "/comments");
}

var Config$14 = {
  body_encode: body_encode$7,
  body_decode: body_decode$7,
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  exec: exec$14
};

var $$Request$14 = Toolkit__Request.Make({
      exec: exec$14
    });

var PostComment = {
  Config: Config$14,
  $$Request: $$Request$14
};

function comment_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.CommentId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
    if (message.TAG === "Ok") {
      var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
      if (createdAt.TAG === "Ok") {
        var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
        if (updatedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    message: message._0,
                    createdAt: createdAt._0,
                    updatedAt: updatedAt._0
                  }
                };
        }
        var e = updatedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".updatedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = createdAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".createdAt" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = message._0;
    return {
            TAG: "Error",
            _0: {
              path: ".message" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function comment_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.CommentId.t_encode, v.id)
                  ],
                  [
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function response_encode$15(v) {
  return Spice.arrayToJson(comment_encode, v);
}

function response_decode$15(v) {
  return Spice.arrayFromJson(comment_decode, v);
}

function exec$15(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$15, undefined, deliveriesBaseUrl(param[0], param[1]) + "/comments");
}

var Config$15 = {
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  comment_encode: comment_encode,
  comment_decode: comment_decode,
  exec: exec$15
};

var $$Request$15 = Toolkit__Request.Make({
      exec: exec$15
    });

var GetComments = {
  Config: Config$15,
  $$Request: $$Request$15
};

function response_encode$16(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "carrierCanAcceptDeliveries",
                    false,
                    Spice.boolToJson(v.carrierCanAcceptDeliveries)
                  ],
                  [
                    "showPriceToCarriers",
                    false,
                    Spice.boolToJson(v.showPriceToCarriers)
                  ]
                ]));
}

function response_decode$16(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carrierCanAcceptDeliveries = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "carrierCanAcceptDeliveries"), null));
  if (carrierCanAcceptDeliveries.TAG === "Ok") {
    var showPriceToCarriers = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "showPriceToCarriers"), null));
    if (showPriceToCarriers.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                carrierCanAcceptDeliveries: carrierCanAcceptDeliveries._0,
                showPriceToCarriers: showPriceToCarriers._0
              }
            };
    }
    var e = showPriceToCarriers._0;
    return {
            TAG: "Error",
            _0: {
              path: ".showPriceToCarriers" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = carrierCanAcceptDeliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carrierCanAcceptDeliveries" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$16(transporterId) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$16, (function (error) {
                var match = error.response;
                if (match !== undefined && match.status === 404) {
                  return {
                          NAME: "custom",
                          VAL: "transporterNotFound"
                        };
                } else {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
              }), "/transporters/" + transporterId + "/settings");
}

var Config$16 = {
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  exec: exec$16
};

var $$Request$16 = Toolkit__Request.Make({
      exec: exec$16
    });

var GetSettings = {
  Config: Config$16,
  $$Request: $$Request$16
};

function body_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "carrierCanAcceptDeliveries",
                    false,
                    Spice.boolToJson(v.carrierCanAcceptDeliveries)
                  ],
                  [
                    "showPriceToCarriers",
                    false,
                    Spice.boolToJson(v.showPriceToCarriers)
                  ]
                ]));
}

function body_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carrierCanAcceptDeliveries = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "carrierCanAcceptDeliveries"), null));
  if (carrierCanAcceptDeliveries.TAG === "Ok") {
    var showPriceToCarriers = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "showPriceToCarriers"), null));
    if (showPriceToCarriers.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                carrierCanAcceptDeliveries: carrierCanAcceptDeliveries._0,
                showPriceToCarriers: showPriceToCarriers._0
              }
            };
    }
    var e = showPriceToCarriers._0;
    return {
            TAG: "Error",
            _0: {
              path: ".showPriceToCarriers" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = carrierCanAcceptDeliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carrierCanAcceptDeliveries" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "carrierCanAcceptDeliveries",
                    false,
                    Spice.boolToJson(v.carrierCanAcceptDeliveries)
                  ],
                  [
                    "showPriceToCarriers",
                    false,
                    Spice.boolToJson(v.showPriceToCarriers)
                  ]
                ]));
}

function response_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var carrierCanAcceptDeliveries = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "carrierCanAcceptDeliveries"), null));
  if (carrierCanAcceptDeliveries.TAG === "Ok") {
    var showPriceToCarriers = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "showPriceToCarriers"), null));
    if (showPriceToCarriers.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                carrierCanAcceptDeliveries: carrierCanAcceptDeliveries._0,
                showPriceToCarriers: showPriceToCarriers._0
              }
            };
    }
    var e = showPriceToCarriers._0;
    return {
            TAG: "Error",
            _0: {
              path: ".showPriceToCarriers" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = carrierCanAcceptDeliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".carrierCanAcceptDeliveries" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$17(param) {
  return Axios.WithResult.put(undefined, body_encode$8(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$17, (function (error) {
                var match = error.response;
                if (match !== undefined && match.status === 404) {
                  return {
                          NAME: "custom",
                          VAL: "transporterNotFound"
                        };
                } else {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
              }), "/transporters/" + param[0] + "/settings");
}

var Config$17 = {
  body_encode: body_encode$8,
  body_decode: body_decode$8,
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  exec: exec$17
};

var $$Request$17 = Toolkit__Request.Make({
      exec: exec$17
    });

var UpdateSettings = {
  Config: Config$17,
  $$Request: $$Request$17
};

function body_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "primaryPhone",
                    false,
                    Spice.stringToJson(v.primaryPhone)
                  ]
                ]));
}

function body_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    var primaryPhone = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "primaryPhone"), null));
    if (primaryPhone.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                email: email._0,
                primaryPhone: primaryPhone._0
              }
            };
    }
    var e = primaryPhone._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryPhone" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$18 = Spice.unitToJson;

var response_decode$18 = Spice.unitFromJson;

function exec$18(param) {
  return Axios.WithResult.post(undefined, body_encode$9(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$18, (function (error) {
                return Belt_Option.mapWithDefault(error.response, {
                            NAME: "default",
                            VAL: error
                          }, (function (param) {
                              var status = param.status;
                              var match = param.data.type;
                              if (status === 404) {
                                if (match === "transporter_not_found") {
                                  return {
                                          NAME: "custom",
                                          VAL: "transporterNotFound"
                                        };
                                } else {
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                                }
                              }
                              if (status !== 409) {
                                return {
                                        NAME: "default",
                                        VAL: error
                                      };
                              }
                              switch (match) {
                                case "email_not_available" :
                                    return {
                                            NAME: "custom",
                                            VAL: "emailNotAvailable"
                                          };
                                case "login_not_available" :
                                    return {
                                            NAME: "custom",
                                            VAL: "loginNotAvailable"
                                          };
                                default:
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                              }
                            }));
              }), "/transporters/" + param[0] + "/contacts");
}

var Config$18 = {
  body_encode: body_encode$9,
  body_decode: body_decode$9,
  response_encode: response_encode$18,
  response_decode: response_decode$18,
  exec: exec$18
};

var $$Request$18 = Toolkit__Request.Make({
      exec: exec$18
    });

var CreateContact = {
  Config: Config$18,
  $$Request: $$Request$18
};

function body_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "primaryPhone",
                    false,
                    Spice.stringToJson(v.primaryPhone)
                  ]
                ]));
}

function body_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    var primaryPhone = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "primaryPhone"), null));
    if (primaryPhone.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                email: email._0,
                primaryPhone: primaryPhone._0
              }
            };
    }
    var e = primaryPhone._0;
    return {
            TAG: "Error",
            _0: {
              path: ".primaryPhone" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$19 = Spice.unitToJson;

var response_decode$19 = Spice.unitFromJson;

function exec$19(param) {
  return Axios.WithResult.put(undefined, body_encode$10(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$19, (function (error) {
                return Belt_Option.mapWithDefault(error.response, {
                            NAME: "default",
                            VAL: error
                          }, (function (param) {
                              var status = param.status;
                              var match = param.data.type;
                              if (status !== 404) {
                                if (status !== 409 || match !== "login_not_available") {
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                                } else {
                                  return {
                                          NAME: "custom",
                                          VAL: "loginNotAvailable"
                                        };
                                }
                              }
                              switch (match) {
                                case "contact_not_found" :
                                    return {
                                            NAME: "custom",
                                            VAL: "contactNotFound"
                                          };
                                case "transporter_not_found" :
                                    return {
                                            NAME: "custom",
                                            VAL: "transporterNotFound"
                                          };
                                default:
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                              }
                            }));
              }), "/transporters/" + param[0] + "/contacts/" + param[1]);
}

var Config$19 = {
  body_encode: body_encode$10,
  body_decode: body_decode$10,
  response_encode: response_encode$19,
  response_decode: response_decode$19,
  exec: exec$19
};

var $$Request$19 = Toolkit__Request.Make({
      exec: exec$19
    });

var UpdateContact = {
  Config: Config$19,
  $$Request: $$Request$19
};

function contact_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "primaryPhone",
                    false,
                    Spice.stringToJson(v.primaryPhone)
                  ]]));
}

function contact_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var primaryPhone = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "primaryPhone"), null));
  if (primaryPhone.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              primaryPhone: primaryPhone._0
            }
          };
  }
  var e = primaryPhone._0;
  return {
          TAG: "Error",
          _0: {
            path: ".primaryPhone" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function body_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "login",
                    false,
                    Spice.stringToJson(v.login)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ],
                  [
                    "contact",
                    false,
                    contact_encode(v.contact)
                  ]
                ]));
}

function body_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var login = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "login"), null));
  if (login.TAG === "Ok") {
    var password = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
    if (password.TAG === "Ok") {
      var contact = contact_decode(Belt_Option.getWithDefault(Js_dict.get(v, "contact"), null));
      if (contact.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  login: login._0,
                  password: password._0,
                  contact: contact._0
                }
              };
      }
      var e = contact._0;
      return {
              TAG: "Error",
              _0: {
                path: ".contact" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = password._0;
    return {
            TAG: "Error",
            _0: {
              path: ".password" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = login._0;
  return {
          TAG: "Error",
          _0: {
            path: ".login" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var response_encode$20 = Spice.unitToJson;

var response_decode$20 = Spice.unitFromJson;

function exec$20(param) {
  return Axios.WithResult.post(undefined, body_encode$11(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$20, (function (error) {
                return Belt_Option.mapWithDefault(error.response, {
                            NAME: "default",
                            VAL: error
                          }, (function (param) {
                              var status = param.status;
                              var match = param.data.type;
                              if (status === 404) {
                                if (match === "transporter_not_found") {
                                  return {
                                          NAME: "custom",
                                          VAL: "transporterNotFound"
                                        };
                                } else {
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                                }
                              }
                              if (status !== 409) {
                                return {
                                        NAME: "default",
                                        VAL: error
                                      };
                              }
                              switch (match) {
                                case "email_not_available" :
                                    return {
                                            NAME: "custom",
                                            VAL: "emailNotAvailable"
                                          };
                                case "login_not_available" :
                                    return {
                                            NAME: "custom",
                                            VAL: "loginNotAvailable"
                                          };
                                default:
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                              }
                            }));
              }), "/transporters/" + param[0] + "/carriers");
}

var Config$20 = {
  contact_encode: contact_encode,
  contact_decode: contact_decode,
  body_encode: body_encode$11,
  body_decode: body_decode$11,
  response_encode: response_encode$20,
  response_decode: response_decode$20,
  exec: exec$20
};

var $$Request$20 = Toolkit__Request.Make({
      exec: exec$20
    });

var CreateCarrier = {
  Config: Config$20,
  $$Request: $$Request$20
};

function contact_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "primaryPhone",
                    false,
                    Spice.stringToJson(v.primaryPhone)
                  ]]));
}

function contact_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var primaryPhone = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "primaryPhone"), null));
  if (primaryPhone.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              primaryPhone: primaryPhone._0
            }
          };
  }
  var e = primaryPhone._0;
  return {
          TAG: "Error",
          _0: {
            path: ".primaryPhone" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function body_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "login",
                    false,
                    Spice.stringToJson(v.login)
                  ],
                  [
                    "password",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.password)
                  ],
                  [
                    "contact",
                    false,
                    contact_encode$1(v.contact)
                  ]
                ]));
}

function body_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var login = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "login"), null));
  if (login.TAG === "Ok") {
    var password = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
    if (password.TAG === "Ok") {
      var contact = contact_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "contact"), null));
      if (contact.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  login: login._0,
                  password: password._0,
                  contact: contact._0
                }
              };
      }
      var e = contact._0;
      return {
              TAG: "Error",
              _0: {
                path: ".contact" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = password._0;
    return {
            TAG: "Error",
            _0: {
              path: ".password" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = login._0;
  return {
          TAG: "Error",
          _0: {
            path: ".login" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var response_encode$21 = Spice.unitToJson;

var response_decode$21 = Spice.unitFromJson;

function exec$21(param) {
  return Axios.WithResult.put(undefined, body_encode$12(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$21, (function (error) {
                return Belt_Option.mapWithDefault(error.response, {
                            NAME: "default",
                            VAL: error
                          }, (function (param) {
                              var status = param.status;
                              var match = param.data.type;
                              if (status !== 404) {
                                if (status !== 409 || match !== "login_not_available") {
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                                } else {
                                  return {
                                          NAME: "custom",
                                          VAL: "loginNotAvailable"
                                        };
                                }
                              }
                              switch (match) {
                                case "contact_not_found" :
                                    return {
                                            NAME: "custom",
                                            VAL: "carrierNotFound"
                                          };
                                case "transporter_not_found" :
                                    return {
                                            NAME: "custom",
                                            VAL: "transporterNotFound"
                                          };
                                default:
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                              }
                            }));
              }), "/transporters/" + param[0] + "/carriers/" + param[1]);
}

var Config$21 = {
  contact_encode: contact_encode$1,
  contact_decode: contact_decode$1,
  body_encode: body_encode$12,
  body_decode: body_decode$12,
  response_encode: response_encode$21,
  response_decode: response_decode$21,
  exec: exec$21
};

var $$Request$21 = Toolkit__Request.Make({
      exec: exec$21
    });

var UpdateCarrier = {
  Config: Config$21,
  $$Request: $$Request$21
};

var response_encode$22 = Spice.unitToJson;

var response_decode$22 = Spice.unitFromJson;

function exec$22(param) {
  return Axios.WithResult.$$delete(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$22, (function (error) {
                return Belt_Option.mapWithDefault(error.response, {
                            NAME: "default",
                            VAL: error
                          }, (function (param) {
                              var status = param.status;
                              var match = param.data.type;
                              if (status === 403) {
                                if (match === "has_non_idle_deliveries") {
                                  return {
                                          NAME: "custom",
                                          VAL: "hasNonIdleDeliveries"
                                        };
                                } else {
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                                }
                              }
                              if (status !== 404) {
                                return {
                                        NAME: "default",
                                        VAL: error
                                      };
                              }
                              switch (match) {
                                case "contact_not_found" :
                                    return {
                                            NAME: "custom",
                                            VAL: "carrierNotFound"
                                          };
                                case "transporter_not_found" :
                                    return {
                                            NAME: "custom",
                                            VAL: "transporterNotFound"
                                          };
                                default:
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                              }
                            }));
              }), "/transporters/" + param[0] + "/carriers/" + param[1]);
}

var Config$22 = {
  response_encode: response_encode$22,
  response_decode: response_decode$22,
  exec: exec$22
};

var $$Request$22 = Toolkit__Request.Make({
      exec: exec$22
    });

var DeleteCarrier = {
  Config: Config$22,
  $$Request: $$Request$22
};

var response_encode$23 = Spice.unitToJson;

var response_decode$23 = Spice.unitFromJson;

function exec$23(param) {
  return Axios.WithResult.$$delete(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$23, (function (error) {
                return Belt_Option.mapWithDefault(error.response, {
                            NAME: "default",
                            VAL: error
                          }, (function (param) {
                              var status = param.status;
                              var match = param.data.type;
                              if (status !== 403) {
                                if (status !== 404) {
                                  return {
                                          NAME: "default",
                                          VAL: error
                                        };
                                }
                                switch (match) {
                                  case "contact_not_found" :
                                      return {
                                              NAME: "custom",
                                              VAL: "contactNotFound"
                                            };
                                  case "transporter_not_found" :
                                      return {
                                              NAME: "custom",
                                              VAL: "transporterNotFound"
                                            };
                                  default:
                                    return {
                                            NAME: "default",
                                            VAL: error
                                          };
                                }
                              } else {
                                switch (match) {
                                  case "cannot_destroy_all_transporter_location_contacts" :
                                      return {
                                              NAME: "custom",
                                              VAL: "cannotDeleteLastContact"
                                            };
                                  case "has_non_idle_deliveries" :
                                      return {
                                              NAME: "custom",
                                              VAL: "hasNonIdleDeliveries"
                                            };
                                  default:
                                    return {
                                            NAME: "default",
                                            VAL: error
                                          };
                                }
                              }
                            }));
              }), "/transporters/" + param[0] + "/contacts/" + param[1]);
}

var Config$23 = {
  response_encode: response_encode$23,
  response_decode: response_decode$23,
  exec: exec$23
};

var $$Request$23 = Toolkit__Request.Make({
      exec: exec$23
    });

var DeleteContact = {
  Config: Config$23,
  $$Request: $$Request$23
};

function body_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "startsAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startsAt)
                  ]]));
}

function body_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startsAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startsAt"), null));
  if (startsAt.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              startsAt: startsAt._0
            }
          };
  }
  var e = startsAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startsAt" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$24 = Spice.unitToJson;

var response_decode$24 = Spice.unitFromJson;

function config$1(param) {
  return {
          kyInstance: kyInstance,
          path: param[0] + "/deliveries/" + param[1] + "/refinedSlot",
          requestOptions: {
            method: "PUT",
            json: body_encode$13(param[2])
          }
        };
}

var Update = {
  body_encode: body_encode$13,
  body_decode: body_decode$13,
  response_encode: response_encode$24,
  response_decode: response_decode$24,
  config: config$1
};

var RefinedSlot = {
  Update: Update
};

var Deliveries = {
  RefinedSlot: RefinedSlot
};

var Coordinates;

var StatusProvider;

export {
  Coordinates ,
  config ,
  baseUrl ,
  deliveriesBaseUrl ,
  RefusalCause ,
  StatusProvider ,
  DeliveryFlowType ,
  PlanState ,
  AddressLift ,
  Problem ,
  GetCourierDetails ,
  GetTransporterInformations ,
  GetDeliveries ,
  GetDeliveriesCounts ,
  PostExcelExport ,
  RefuseDelivery ,
  AcceptAndAssignDelivery ,
  AssignDelivery ,
  PostAutoRefusal ,
  GetDelivery ,
  GetChangesHistory ,
  GetPictures ,
  GetPackets ,
  PostStatusProvider ,
  PostComment ,
  GetComments ,
  GetSettings ,
  UpdateSettings ,
  CreateContact ,
  UpdateContact ,
  CreateCarrier ,
  UpdateCarrier ,
  DeleteCarrier ,
  DeleteContact ,
  Deliveries ,
}
/* kyInstance Not a pure module */
