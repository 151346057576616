

import * as Axios from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Axios.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Toolkit__Request.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as ColiswebApi__V6_Geo from "../v6/ColiswebApi__V6_Geo.bs.js";
import * as ColiswebApi__V5_Utils from "./ColiswebApi__V5_Utils.bs.js";
import * as Decoders__UnitMeasure from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Decoders__UnitMeasure.bs.js";
import * as ColiswebApi__V5_Shared from "./ColiswebApi__V5_Shared.bs.js";

var arg = ColiswebApi__Env.v5ApiUrl;

var arg$1 = ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000;

function config(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  var partial_arg = true;
  return function (param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
    var tmp = {};
    if (param !== undefined) {
      tmp.url = param;
    }
    if (param$1 !== undefined) {
      tmp._method = param$1;
    }
    if (arg !== undefined) {
      tmp.baseURL = arg;
    }
    if (param$2 !== undefined) {
      tmp.transformRequest = Caml_option.valFromOption(param$2);
    }
    if (param$3 !== undefined) {
      tmp.transformResponse = Caml_option.valFromOption(param$3);
    }
    if (param$4 !== undefined) {
      tmp.headers = Caml_option.valFromOption(param$4);
    }
    if (param$5 !== undefined) {
      tmp.params = Caml_option.valFromOption(param$5);
    }
    if (param$6 !== undefined) {
      tmp.paramsSerializer = Caml_option.valFromOption(param$6);
    }
    if (param$7 !== undefined) {
      tmp.data = Caml_option.valFromOption(param$7);
    }
    if (arg$1 !== undefined) {
      tmp.timeout = arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.withCredentials = partial_arg;
    }
    if (param$8 !== undefined) {
      tmp.adapter = Caml_option.valFromOption(param$8);
    }
    if (param$9 !== undefined) {
      tmp.auth = Caml_option.valFromOption(param$9);
    }
    if (param$10 !== undefined) {
      tmp.responseType = param$10;
    }
    if (param$11 !== undefined) {
      tmp.xsrfCookieName = param$11;
    }
    if (param$12 !== undefined) {
      tmp.xsrfHeaderName = param$12;
    }
    if (param$13 !== undefined) {
      tmp.onUploadProgress = Caml_option.valFromOption(param$13);
    }
    if (param$14 !== undefined) {
      tmp.onDownloadProgress = Caml_option.valFromOption(param$14);
    }
    if (param$15 !== undefined) {
      tmp.maxContentLength = param$15;
    }
    if (param$16 !== undefined) {
      tmp.validateStatus = Caml_option.valFromOption(param$16);
    }
    if (param$17 !== undefined) {
      tmp.maxRedirects = param$17;
    }
    if (param$18 !== undefined) {
      tmp.socketPath = param$18;
    }
    if (param$19 !== undefined) {
      tmp.proxy = Caml_option.valFromOption(param$19);
    }
    if (param$20 !== undefined) {
      tmp.cancelToken = Caml_option.valFromOption(param$20);
    }
    return tmp;
  };
}

function baseUrl(clientId) {
  return "/clients/" + clientId;
}

function skill_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
    if (tag.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var isDefault = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isDefault"), null));
        if (isDefault.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    tag: tag._0,
                    description: description._0,
                    isDefault: isDefault._0
                  }
                };
        }
        var e = isDefault._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".isDefault" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = tag._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tag" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function heftingOptions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var sidewalk = Spice.optionFromJson(ColiswebApi__V5_Utils.HeftingOption.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "sidewalk"), null));
  if (sidewalk.TAG === "Ok") {
    var inRoom = Spice.optionFromJson(ColiswebApi__V5_Utils.HeftingOption.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "inRoom"), null));
    if (inRoom.TAG === "Ok") {
      var trailed = Spice.optionFromJson(ColiswebApi__V5_Utils.HeftingOption.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "trailed"), null));
      if (trailed.TAG === "Ok") {
        var $$default = Spice.optionFromJson(ColiswebApi__V5_Utils.HeftingOption.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "default"), null));
        if ($$default.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    sidewalk: sidewalk._0,
                    inRoom: inRoom._0,
                    trailed: trailed._0,
                    default: $$default._0
                  }
                };
        }
        var e = $$default._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".default" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = trailed._0;
      return {
              TAG: "Error",
              _0: {
                path: ".trailed" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = inRoom._0;
    return {
            TAG: "Error",
            _0: {
              path: ".inRoom" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = sidewalk._0;
  return {
          TAG: "Error",
          _0: {
            path: ".sidewalk" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function heftingOptions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "sidewalk",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.HeftingOption.t_encode, v.sidewalk)
                  ],
                  [
                    "inRoom",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.HeftingOption.t_encode, v.inRoom)
                  ],
                  [
                    "trailed",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.HeftingOption.t_encode, v.trailed)
                  ],
                  [
                    "default",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.HeftingOption.t_encode, v.default)
                  ]
                ]));
}

function skill_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ],
                  [
                    "isDefault",
                    false,
                    Spice.boolToJson(v.isDefault)
                  ]
                ]));
}

function response_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "heftingOptions",
                    false,
                    heftingOptions_encode(v.heftingOptions)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode, v.skills)
                  ]
                ]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var heftingOptions = heftingOptions_decode(Belt_Option.getWithDefault(Js_dict.get(v, "heftingOptions"), null));
  if (heftingOptions.TAG === "Ok") {
    var skills = Spice.arrayFromJson(skill_decode, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
    if (skills.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                heftingOptions: heftingOptions._0,
                skills: skills._0
              }
            };
    }
    var e = skills._0;
    return {
            TAG: "Error",
            _0: {
              path: ".skills" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = heftingOptions._0;
  return {
          TAG: "Error",
          _0: {
            path: ".heftingOptions" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function rawSkill_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
    if (tag.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var isHeftingOption = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isHeftingOption"), null));
        if (isHeftingOption.TAG === "Ok") {
          var isDefault = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isDefault"), null));
          if (isDefault.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      id: id._0,
                      tag: tag._0,
                      description: description._0,
                      isHeftingOption: isHeftingOption._0,
                      isDefault: isDefault._0
                    }
                  };
          }
          var e = isDefault._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".isDefault" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = isHeftingOption._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".isHeftingOption" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = tag._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tag" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function rawSkill_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ],
                  [
                    "isHeftingOption",
                    false,
                    Spice.boolToJson(v.isHeftingOption)
                  ],
                  [
                    "isDefault",
                    false,
                    Spice.boolToJson(v.isDefault)
                  ]
                ]));
}

function skills_encode(v) {
  return Spice.arrayToJson(rawSkill_encode, v);
}

function skills_decode(v) {
  return Spice.arrayFromJson(rawSkill_decode, v);
}

function exec(clientId) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (function (data) {
                var skills = Spice.arrayFromJson(rawSkill_decode, data);
                if (skills.TAG !== "Ok") {
                  return skills;
                }
                var skills$1 = skills._0;
                var getHeftingOption = function (option) {
                  return Belt_Array.get(Belt_Array.keepMap(skills$1, (function (skill) {
                                    var tag = ColiswebApi__V5_Utils.HeftingOption.t_decode(skill.tag);
                                    if (tag.TAG === "Ok" && tag._0 === option) {
                                      return skill;
                                    }
                                    
                                  })), 0);
                };
                var inRoom = getHeftingOption("roomdelivery");
                var sidewalk = getHeftingOption("sidewalkdelivery");
                var trailed = getHeftingOption("traileddelivery");
                var tmp;
                var exit = 0;
                var exit$1 = 0;
                if (sidewalk !== undefined && sidewalk.isDefault) {
                  tmp = "sidewalkdelivery";
                } else {
                  exit$1 = 2;
                }
                if (exit$1 === 2) {
                  if (inRoom !== undefined && inRoom.isDefault) {
                    tmp = "roomdelivery";
                  } else {
                    exit = 1;
                  }
                }
                if (exit === 1) {
                  tmp = trailed !== undefined && trailed.isDefault ? "traileddelivery" : undefined;
                }
                return {
                        TAG: "Ok",
                        _0: {
                          heftingOptions: {
                            sidewalk: Belt_Option.map(sidewalk, (function (param) {
                                    return "sidewalkdelivery";
                                  })),
                            inRoom: Belt_Option.map(inRoom, (function (param) {
                                    return "roomdelivery";
                                  })),
                            trailed: Belt_Option.map(trailed, (function (param) {
                                    return "traileddelivery";
                                  })),
                            default: tmp
                          },
                          skills: Belt_Array.keepMap(skills$1, (function (skill) {
                                  if (skill.isHeftingOption) {
                                    return ;
                                  } else {
                                    return {
                                            id: skill.id,
                                            tag: skill.tag,
                                            description: skill.description,
                                            isDefault: skill.isDefault
                                          };
                                  }
                                }))
                        }
                      };
              }), undefined, "/clients/" + clientId + "/skills");
}

var Config = {
  response_encode: response_encode,
  response_decode: response_decode,
  skill_encode: skill_encode,
  skill_decode: skill_decode,
  heftingOptions_encode: heftingOptions_encode,
  heftingOptions_decode: heftingOptions_decode,
  skills_encode: skills_encode,
  skills_decode: skills_decode,
  rawSkill_encode: rawSkill_encode,
  rawSkill_decode: rawSkill_decode,
  exec: exec
};

var $$Request = Toolkit__Request.Make({
      exec: exec
    });

var GetEligibleOptions = {
  Config: Config,
  $$Request: $$Request
};

function t_encode(v) {
  switch (v) {
    case "client_not_found" :
        return "client_not_found";
    case "store_not_found" :
        return "store_not_found";
    case "uneligible_location" :
        return "uneligible_location";
    case "origin_geocode_failure" :
        return "origin_geocode_failure";
    case "destination_geocode_failure" :
        return "destination_geocode_failure";
    case "impossible_to_get_the_distance" :
        return "impossible_to_get_the_distance";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "origin_missing_postal_code" :
        return "origin_missing_postal_code";
    case "destination_missing_postal_code" :
        return "destination_missing_postal_code";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("uneligible_location" === v) {
          return {
                  TAG: "Ok",
                  _0: "uneligible_location"
                };
        } else if ("origin_geocode_failure" === v) {
          return {
                  TAG: "Ok",
                  _0: "origin_geocode_failure"
                };
        } else if ("destination_geocode_failure" === v) {
          return {
                  TAG: "Ok",
                  _0: "destination_geocode_failure"
                };
        } else if ("impossible_to_get_the_distance" === v) {
          return {
                  TAG: "Ok",
                  _0: "impossible_to_get_the_distance"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("origin_missing_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "origin_missing_postal_code"
                };
        } else if ("destination_missing_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "destination_missing_postal_code"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error = {
  t_encode: t_encode,
  t_decode: t_decode
};

function administrativeArea_decode(v) {
  return ColiswebApi__V6_Geo.GeocodeLocation.administrativeArea_decode(v);
}

function bodyCoords_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lat = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lat"), null));
  if (lat.TAG === "Ok") {
    var lng = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lng"), null));
    if (lng.TAG === "Ok") {
      var level = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "level"), null));
      if (level.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  lat: lat._0,
                  lng: lng._0,
                  level: level._0
                }
              };
      }
      var e = level._0;
      return {
              TAG: "Error",
              _0: {
                path: ".level" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = lng._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lng" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = lat._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lat" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function bodyCoords_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lat",
                    false,
                    Spice.floatToJson(v.lat)
                  ],
                  [
                    "lng",
                    false,
                    Spice.floatToJson(v.lng)
                  ],
                  [
                    "level",
                    false,
                    Spice.stringToJson(v.level)
                  ]
                ]));
}

function administrativeArea_encode(v) {
  return ColiswebApi__V6_Geo.GeocodeLocation.administrativeArea_encode(v);
}

function location_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
    if (postalCode.TAG === "Ok") {
      var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
      if (city.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
          if (storeId.TAG === "Ok") {
            var coords = Spice.optionFromJson(bodyCoords_decode, Belt_Option.getWithDefault(Js_dict.get(v, "coords"), null));
            if (coords.TAG === "Ok") {
              var administrativeArea = Spice.optionFromJson(administrativeArea_decode, Belt_Option.getWithDefault(Js_dict.get(v, "administrativeArea"), null));
              if (administrativeArea.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          line1: line1._0,
                          postalCode: postalCode._0,
                          city: city._0,
                          country: country._0,
                          storeId: storeId._0,
                          coords: coords._0,
                          administrativeArea: administrativeArea._0
                        }
                      };
              }
              var e = administrativeArea._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".administrativeArea" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = coords._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".coords" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = storeId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".storeId" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = city._0;
      return {
              TAG: "Error",
              _0: {
                path: ".city" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = postalCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".postalCode" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function location_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "coords",
                    false,
                    Spice.optionToJson(bodyCoords_encode, v.coords)
                  ],
                  [
                    "administrativeArea",
                    false,
                    Spice.optionToJson(administrativeArea_encode, v.administrativeArea)
                  ]
                ]));
}

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "origin",
                    false,
                    location_encode(v.origin)
                  ],
                  [
                    "destination",
                    false,
                    location_encode(v.destination)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var origin = location_decode(Belt_Option.getWithDefault(Js_dict.get(v, "origin"), null));
  if (origin.TAG === "Ok") {
    var destination = location_decode(Belt_Option.getWithDefault(Js_dict.get(v, "destination"), null));
    if (destination.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                origin: origin._0,
                destination: destination._0
              }
            };
    }
    var e = destination._0;
    return {
            TAG: "Error",
            _0: {
              path: ".destination" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = origin._0;
  return {
          TAG: "Error",
          _0: {
            path: ".origin" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function responseCoords_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lat",
                    false,
                    Spice.floatToJson(v.lat)
                  ],
                  [
                    "lng",
                    false,
                    Spice.floatToJson(v.lng)
                  ],
                  [
                    "level",
                    false,
                    Spice.stringToJson(v.level)
                  ],
                  [
                    "administrativeArea",
                    false,
                    Spice.optionToJson(administrativeArea_encode, v.administrativeArea)
                  ]
                ]));
}

function responseCoords_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lat = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lat"), null));
  if (lat.TAG === "Ok") {
    var lng = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lng"), null));
    if (lng.TAG === "Ok") {
      var level = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "level"), null));
      if (level.TAG === "Ok") {
        var administrativeArea = Spice.optionFromJson(administrativeArea_decode, Belt_Option.getWithDefault(Js_dict.get(v, "administrativeArea"), null));
        if (administrativeArea.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    lat: lat._0,
                    lng: lng._0,
                    level: level._0,
                    administrativeArea: administrativeArea._0
                  }
                };
        }
        var e = administrativeArea._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".administrativeArea" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = level._0;
      return {
              TAG: "Error",
              _0: {
                path: ".level" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = lng._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lng" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = lat._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lat" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "origin",
                    false,
                    responseCoords_encode(v.origin)
                  ],
                  [
                    "destination",
                    false,
                    responseCoords_encode(v.destination)
                  ],
                  [
                    "distanceKm",
                    false,
                    Curry._1(Decoders__UnitMeasure.Dimension.Km.t_encode, v.distanceKm)
                  ]
                ]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var origin = responseCoords_decode(Belt_Option.getWithDefault(Js_dict.get(v, "origin"), null));
  if (origin.TAG === "Ok") {
    var destination = responseCoords_decode(Belt_Option.getWithDefault(Js_dict.get(v, "destination"), null));
    if (destination.TAG === "Ok") {
      var distanceKm = Curry._1(Decoders__UnitMeasure.Dimension.Km.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "distanceKm"), null));
      if (distanceKm.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  origin: origin._0,
                  destination: destination._0,
                  distanceKm: distanceKm._0
                }
              };
      }
      var e = distanceKm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".distanceKm" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = destination._0;
    return {
            TAG: "Error",
            _0: {
              path: ".destination" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = origin._0;
  return {
          TAG: "Error",
          _0: {
            path: ".origin" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$1(param) {
  return Axios.WithResult.post(undefined, body_encode(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$1, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), "/clients/" + param[0] + "/geographicEligibility");
}

var Config$1 = {
  body_encode: body_encode,
  body_decode: body_decode,
  location_encode: location_encode,
  location_decode: location_decode,
  administrativeArea_encode: administrativeArea_encode,
  administrativeArea_decode: administrativeArea_decode,
  bodyCoords_encode: bodyCoords_encode,
  bodyCoords_decode: bodyCoords_decode,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  responseCoords_encode: responseCoords_encode,
  responseCoords_decode: responseCoords_decode,
  exec: exec$1
};

var $$Request$1 = Toolkit__Request.Make({
      exec: exec$1
    });

var CheckEligibleLocation = {
  $$Error: $$Error,
  Config: Config$1,
  $$Request: $$Request$1
};

function t_encode$1(v) {
  switch (v) {
    case "store_not_found" :
        return "store_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "postal_code_not_found" :
        return "postal_code_not_found";
    case "empty_response_from_packing" :
        return "empty_response_from_packing";
    case "package_too_big" :
        return "package_too_big";
    case "packet_too_big" :
        return "packet_too_big";
    case "too_heavy_packet" :
        return "too_heavy_packet";
    case "distance_too_far" :
        return "distance_too_far";
    case "total_weight_too_heavy" :
        return "total_weight_too_heavy";
    case "too_heavy_single_packet" :
        return "too_heavy_single_packet";
    case "conditions_not_matched_for_delivery" :
        return "conditions_not_matched_for_delivery";
    case "not_configured_to_order_deliveries" :
        return "not_configured_to_order_deliveries";
    case "generated_calendar_is_empty" :
        return "generated_calendar_is_empty";
    case "invalid_required_skills" :
        return "invalid_required_skills";
    case "no_floor_or_lift_has_been_specified" :
        return "no_floor_or_lift_has_been_specified";
    case "impossible_to_get_the_distance" :
        return "impossible_to_get_the_distance";
    case "no_prices_found" :
        return "no_prices_found";
    case "contract_not_found_for_packaging" :
        return "contract_not_found_for_packaging";
    case "area_not_eligible" :
        return "area_not_eligible";
    
  }
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("postal_code_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "postal_code_not_found"
                };
        } else if ("empty_response_from_packing" === v) {
          return {
                  TAG: "Ok",
                  _0: "empty_response_from_packing"
                };
        } else if ("package_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_too_big"
                };
        } else if ("packet_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "packet_too_big"
                };
        } else if ("too_heavy_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_packet"
                };
        } else if ("distance_too_far" === v) {
          return {
                  TAG: "Ok",
                  _0: "distance_too_far"
                };
        } else if ("total_weight_too_heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "total_weight_too_heavy"
                };
        } else if ("too_heavy_single_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_single_packet"
                };
        } else if ("conditions_not_matched_for_delivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "conditions_not_matched_for_delivery"
                };
        } else if ("not_configured_to_order_deliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_configured_to_order_deliveries"
                };
        } else if ("generated_calendar_is_empty" === v) {
          return {
                  TAG: "Ok",
                  _0: "generated_calendar_is_empty"
                };
        } else if ("invalid_required_skills" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_required_skills"
                };
        } else if ("no_floor_or_lift_has_been_specified" === v) {
          return {
                  TAG: "Ok",
                  _0: "no_floor_or_lift_has_been_specified"
                };
        } else if ("impossible_to_get_the_distance" === v) {
          return {
                  TAG: "Ok",
                  _0: "impossible_to_get_the_distance"
                };
        } else if ("no_prices_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "no_prices_found"
                };
        } else if ("contract_not_found_for_packaging" === v) {
          return {
                  TAG: "Ok",
                  _0: "contract_not_found_for_packaging"
                };
        } else if ("area_not_eligible" === v) {
          return {
                  TAG: "Ok",
                  _0: "area_not_eligible"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$1 = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function administrativeArea_encode$1(v) {
  return ColiswebApi__V6_Geo.GeocodeLocation.administrativeArea_encode(v);
}

function administrativeArea_decode$1(v) {
  return ColiswebApi__V6_Geo.GeocodeLocation.administrativeArea_decode(v);
}

function additionalInformation_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ],
                  [
                    "hasLift",
                    false,
                    ColiswebApi__V5_Utils.Lift.t_encode(v.hasLift)
                  ]
                ]));
}

function additionalInformation_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
  if (floor.TAG === "Ok") {
    var hasLift = ColiswebApi__V5_Utils.Lift.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "hasLift"), null));
    if (hasLift.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                floor: floor._0,
                hasLift: hasLift._0
              }
            };
    }
    var e = hasLift._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hasLift" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = floor._0;
  return {
          TAG: "Error",
          _0: {
            path: ".floor" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function address_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
  if (latitude.TAG === "Ok") {
    var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
    if (longitude.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var storeId = Toolkit__Decoders.$$Option.t_decode(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
        if (storeId.TAG === "Ok") {
          var additionalInformation = Toolkit__Decoders.$$Option.t_decode(additionalInformation_decode, Belt_Option.getWithDefault(Js_dict.get(v, "additionalInformation"), null));
          if (additionalInformation.TAG === "Ok") {
            var geocodingLevel = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "geocodingLevel"), null));
            if (geocodingLevel.TAG === "Ok") {
              var administrativeArea = Spice.optionFromJson(administrativeArea_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "administrativeArea"), null));
              if (administrativeArea.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          latitude: latitude._0,
                          longitude: longitude._0,
                          postalCode: postalCode._0,
                          storeId: storeId._0,
                          additionalInformation: additionalInformation._0,
                          geocodingLevel: geocodingLevel._0,
                          administrativeArea: administrativeArea._0
                        }
                      };
              }
              var e = administrativeArea._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".administrativeArea" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = geocodingLevel._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".geocodingLevel" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = additionalInformation._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".additionalInformation" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = storeId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".storeId" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = longitude._0;
    return {
            TAG: "Error",
            _0: {
              path: ".longitude" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = latitude._0;
  return {
          TAG: "Error",
          _0: {
            path: ".latitude" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function packaging_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var numberOfPackets = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "numberOfPackets"), null));
  if (numberOfPackets.TAG === "Ok") {
    var heightCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
    if (heightCm.TAG === "Ok") {
      var lengthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
      if (lengthCm.TAG === "Ok") {
        var widthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
        if (widthCm.TAG === "Ok") {
          var weightKg = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
          if (weightKg.TAG === "Ok") {
            var maxPacketWeightKg = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "maxPacketWeightKg"), null));
            if (maxPacketWeightKg.TAG === "Ok") {
              var maxPacketLengthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "maxPacketLengthCm"), null));
              if (maxPacketLengthCm.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          numberOfPackets: numberOfPackets._0,
                          heightCm: heightCm._0,
                          lengthCm: lengthCm._0,
                          widthCm: widthCm._0,
                          weightKg: weightKg._0,
                          maxPacketWeightKg: maxPacketWeightKg._0,
                          maxPacketLengthCm: maxPacketLengthCm._0
                        }
                      };
              }
              var e = maxPacketLengthCm._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".maxPacketLengthCm" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = maxPacketWeightKg._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".maxPacketWeightKg" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = weightKg._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".weightKg" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = widthCm._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".widthCm" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = lengthCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lengthCm" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = heightCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".heightCm" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = numberOfPackets._0;
  return {
          TAG: "Error",
          _0: {
            path: ".numberOfPackets" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function packaging_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "numberOfPackets",
                    false,
                    Spice.intToJson(v.numberOfPackets)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.floatToJson(v.heightCm)
                  ],
                  [
                    "lengthCm",
                    false,
                    Spice.floatToJson(v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.floatToJson(v.widthCm)
                  ],
                  [
                    "weightKg",
                    false,
                    Spice.floatToJson(v.weightKg)
                  ],
                  [
                    "maxPacketWeightKg",
                    false,
                    Spice.floatToJson(v.maxPacketWeightKg)
                  ],
                  [
                    "maxPacketLengthCm",
                    false,
                    Spice.floatToJson(v.maxPacketLengthCm)
                  ]
                ]));
}

function address_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "storeId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "additionalInformation",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(additionalInformation_encode, v.additionalInformation)
                  ],
                  [
                    "geocodingLevel",
                    false,
                    Spice.stringToJson(v.geocodingLevel)
                  ],
                  [
                    "administrativeArea",
                    false,
                    Spice.optionToJson(administrativeArea_encode$1, v.administrativeArea)
                  ]
                ]));
}

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startDate)
                  ],
                  [
                    "endDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.endDate)
                  ],
                  [
                    "pickupAddress",
                    false,
                    address_encode(v.pickupAddress)
                  ],
                  [
                    "shippingAddress",
                    false,
                    address_encode(v.shippingAddress)
                  ],
                  [
                    "packaging",
                    false,
                    packaging_encode(v.packaging)
                  ],
                  [
                    "requiredSkills",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.requiredSkills)
                  ]
                ]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startDate"), null));
  if (startDate.TAG === "Ok") {
    var endDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endDate"), null));
    if (endDate.TAG === "Ok") {
      var pickupAddress = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pickupAddress"), null));
      if (pickupAddress.TAG === "Ok") {
        var shippingAddress = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "shippingAddress"), null));
        if (shippingAddress.TAG === "Ok") {
          var packaging = packaging_decode(Belt_Option.getWithDefault(Js_dict.get(v, "packaging"), null));
          if (packaging.TAG === "Ok") {
            var requiredSkills = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "requiredSkills"), null));
            if (requiredSkills.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        startDate: startDate._0,
                        endDate: endDate._0,
                        pickupAddress: pickupAddress._0,
                        shippingAddress: shippingAddress._0,
                        packaging: packaging._0,
                        requiredSkills: requiredSkills._0
                      }
                    };
            }
            var e = requiredSkills._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".requiredSkills" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = packaging._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".packaging" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = shippingAddress._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".shippingAddress" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = pickupAddress._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pickupAddress" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = endDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endDate" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = startDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startDate" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function calendar_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startsAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startsAt"), null));
  if (startsAt.TAG === "Ok") {
    var endsAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endsAt"), null));
    if (endsAt.TAG === "Ok") {
      var now = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "now"), null));
      if (now.TAG === "Ok") {
        var slotSizeMinutes = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "slotSizeMinutes"), null));
        if (slotSizeMinutes.TAG === "Ok") {
          var priceWithTaxes = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceWithTaxes"), null));
          if (priceWithTaxes.TAG === "Ok") {
            var priceWithoutTaxes = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxes"), null));
            if (priceWithoutTaxes.TAG === "Ok") {
              var priceIsValid = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceIsValid"), null));
              if (priceIsValid.TAG === "Ok") {
                var suggestedSlot = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "suggestedSlot"), null));
                if (suggestedSlot.TAG === "Ok") {
                  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
                  if (id.TAG === "Ok") {
                    var isAvailable = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isAvailable"), null));
                    if (isAvailable.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: {
                                startsAt: startsAt._0,
                                endsAt: endsAt._0,
                                now: now._0,
                                slotSizeMinutes: slotSizeMinutes._0,
                                priceWithTaxes: priceWithTaxes._0,
                                priceWithoutTaxes: priceWithoutTaxes._0,
                                priceIsValid: priceIsValid._0,
                                suggestedSlot: suggestedSlot._0,
                                id: id._0,
                                isAvailable: isAvailable._0
                              }
                            };
                    }
                    var e = isAvailable._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".isAvailable" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = id._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".id" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = suggestedSlot._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".suggestedSlot" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = priceIsValid._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".priceIsValid" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = priceWithoutTaxes._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".priceWithoutTaxes" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = priceWithTaxes._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".priceWithTaxes" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = slotSizeMinutes._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".slotSizeMinutes" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = now._0;
      return {
              TAG: "Error",
              _0: {
                path: ".now" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = endsAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endsAt" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = startsAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startsAt" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function calendar_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startsAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startsAt)
                  ],
                  [
                    "endsAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.endsAt)
                  ],
                  [
                    "now",
                    false,
                    Spice.boolToJson(v.now)
                  ],
                  [
                    "slotSizeMinutes",
                    false,
                    Spice.intToJson(v.slotSizeMinutes)
                  ],
                  [
                    "priceWithTaxes",
                    false,
                    Spice.stringToJson(v.priceWithTaxes)
                  ],
                  [
                    "priceWithoutTaxes",
                    false,
                    Spice.stringToJson(v.priceWithoutTaxes)
                  ],
                  [
                    "priceIsValid",
                    false,
                    Spice.boolToJson(v.priceIsValid)
                  ],
                  [
                    "suggestedSlot",
                    false,
                    Spice.boolToJson(v.suggestedSlot)
                  ],
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "isAvailable",
                    false,
                    Spice.boolToJson(v.isAvailable)
                  ]
                ]));
}

function response_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "calendar",
                    false,
                    Spice.arrayToJson(calendar_encode, v.calendar)
                  ],
                  [
                    "deliveryRequestId",
                    false,
                    Spice.stringToJson(v.deliveryRequestId)
                  ]
                ]));
}

function response_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var calendar = Spice.arrayFromJson(calendar_decode, Belt_Option.getWithDefault(Js_dict.get(v, "calendar"), null));
  if (calendar.TAG === "Ok") {
    var deliveryRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryRequestId"), null));
    if (deliveryRequestId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                calendar: calendar._0,
                deliveryRequestId: deliveryRequestId._0
              }
            };
    }
    var e = deliveryRequestId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryRequestId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = calendar._0;
  return {
          TAG: "Error",
          _0: {
            path: ".calendar" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$2(param) {
  return Axios.WithResult.post(undefined, body_encode$1(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$2, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$1(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), "/clients/" + param[0] + "/deliveryOptions");
}

var Config$2 = {
  administrativeArea_encode: administrativeArea_encode$1,
  administrativeArea_decode: administrativeArea_decode$1,
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  address_encode: address_encode,
  address_decode: address_decode,
  additionalInformation_encode: additionalInformation_encode,
  additionalInformation_decode: additionalInformation_decode,
  packaging_encode: packaging_encode,
  packaging_decode: packaging_decode,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  calendar_encode: calendar_encode,
  calendar_decode: calendar_decode,
  exec: exec$2
};

var $$Request$2 = Toolkit__Request.Make({
      exec: exec$2
    });

var GetEligibleTimeSlots = {
  $$Error: $$Error$1,
  Config: Config$2,
  $$Request: $$Request$2
};

var DeliveryEligibility = {
  GetEligibleOptions: GetEligibleOptions,
  CheckEligibleLocation: CheckEligibleLocation,
  GetEligibleTimeSlots: GetEligibleTimeSlots
};

function response_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "crmReference",
                    false,
                    Spice.optionToJson(Identifiers.ClientCRMReference.t_encode, v.crmReference)
                  ]
                ]));
}

function response_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var crmReference = Spice.optionFromJson(Identifiers.ClientCRMReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "crmReference"), null));
      if (crmReference.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  name: name._0,
                  crmReference: crmReference._0
                }
              };
      }
      var e = crmReference._0;
      return {
              TAG: "Error",
              _0: {
                path: ".crmReference" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$3(clientId) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$3, undefined, "/clients/" + clientId);
}

var Config$3 = {
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  exec: exec$3
};

var $$Request$3 = Toolkit__Request.Make({
      exec: exec$3
    });

var GetClient = {
  Config: Config$3,
  $$Request: $$Request$3
};

function clientContainer_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              clientId: clientId._0
            }
          };
  }
  var e = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function clientContainer_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]]));
}

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "clients",
                    false,
                    Spice.arrayToJson(clientContainer_encode, v.clients)
                  ]]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clients = Spice.arrayFromJson(clientContainer_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clients"), null));
  if (clients.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              clients: clients._0
            }
          };
  }
  var e = clients._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clients" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function client_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function client_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "clients",
                    false,
                    Spice.arrayToJson(client_encode, v.clients)
                  ]]));
}

function response_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clients = Spice.arrayFromJson(client_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clients"), null));
  if (clients.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              clients: clients._0
            }
          };
  }
  var e = clients._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clients" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$4(clientIds) {
  return Axios.WithResult.post(undefined, body_encode$2({
                  clients: Belt_Array.map(clientIds, (function (clientId) {
                          return {
                                  clientId: clientId
                                };
                        }))
                }), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$4, undefined, "/clients");
}

var Config$4 = {
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  clientContainer_encode: clientContainer_encode,
  clientContainer_decode: clientContainer_decode,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  client_encode: client_encode,
  client_decode: client_decode,
  exec: exec$4
};

var $$Request$4 = Toolkit__Request.Make({
      exec: exec$4
    });

var GetClientNames = {
  Config: Config$4,
  $$Request: $$Request$4
};

function filters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "storeName",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.storeName)
                  ]]));
}

function filters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeName = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "storeName"), null));
  if (storeName.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              storeName: storeName._0
            }
          };
  }
  var e = storeName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeName" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function pagination_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function pagination_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                page: page._0,
                pageSize: pageSize._0
              }
            };
    }
    var e = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode(v.filters)
                  ],
                  [
                    "pagination",
                    false,
                    pagination_encode(v.pagination)
                  ]
                ]));
}

function body_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var pagination = pagination_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
    if (pagination.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                filters: filters._0,
                pagination: pagination._0
              }
            };
    }
    var e = pagination._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pagination" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function argument_encode(v) {
  return [
          Curry._1(Identifiers.ClientId.t_encode, v[0]),
          body_encode$3(v[1])
        ];
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.ClientId.t_decode, v0);
  var match$1 = body_decode$3(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function address_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
          if (latitude.TAG === "Ok") {
            var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
            if (longitude.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        line1: line1._0,
                        city: city._0,
                        postalCode: postalCode._0,
                        country: country._0,
                        latitude: latitude._0,
                        longitude: longitude._0
                      }
                    };
            }
            var e = longitude._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".longitude" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = latitude._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".latitude" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function address_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ]
                ]));
}

function store_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var storeCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
      if (storeCode.TAG === "Ok") {
        var address = address_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
        if (address.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    name: name._0,
                    storeCode: storeCode._0,
                    address: address._0
                  }
                };
        }
        var e = address._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".address" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = storeCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".storeCode" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function store_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "storeCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.storeCode)
                  ],
                  [
                    "address",
                    false,
                    address_encode$1(v.address)
                  ]
                ]));
}

function response_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "stores",
                    false,
                    Spice.arrayToJson(store_encode, v.stores)
                  ],
                  [
                    "storesCount",
                    false,
                    Spice.intToJson(v.storesCount)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ]
                ]));
}

function response_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var stores = Spice.arrayFromJson(store_decode, Belt_Option.getWithDefault(Js_dict.get(v, "stores"), null));
  if (stores.TAG === "Ok") {
    var storesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "storesCount"), null));
    if (storesCount.TAG === "Ok") {
      var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
      if (pagesCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  stores: stores._0,
                  storesCount: storesCount._0,
                  pagesCount: pagesCount._0
                }
              };
      }
      var e = pagesCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagesCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = storesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = stores._0;
  return {
          TAG: "Error",
          _0: {
            path: ".stores" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$5(param) {
  return Axios.WithResult.post(undefined, body_encode$3(param[1]), {
              baseURL: ColiswebApi__Env.v5ApiUrl,
              timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 30000,
              withCredentials: true
            }, response_decode$5, undefined, "/clients/" + param[0] + "/stores");
}

var Config$5 = {
  filters_encode: filters_encode,
  filters_decode: filters_decode,
  pagination_encode: pagination_encode,
  pagination_decode: pagination_decode,
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  store_encode: store_encode,
  store_decode: store_decode,
  address_encode: address_encode$1,
  address_decode: address_decode$1,
  exec: exec$5
};

var $$Request$5 = Toolkit__Request.Make({
      exec: exec$5
    });

var GetStores = {
  Config: Config$5,
  $$Request: $$Request$5
};

function argument_encode$1(v) {
  return [
          Curry._1(Identifiers.ClientId.t_encode, v[0]),
          Curry._1(Identifiers.StoreCode.t_encode, v[1])
        ];
}

function argument_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.ClientId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.StoreCode.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function address_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ],
                  [
                    "lift",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.Lift.t_encode, v.lift)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ]
                ]));
}

function address_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
          if (latitude.TAG === "Ok") {
            var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
            if (longitude.TAG === "Ok") {
              var lift = Spice.optionFromJson(ColiswebApi__V5_Utils.Lift.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lift"), null));
              if (lift.TAG === "Ok") {
                var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                if (floor.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            line1: line1._0,
                            city: city._0,
                            postalCode: postalCode._0,
                            country: country._0,
                            latitude: latitude._0,
                            longitude: longitude._0,
                            lift: lift._0,
                            floor: floor._0
                          }
                        };
                }
                var e = floor._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".floor" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = lift._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".lift" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = longitude._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".longitude" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = latitude._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".latitude" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function store_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "storeCode",
                    false,
                    Spice.optionToJson(Identifiers.StoreCode.t_encode, v.storeCode)
                  ],
                  [
                    "address",
                    false,
                    address_encode$2(v.address)
                  ]
                ]));
}

function store_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var storeCode = Spice.optionFromJson(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
      if (storeCode.TAG === "Ok") {
        var address = address_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
        if (address.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    name: name._0,
                    storeCode: storeCode._0,
                    address: address._0
                  }
                };
        }
        var e = address._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".address" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = storeCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".storeCode" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var response_encode$6 = store_encode$1;

var response_decode$6 = store_decode$1;

function exec$6(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$6, undefined, "/clients/" + param[0] + "/stores/storeCode/" + param[1]);
}

var Config$6 = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  store_encode: store_encode$1,
  store_decode: store_decode$1,
  address_encode: address_encode$2,
  address_decode: address_decode$2,
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  exec: exec$6
};

var $$Request$6 = Toolkit__Request.Make({
      exec: exec$6
    });

var GetStoreAddressByStoreCode = {
  Config: Config$6,
  $$Request: $$Request$6
};

function address_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ]
                ]));
}

function address_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
          if (latitude.TAG === "Ok") {
            var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
            if (longitude.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        line1: line1._0,
                        city: city._0,
                        postalCode: postalCode._0,
                        country: country._0,
                        latitude: latitude._0,
                        longitude: longitude._0
                      }
                    };
            }
            var e = longitude._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".longitude" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = latitude._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".latitude" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function store_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "storeCode",
                    false,
                    Spice.optionToJson(Identifiers.StoreCode.t_encode, v.storeCode)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "address",
                    false,
                    address_encode$3(v.address)
                  ]
                ]));
}

function store_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var storeCode = Spice.optionFromJson(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
    if (storeCode.TAG === "Ok") {
      var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
      if (name.TAG === "Ok") {
        var address = address_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
        if (address.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    storeCode: storeCode._0,
                    name: name._0,
                    address: address._0
                  }
                };
        }
        var e = address._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".address" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = name._0;
      return {
              TAG: "Error",
              _0: {
                path: ".name" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = storeCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeCode" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$7(v) {
  return Spice.arrayToJson(store_encode$2, v);
}

function response_decode$7(v) {
  return Spice.arrayFromJson(store_decode$2, v);
}

function exec$7(param) {
  var search = param[1];
  var config$1 = search === "" ? config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined) : config(undefined, undefined, undefined, undefined, undefined, {
            search: search
          }, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  return Axios.WithResult.get(undefined, config$1, (function (data) {
                var withCoordinates = function (data) {
                  return Belt_Array.keep(data, (function (v) {
                                if (v.address.latitude == null) {
                                  return false;
                                } else {
                                  return !(v.address.longitude == null);
                                }
                              }));
                };
                return Spice.arrayFromJson(store_decode$2, withCoordinates(data));
              }), undefined, "/clients/" + param[0] + "/stores");
}

var Config$7 = {
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  store_encode: store_encode$2,
  store_decode: store_decode$2,
  address_encode: address_encode$3,
  address_decode: address_decode$3,
  exec: exec$7
};

var $$Request$7 = Toolkit__Request.Make({
      exec: exec$7
    });

var SearchStore = {
  Config: Config$7,
  $$Request: $$Request$7
};

function address_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ]
                ]));
}

function address_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
    if (line2.TAG === "Ok") {
      var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
      if (city.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
          if (postalCode.TAG === "Ok") {
            var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
            if (latitude.TAG === "Ok") {
              var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
              if (longitude.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          line1: line1._0,
                          line2: line2._0,
                          city: city._0,
                          country: country._0,
                          postalCode: postalCode._0,
                          latitude: latitude._0,
                          longitude: longitude._0
                        }
                      };
              }
              var e = longitude._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".longitude" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = latitude._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".latitude" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = postalCode._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".postalCode" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = city._0;
      return {
              TAG: "Error",
              _0: {
                path: ".city" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = line2._0;
    return {
            TAG: "Error",
            _0: {
              path: ".line2" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function customer_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var firstName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
    if (firstName.TAG === "Ok") {
      var lastName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
      if (lastName.TAG === "Ok") {
        var phone1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
        if (phone1.TAG === "Ok") {
          var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
          if (phone2.TAG === "Ok") {
            var address = address_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
            if (address.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        id: id._0,
                        firstName: firstName._0,
                        lastName: lastName._0,
                        phone1: phone1._0,
                        phone2: phone2._0,
                        address: address._0
                      }
                    };
            }
            var e = address._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".address" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = phone2._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".phone2" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = phone1._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phone1" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = lastName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastName" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = firstName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstName" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function customer_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.stringToJson(v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.stringToJson(v.lastName)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.stringToJson(v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ],
                  [
                    "address",
                    false,
                    address_encode$4(v.address)
                  ]
                ]));
}

function response_encode$8(v) {
  return Spice.arrayToJson(customer_encode, v);
}

function response_decode$8(v) {
  return Spice.arrayFromJson(customer_decode, v);
}

function exec$8(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (function (data) {
                var withCoordinates = function (data) {
                  return Belt_Array.keep(data, (function (v) {
                                if (!(v.address.latitude == null) && !(v.address.longitude == null)) {
                                  return !(v.address.phone1 == null);
                                } else {
                                  return false;
                                }
                              }));
                };
                return Spice.arrayFromJson(customer_decode, withCoordinates(data));
              }), undefined, "/clients/" + param[0] + ("/customers?query=" + param[1]));
}

var Config$8 = {
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  customer_encode: customer_encode,
  customer_decode: customer_decode,
  address_encode: address_encode$4,
  address_decode: address_decode$4,
  exec: exec$8
};

var $$Request$8 = Toolkit__Request.Make({
      exec: exec$8
    });

var SearchCustomer = {
  Config: Config$8,
  $$Request: $$Request$8
};

function response_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "statusProvider",
                    false,
                    Spice.arrayToJson(ColiswebApi__V5_Utils.StatusProvider.t_encode, v.statusProvider)
                  ],
                  [
                    "statusOriginator",
                    false,
                    Spice.arrayToJson(ColiswebApi__V5_Utils.StatusOriginator.t_encode, v.statusOriginator)
                  ]
                ]));
}

function response_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var statusProvider = Spice.arrayFromJson(ColiswebApi__V5_Utils.StatusProvider.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
  if (statusProvider.TAG === "Ok") {
    var statusOriginator = Spice.arrayFromJson(ColiswebApi__V5_Utils.StatusOriginator.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "statusOriginator"), null));
    if (statusOriginator.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                statusProvider: statusProvider._0,
                statusOriginator: statusOriginator._0
              }
            };
    }
    var e = statusOriginator._0;
    return {
            TAG: "Error",
            _0: {
              path: ".statusOriginator" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = statusProvider._0;
  return {
          TAG: "Error",
          _0: {
            path: ".statusProvider" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$9(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$9, undefined, "/clients/" + param[0] + ("/states?filter=" + param[1]));
}

var Config$9 = {
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  exec: exec$9
};

var $$Request$9 = Toolkit__Request.Make({
      exec: exec$9
    });

var GetStates = {
  Config: Config$9,
  $$Request: $$Request$9
};

function delivery_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "timeSlot",
                    false,
                    ColiswebApi__V5_Utils.TimeSlot.t_encode(v.timeSlot)
                  ],
                  [
                    "routeId",
                    false,
                    Spice.optionToJson(Identifiers.RouteId.t_encode, v.routeId)
                  ]
                ]));
}

function delivery_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
    if (orderId.TAG === "Ok") {
      var statusProvider = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
      if (statusProvider.TAG === "Ok") {
        var timeSlot = ColiswebApi__V5_Utils.TimeSlot.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
        if (timeSlot.TAG === "Ok") {
          var routeId = Spice.optionFromJson(Identifiers.RouteId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "routeId"), null));
          if (routeId.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      id: id._0,
                      orderId: orderId._0,
                      statusProvider: statusProvider._0,
                      timeSlot: timeSlot._0,
                      routeId: routeId._0
                    }
                  };
          }
          var e = routeId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".routeId" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = timeSlot._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".timeSlot" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = statusProvider._0;
      return {
              TAG: "Error",
              _0: {
                path: ".statusProvider" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = orderId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".orderId" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function argument_encode$2(v) {
  return [
          Curry._1(Identifiers.ClientId.t_encode, v[0]),
          Curry._1(Identifiers.OrderId.t_encode, v[1])
        ];
}

function argument_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.ClientId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.OrderId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveries",
                    false,
                    Spice.arrayToJson(delivery_encode, v.deliveries)
                  ]]));
}

function response_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveries = Spice.arrayFromJson(delivery_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveries"), null));
  if (deliveries.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveries: deliveries._0
            }
          };
  }
  var e = deliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveries" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$10(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$10, undefined, "/clients/" + param[0] + "/orders/" + param[1] + "/deliveries");
}

var Config$10 = {
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  delivery_encode: delivery_encode,
  delivery_decode: delivery_decode,
  exec: exec$10
};

var $$Request$10 = Toolkit__Request.Make({
      exec: exec$10
    });

var GetOrderDeliveriesForClient = {
  Config: Config$10,
  $$Request: $$Request$10
};

function timeSlot_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end)
                  ]
                ]));
}

function timeSlot_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function filters_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ref",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.ref)
                  ],
                  [
                    "timeSlot",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(timeSlot_encode, v.timeSlot)
                  ],
                  [
                    "statusProvider",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(ColiswebApi__V5_Utils.StatusProvider.t_encode, param);
                          }), v.statusProvider)
                  ],
                  [
                    "originatorName",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.originatorName)
                  ],
                  [
                    "deliveryId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.deliveryId)
                  ],
                  [
                    "pickup",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.pickup)
                  ],
                  [
                    "dropoff",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.dropoff)
                  ]
                ]));
}

function filters_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ref = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "ref"), null));
  if (ref.TAG === "Ok") {
    var timeSlot = Toolkit__Decoders.$$Option.t_decode(timeSlot_decode, Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
    if (timeSlot.TAG === "Ok") {
      var statusProvider = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(ColiswebApi__V5_Utils.StatusProvider.t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
      if (statusProvider.TAG === "Ok") {
        var originatorName = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "originatorName"), null));
        if (originatorName.TAG === "Ok") {
          var deliveryId = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
          if (deliveryId.TAG === "Ok") {
            var pickup = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
            if (pickup.TAG === "Ok") {
              var dropoff = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "dropoff"), null));
              if (dropoff.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          ref: ref._0,
                          timeSlot: timeSlot._0,
                          statusProvider: statusProvider._0,
                          originatorName: originatorName._0,
                          deliveryId: deliveryId._0,
                          pickup: pickup._0,
                          dropoff: dropoff._0
                        }
                      };
              }
              var e = dropoff._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".dropoff" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = pickup._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".pickup" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = deliveryId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".deliveryId" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = originatorName._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".originatorName" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = statusProvider._0;
      return {
              TAG: "Error",
              _0: {
                path: ".statusProvider" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = timeSlot._0;
    return {
            TAG: "Error",
            _0: {
              path: ".timeSlot" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = ref._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ref" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function sort_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "ref",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.ref)
                  ],
                  [
                    "timeSlot",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.timeSlot)
                  ],
                  [
                    "statusProvider",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.statusProvider)
                  ],
                  [
                    "originatorName",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.originatorName)
                  ],
                  [
                    "pickup",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.pickup)
                  ],
                  [
                    "dropoff",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.dropoff)
                  ]
                ]));
}

function sort_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ref = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref"), null));
  if (ref.TAG === "Ok") {
    var timeSlot = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
    if (timeSlot.TAG === "Ok") {
      var statusProvider = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
      if (statusProvider.TAG === "Ok") {
        var originatorName = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "originatorName"), null));
        if (originatorName.TAG === "Ok") {
          var pickup = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
          if (pickup.TAG === "Ok") {
            var dropoff = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "dropoff"), null));
            if (dropoff.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        ref: ref._0,
                        timeSlot: timeSlot._0,
                        statusProvider: statusProvider._0,
                        originatorName: originatorName._0,
                        pickup: pickup._0,
                        dropoff: dropoff._0
                      }
                    };
            }
            var e = dropoff._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".dropoff" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = pickup._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".pickup" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = originatorName._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".originatorName" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = statusProvider._0;
      return {
              TAG: "Error",
              _0: {
                path: ".statusProvider" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = timeSlot._0;
    return {
            TAG: "Error",
            _0: {
              path: ".timeSlot" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = ref._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ref" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function body_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode$1(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode(v.sort)
                  ],
                  [
                    "page",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.Int.t_encode(v.pageSize)
                  ]
                ]));
}

function body_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var page = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
      if (page.TAG === "Ok") {
        var pageSize = Toolkit__Decoders.Int.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
        if (pageSize.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    filters: filters._0,
                    sort: sort._0,
                    page: page._0,
                    pageSize: pageSize._0
                  }
                };
        }
        var e = pageSize._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".pageSize" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = page._0;
      return {
              TAG: "Error",
              _0: {
                path: ".page" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function point_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.name)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.city)
                  ],
                  [
                    "hubSlugId",
                    false,
                    Spice.optionToJson(Identifiers.HubSlugId.t_encode, v.hubSlugId)
                  ]
                ]));
}

function point_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
    if (firstName.TAG === "Ok") {
      var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
      if (lastName.TAG === "Ok") {
        var postalCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
        if (postalCode.TAG === "Ok") {
          var city = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "city"), (function (param) {
                      return Spice.optionFromJson(Spice.stringFromJson, param);
                    })), {
                TAG: "Ok",
                _0: undefined
              });
          if (city.TAG === "Ok") {
            var hubSlugId = Spice.optionFromJson(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubSlugId"), null));
            if (hubSlugId.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        name: name._0,
                        firstName: firstName._0,
                        lastName: lastName._0,
                        postalCode: postalCode._0,
                        city: city._0,
                        hubSlugId: hubSlugId._0
                      }
                    };
            }
            var e = hubSlugId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".hubSlugId" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = city._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".city" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = postalCode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".postalCode" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = lastName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastName" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = firstName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstName" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function priceComposition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var amount = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "amount"), null));
  if (amount.TAG === "Ok") {
    var label = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                amount: amount._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function priceComposition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "amount",
                    false,
                    Spice.floatToJson(v.amount)
                  ],
                  [
                    "label",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.label)
                  ]
                ]));
}

function delivery_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "isArchived",
                    false,
                    Spice.boolToJson(v.isArchived)
                  ],
                  [
                    "ref1",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref2)
                  ],
                  [
                    "originatorName",
                    false,
                    Spice.stringToJson(v.originatorName)
                  ],
                  [
                    "originatorId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.originatorId)
                  ],
                  [
                    "pickup",
                    false,
                    Spice.optionToJson(point_encode, v.pickup)
                  ],
                  [
                    "shipping",
                    false,
                    Spice.optionToJson(point_encode, v.shipping)
                  ],
                  [
                    "haveStoreComments",
                    false,
                    Spice.boolToJson(v.haveStoreComments)
                  ],
                  [
                    "timeSlot",
                    false,
                    timeSlot_encode(v.timeSlot)
                  ],
                  [
                    "previousTimeSlot",
                    false,
                    Spice.optionToJson(timeSlot_encode, v.previousTimeSlot)
                  ],
                  [
                    "salesConsultantName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.salesConsultantName)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "statusOriginator",
                    false,
                    ColiswebApi__V5_Utils.StatusOriginator.t_encode(v.statusOriginator)
                  ],
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Utils.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "priceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithoutTaxEur)
                  ],
                  [
                    "priceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithTaxEur)
                  ],
                  [
                    "priceWithoutTaxEurComposition",
                    false,
                    Spice.arrayToJson(priceComposition_encode, v.priceWithoutTaxEurComposition)
                  ],
                  [
                    "customerPriceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithoutTaxEur)
                  ],
                  [
                    "customerPriceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEurComposition",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(priceComposition_encode, param);
                          }), v.customerPriceWithoutTaxEurComposition)
                  ],
                  [
                    "hasBeenRescheduled",
                    false,
                    Spice.boolToJson(v.hasBeenRescheduled)
                  ],
                  [
                    "fromRescheduledDeliveryId",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryId.t_encode, v.fromRescheduledDeliveryId)
                  ]
                ]));
}

function delivery_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var isArchived = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isArchived"), null));
    if (isArchived.TAG === "Ok") {
      var ref1 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
      if (ref1.TAG === "Ok") {
        var ref2 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
        if (ref2.TAG === "Ok") {
          var originatorName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "originatorName"), null));
          if (originatorName.TAG === "Ok") {
            var originatorId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "originatorId"), null));
            if (originatorId.TAG === "Ok") {
              var pickup = Spice.optionFromJson(point_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
              if (pickup.TAG === "Ok") {
                var shipping = Spice.optionFromJson(point_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shipping"), null));
                if (shipping.TAG === "Ok") {
                  var haveStoreComments = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "haveStoreComments"), null));
                  if (haveStoreComments.TAG === "Ok") {
                    var timeSlot = timeSlot_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
                    if (timeSlot.TAG === "Ok") {
                      var previousTimeSlot = Spice.optionFromJson(timeSlot_decode, Belt_Option.getWithDefault(Js_dict.get(v, "previousTimeSlot"), null));
                      if (previousTimeSlot.TAG === "Ok") {
                        var salesConsultantName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "salesConsultantName"), null));
                        if (salesConsultantName.TAG === "Ok") {
                          var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
                          if (createdAt.TAG === "Ok") {
                            var statusOriginator = ColiswebApi__V5_Utils.StatusOriginator.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusOriginator"), null));
                            if (statusOriginator.TAG === "Ok") {
                              var statusProvider = ColiswebApi__V5_Utils.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
                              if (statusProvider.TAG === "Ok") {
                                var priceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEur"), null));
                                if (priceWithoutTaxEur.TAG === "Ok") {
                                  var priceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithTaxEur"), null));
                                  if (priceWithTaxEur.TAG === "Ok") {
                                    var priceWithoutTaxEurComposition = Spice.arrayFromJson(priceComposition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEurComposition"), null));
                                    if (priceWithoutTaxEurComposition.TAG === "Ok") {
                                      var customerPriceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEur"), null));
                                      if (customerPriceWithoutTaxEur.TAG === "Ok") {
                                        var customerPriceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithTaxEur"), null));
                                        if (customerPriceWithTaxEur.TAG === "Ok") {
                                          var customerPriceWithoutTaxEurComposition = Spice.optionFromJson((function (param) {
                                                  return Spice.arrayFromJson(priceComposition_decode, param);
                                                }), Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEurComposition"), null));
                                          if (customerPriceWithoutTaxEurComposition.TAG === "Ok") {
                                            var hasBeenRescheduled = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "hasBeenRescheduled"), null));
                                            if (hasBeenRescheduled.TAG === "Ok") {
                                              var fromRescheduledDeliveryId = Spice.optionFromJson(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "fromRescheduledDeliveryId"), null));
                                              if (fromRescheduledDeliveryId.TAG === "Ok") {
                                                return {
                                                        TAG: "Ok",
                                                        _0: {
                                                          id: id._0,
                                                          isArchived: isArchived._0,
                                                          ref1: ref1._0,
                                                          ref2: ref2._0,
                                                          originatorName: originatorName._0,
                                                          originatorId: originatorId._0,
                                                          pickup: pickup._0,
                                                          shipping: shipping._0,
                                                          haveStoreComments: haveStoreComments._0,
                                                          timeSlot: timeSlot._0,
                                                          previousTimeSlot: previousTimeSlot._0,
                                                          salesConsultantName: salesConsultantName._0,
                                                          createdAt: createdAt._0,
                                                          statusOriginator: statusOriginator._0,
                                                          statusProvider: statusProvider._0,
                                                          priceWithoutTaxEur: priceWithoutTaxEur._0,
                                                          priceWithTaxEur: priceWithTaxEur._0,
                                                          priceWithoutTaxEurComposition: priceWithoutTaxEurComposition._0,
                                                          customerPriceWithoutTaxEur: customerPriceWithoutTaxEur._0,
                                                          customerPriceWithTaxEur: customerPriceWithTaxEur._0,
                                                          customerPriceWithoutTaxEurComposition: customerPriceWithoutTaxEurComposition._0,
                                                          hasBeenRescheduled: hasBeenRescheduled._0,
                                                          fromRescheduledDeliveryId: fromRescheduledDeliveryId._0
                                                        }
                                                      };
                                              }
                                              var e = fromRescheduledDeliveryId._0;
                                              return {
                                                      TAG: "Error",
                                                      _0: {
                                                        path: ".fromRescheduledDeliveryId" + e.path,
                                                        message: e.message,
                                                        value: e.value
                                                      }
                                                    };
                                            }
                                            var e$1 = hasBeenRescheduled._0;
                                            return {
                                                    TAG: "Error",
                                                    _0: {
                                                      path: ".hasBeenRescheduled" + e$1.path,
                                                      message: e$1.message,
                                                      value: e$1.value
                                                    }
                                                  };
                                          }
                                          var e$2 = customerPriceWithoutTaxEurComposition._0;
                                          return {
                                                  TAG: "Error",
                                                  _0: {
                                                    path: ".customerPriceWithoutTaxEurComposition" + e$2.path,
                                                    message: e$2.message,
                                                    value: e$2.value
                                                  }
                                                };
                                        }
                                        var e$3 = customerPriceWithTaxEur._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".customerPriceWithTaxEur" + e$3.path,
                                                  message: e$3.message,
                                                  value: e$3.value
                                                }
                                              };
                                      }
                                      var e$4 = customerPriceWithoutTaxEur._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".customerPriceWithoutTaxEur" + e$4.path,
                                                message: e$4.message,
                                                value: e$4.value
                                              }
                                            };
                                    }
                                    var e$5 = priceWithoutTaxEurComposition._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".priceWithoutTaxEurComposition" + e$5.path,
                                              message: e$5.message,
                                              value: e$5.value
                                            }
                                          };
                                  }
                                  var e$6 = priceWithTaxEur._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".priceWithTaxEur" + e$6.path,
                                            message: e$6.message,
                                            value: e$6.value
                                          }
                                        };
                                }
                                var e$7 = priceWithoutTaxEur._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".priceWithoutTaxEur" + e$7.path,
                                          message: e$7.message,
                                          value: e$7.value
                                        }
                                      };
                              }
                              var e$8 = statusProvider._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".statusProvider" + e$8.path,
                                        message: e$8.message,
                                        value: e$8.value
                                      }
                                    };
                            }
                            var e$9 = statusOriginator._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".statusOriginator" + e$9.path,
                                      message: e$9.message,
                                      value: e$9.value
                                    }
                                  };
                          }
                          var e$10 = createdAt._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".createdAt" + e$10.path,
                                    message: e$10.message,
                                    value: e$10.value
                                  }
                                };
                        }
                        var e$11 = salesConsultantName._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".salesConsultantName" + e$11.path,
                                  message: e$11.message,
                                  value: e$11.value
                                }
                              };
                      }
                      var e$12 = previousTimeSlot._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".previousTimeSlot" + e$12.path,
                                message: e$12.message,
                                value: e$12.value
                              }
                            };
                    }
                    var e$13 = timeSlot._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".timeSlot" + e$13.path,
                              message: e$13.message,
                              value: e$13.value
                            }
                          };
                  }
                  var e$14 = haveStoreComments._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".haveStoreComments" + e$14.path,
                            message: e$14.message,
                            value: e$14.value
                          }
                        };
                }
                var e$15 = shipping._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".shipping" + e$15.path,
                          message: e$15.message,
                          value: e$15.value
                        }
                      };
              }
              var e$16 = pickup._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".pickup" + e$16.path,
                        message: e$16.message,
                        value: e$16.value
                      }
                    };
            }
            var e$17 = originatorId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".originatorId" + e$17.path,
                      message: e$17.message,
                      value: e$17.value
                    }
                  };
          }
          var e$18 = originatorName._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".originatorName" + e$18.path,
                    message: e$18.message,
                    value: e$18.value
                  }
                };
        }
        var e$19 = ref2._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".ref2" + e$19.path,
                  message: e$19.message,
                  value: e$19.value
                }
              };
      }
      var e$20 = ref1._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ref1" + e$20.path,
                message: e$20.message,
                value: e$20.value
              }
            };
    }
    var e$21 = isArchived._0;
    return {
            TAG: "Error",
            _0: {
              path: ".isArchived" + e$21.path,
              message: e$21.message,
              value: e$21.value
            }
          };
  }
  var e$22 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$22.path,
            message: e$22.message,
            value: e$22.value
          }
        };
}

function response_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveries",
                    false,
                    Spice.arrayToJson(delivery_encode$1, v.deliveries)
                  ],
                  [
                    "deliveriesCount",
                    false,
                    Spice.intToJson(v.deliveriesCount)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ]
                ]));
}

function response_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveries = Spice.arrayFromJson(delivery_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "deliveries"), null));
  if (deliveries.TAG === "Ok") {
    var deliveriesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveriesCount"), null));
    if (deliveriesCount.TAG === "Ok") {
      var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
      if (pagesCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  deliveries: deliveries._0,
                  deliveriesCount: deliveriesCount._0,
                  pagesCount: pagesCount._0
                }
              };
      }
      var e = pagesCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagesCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = deliveriesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveriesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = deliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveries" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$11(param) {
  return Axios.WithResult.post(undefined, body_encode$4(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$11, undefined, "/clients/" + param[0] + "/deliveries");
}

var Config$11 = {
  timeSlot_encode: timeSlot_encode,
  timeSlot_decode: timeSlot_decode,
  filters_encode: filters_encode$1,
  filters_decode: filters_decode$1,
  sort_encode: sort_encode,
  sort_decode: sort_decode,
  body_encode: body_encode$4,
  body_decode: body_decode$4,
  point_encode: point_encode,
  point_decode: point_decode,
  delivery_encode: delivery_encode$1,
  delivery_decode: delivery_decode$1,
  priceComposition_encode: priceComposition_encode,
  priceComposition_decode: priceComposition_decode,
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  exec: exec$11
};

var $$Request$11 = Toolkit__Request.Make({
      exec: exec$11
    });

var GetDeliveries = {
  Config: Config$11,
  $$Request: $$Request$11
};

var response_encode$12 = Spice.unitToJson;

var response_decode$12 = Spice.unitFromJson;

function exec$12(param) {
  return Axios.WithResult.post(undefined, Object.assign(body_encode$4(param[1]), {
                  email: param[2]
                }), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$12, undefined, "/clients/" + param[0] + "/deliveries/export");
}

var Config$12 = {
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  exec: exec$12
};

var $$Request$12 = Toolkit__Request.Make({
      exec: exec$12
    });

var PostExcelExport = {
  Config: Config$12,
  $$Request: $$Request$12
};

function params_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startDate)
                  ],
                  [
                    "endDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.endDate)
                  ],
                  [
                    "deliveryStates",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(ColiswebApi__V5_Utils.StatusProvider.t_encode, param);
                          }), v.deliveryStates)
                  ],
                  [
                    "storeIds",
                    false,
                    Spice.arrayToJson(Identifiers.StoreId.t_encode, v.storeIds)
                  ]
                ]));
}

function params_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startDate"), null));
  if (startDate.TAG === "Ok") {
    var endDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endDate"), null));
    if (endDate.TAG === "Ok") {
      var deliveryStates = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(ColiswebApi__V5_Utils.StatusProvider.t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "deliveryStates"), null));
      if (deliveryStates.TAG === "Ok") {
        var storeIds = Spice.arrayFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeIds"), null));
        if (storeIds.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    startDate: startDate._0,
                    endDate: endDate._0,
                    deliveryStates: deliveryStates._0,
                    storeIds: storeIds._0
                  }
                };
        }
        var e = storeIds._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".storeIds" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = deliveryStates._0;
      return {
              TAG: "Error",
              _0: {
                path: ".deliveryStates" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = endDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endDate" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = startDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startDate" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function store_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var deliveriesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "deliveriesCount"), null));
      if (deliveriesCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  name: name._0,
                  deliveriesCount: deliveriesCount._0
                }
              };
      }
      var e = deliveriesCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".deliveriesCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function store_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "deliveriesCount",
                    false,
                    Spice.intToJson(v.deliveriesCount)
                  ]
                ]));
}

function response_encode$13(v) {
  return Spice.arrayToJson(store_encode$3, v);
}

function response_decode$13(v) {
  return Spice.arrayFromJson(store_decode$3, v);
}

function exec$13(param) {
  return Axios.WithResult.post(undefined, params_encode(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$13, undefined, "/clients/" + param[0] + "/deliveriesCountByStores");
}

var Config$13 = {
  params_encode: params_encode,
  params_decode: params_decode,
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  store_encode: store_encode$3,
  store_decode: store_decode$3,
  exec: exec$13
};

var $$Request$13 = Toolkit__Request.Make({
      exec: exec$13
    });

var GetDeliveriesCountByStores = {
  Config: Config$13,
  $$Request: $$Request$13
};

function baseUrl$1(clientId, deliveryId) {
  return "/clients/" + clientId + ("/deliveries/" + deliveryId);
}

function t_encode$2(v) {
  switch (v) {
    case "line1" :
        return "line1";
    case "line2" :
        return "line2";
    case "city" :
        return "city";
    case "country" :
        return "country";
    case "society" :
        return "society";
    case "floor" :
        return "floor";
    case "state_or_province" :
        return "state_or_province";
    case "postal_code" :
        return "postal_code";
    case "starts_at" :
        return "starts_at";
    case "ends_at" :
        return "ends_at";
    case "lift_information" :
        return "lift_information";
    case "additional_informations" :
        return "additional_informations";
    case "administrative_area_code2" :
        return "administrative_area_code2";
    case "administrative_area_code3" :
        return "administrative_area_code3";
    
  }
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("line1" === v) {
          return {
                  TAG: "Ok",
                  _0: "line1"
                };
        } else if ("line2" === v) {
          return {
                  TAG: "Ok",
                  _0: "line2"
                };
        } else if ("city" === v) {
          return {
                  TAG: "Ok",
                  _0: "city"
                };
        } else if ("country" === v) {
          return {
                  TAG: "Ok",
                  _0: "country"
                };
        } else if ("society" === v) {
          return {
                  TAG: "Ok",
                  _0: "society"
                };
        } else if ("floor" === v) {
          return {
                  TAG: "Ok",
                  _0: "floor"
                };
        } else if ("state_or_province" === v) {
          return {
                  TAG: "Ok",
                  _0: "state_or_province"
                };
        } else if ("postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "postal_code"
                };
        } else if ("starts_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "starts_at"
                };
        } else if ("ends_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "ends_at"
                };
        } else if ("lift_information" === v) {
          return {
                  TAG: "Ok",
                  _0: "lift_information"
                };
        } else if ("additional_informations" === v) {
          return {
                  TAG: "Ok",
                  _0: "additional_informations"
                };
        } else if ("administrative_area_code2" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code2"
                };
        } else if ("administrative_area_code3" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code3"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var FieldName = {
  t_encode: t_encode$2,
  t_decode: t_decode$2
};

function t_encode$3(v) {
  switch (v) {
    case "destination_address" :
        return "destination_address";
    case "slot" :
        return "slot";
    case "origin_address" :
        return "origin_address";
    
  }
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("destination_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "destination_address"
                };
        } else if ("slot" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot"
                };
        } else if ("origin_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "origin_address"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Type = {
  t_encode: t_encode$3,
  t_decode: t_decode$3
};

var ModificationHistory = {
  FieldName: FieldName,
  Type: Type
};

function priceComposition_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "amount",
                    false,
                    Spice.floatToJson(v.amount)
                  ],
                  [
                    "label",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.label)
                  ]
                ]));
}

function priceComposition_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var amount = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "amount"), null));
  if (amount.TAG === "Ok") {
    var label = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                amount: amount._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function customerSatisfaction_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "mark",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.mark)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "slotRespect",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.slotRespect)
                  ],
                  [
                    "carrierProfessionalism",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.carrierProfessionalism)
                  ],
                  [
                    "shippedProductState",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.shippedProductState)
                  ],
                  [
                    "indicationRespect",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.indicationRespect)
                  ],
                  [
                    "recommendation",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.recommendation)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ]
                ]));
}

function customerSatisfaction_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var mark = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "mark"), null));
  if (mark.TAG === "Ok") {
    var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
    if (comment.TAG === "Ok") {
      var slotRespect = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "slotRespect"), null));
      if (slotRespect.TAG === "Ok") {
        var carrierProfessionalism = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "carrierProfessionalism"), null));
        if (carrierProfessionalism.TAG === "Ok") {
          var shippedProductState = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "shippedProductState"), null));
          if (shippedProductState.TAG === "Ok") {
            var indicationRespect = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "indicationRespect"), null));
            if (indicationRespect.TAG === "Ok") {
              var recommendation = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "recommendation"), null));
              if (recommendation.TAG === "Ok") {
                var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
                if (createdAt.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            mark: mark._0,
                            comment: comment._0,
                            slotRespect: slotRespect._0,
                            carrierProfessionalism: carrierProfessionalism._0,
                            shippedProductState: shippedProductState._0,
                            indicationRespect: indicationRespect._0,
                            recommendation: recommendation._0,
                            createdAt: createdAt._0
                          }
                        };
                }
                var e = createdAt._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".createdAt" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = recommendation._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".recommendation" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = indicationRespect._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".indicationRespect" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = shippedProductState._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".shippedProductState" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = carrierProfessionalism._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".carrierProfessionalism" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = slotRespect._0;
      return {
              TAG: "Error",
              _0: {
                path: ".slotRespect" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = comment._0;
    return {
            TAG: "Error",
            _0: {
              path: ".comment" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = mark._0;
  return {
          TAG: "Error",
          _0: {
            path: ".mark" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function nullableDimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lengthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.widthCm)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.heightCm)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeM3)
                  ]
                ]));
}

function nullableDimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lengthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
  if (lengthCm.TAG === "Ok") {
    var widthCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
    if (widthCm.TAG === "Ok") {
      var heightCm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
      if (heightCm.TAG === "Ok") {
        var volumeM3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
        if (volumeM3.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    lengthCm: lengthCm._0,
                    widthCm: widthCm._0,
                    heightCm: heightCm._0,
                    volumeM3: volumeM3._0
                  }
                };
        }
        var e = volumeM3._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".volumeM3" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = heightCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".heightCm" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = widthCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".widthCm" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = lengthCm._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lengthCm" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function packetBarcode_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "barcode",
                    false,
                    Curry._1(Identifiers.Barcode.t_encode, v.barcode)
                  ]]));
}

function dimensions_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lengthCm",
                    false,
                    Spice.floatToJson(v.lengthCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.floatToJson(v.widthCm)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.floatToJson(v.heightCm)
                  ]
                ]));
}

function dimensions_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lengthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
  if (lengthCm.TAG === "Ok") {
    var widthCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
    if (widthCm.TAG === "Ok") {
      var heightCm = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
      if (heightCm.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  lengthCm: lengthCm._0,
                  widthCm: widthCm._0,
                  heightCm: heightCm._0
                }
              };
      }
      var e = heightCm._0;
      return {
              TAG: "Error",
              _0: {
                path: ".heightCm" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = widthCm._0;
    return {
            TAG: "Error",
            _0: {
              path: ".widthCm" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = lengthCm._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lengthCm" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function packetBarcode_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var barcode = Curry._1(Identifiers.Barcode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "barcode"), null));
  if (barcode.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              barcode: barcode._0
            }
          };
  }
  var e = barcode._0;
  return {
          TAG: "Error",
          _0: {
            path: ".barcode" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function packaging_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "dimensions",
                    false,
                    nullableDimensions_encode(v.dimensions)
                  ],
                  [
                    "weightKg",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.weightKg)
                  ],
                  [
                    "packetsCount",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.packetsCount)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "heaviestWeightKg",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.heaviestWeightKg)
                  ],
                  [
                    "largestVolumeCm3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.largestVolumeCm3)
                  ],
                  [
                    "longestPacketLength",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.longestPacketLength)
                  ],
                  [
                    "biggestPacketDimensions",
                    false,
                    Spice.optionToJson(dimensions_encode, v.biggestPacketDimensions)
                  ],
                  [
                    "longestPacketDimensions",
                    false,
                    Spice.optionToJson(dimensions_encode, v.longestPacketDimensions)
                  ],
                  [
                    "volumeCm3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeCm3)
                  ],
                  [
                    "packetsBarcode",
                    false,
                    Spice.arrayToJson(packetBarcode_encode, v.packetsBarcode)
                  ]
                ]));
}

function packaging_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var dimensions = nullableDimensions_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dimensions"), null));
  if (dimensions.TAG === "Ok") {
    var weightKg = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
    if (weightKg.TAG === "Ok") {
      var packetsCount = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "packetsCount"), null));
      if (packetsCount.TAG === "Ok") {
        var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
        if (description.TAG === "Ok") {
          var heaviestWeightKg = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heaviestWeightKg"), null));
          if (heaviestWeightKg.TAG === "Ok") {
            var largestVolumeCm3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "largestVolumeCm3"), null));
            if (largestVolumeCm3.TAG === "Ok") {
              var longestPacketLength = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "longestPacketLength"), null));
              if (longestPacketLength.TAG === "Ok") {
                var biggestPacketDimensions = Spice.optionFromJson(dimensions_decode, Belt_Option.getWithDefault(Js_dict.get(v, "biggestPacketDimensions"), null));
                if (biggestPacketDimensions.TAG === "Ok") {
                  var longestPacketDimensions = Spice.optionFromJson(dimensions_decode, Belt_Option.getWithDefault(Js_dict.get(v, "longestPacketDimensions"), null));
                  if (longestPacketDimensions.TAG === "Ok") {
                    var volumeCm3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeCm3"), null));
                    if (volumeCm3.TAG === "Ok") {
                      var packetsBarcode = Spice.arrayFromJson(packetBarcode_decode, Belt_Option.getWithDefault(Js_dict.get(v, "packetsBarcode"), null));
                      if (packetsBarcode.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: {
                                  dimensions: dimensions._0,
                                  weightKg: weightKg._0,
                                  packetsCount: packetsCount._0,
                                  description: description._0,
                                  heaviestWeightKg: heaviestWeightKg._0,
                                  largestVolumeCm3: largestVolumeCm3._0,
                                  longestPacketLength: longestPacketLength._0,
                                  biggestPacketDimensions: biggestPacketDimensions._0,
                                  longestPacketDimensions: longestPacketDimensions._0,
                                  volumeCm3: volumeCm3._0,
                                  packetsBarcode: packetsBarcode._0
                                }
                              };
                      }
                      var e = packetsBarcode._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".packetsBarcode" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = volumeCm3._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".volumeCm3" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = longestPacketDimensions._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".longestPacketDimensions" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = biggestPacketDimensions._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".biggestPacketDimensions" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = longestPacketLength._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".longestPacketLength" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = largestVolumeCm3._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".largestVolumeCm3" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = heaviestWeightKg._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".heaviestWeightKg" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = description._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".description" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = packetsCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".packetsCount" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = weightKg._0;
    return {
            TAG: "Error",
            _0: {
              path: ".weightKg" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = dimensions._0;
  return {
          TAG: "Error",
          _0: {
            path: ".dimensions" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function signatureTransition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var mobileStateUpdatedAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileStateUpdatedAt"), null));
    if (mobileStateUpdatedAt.TAG === "Ok") {
      var problem = Spice.optionFromJson(ColiswebApi__Utils.TransitionProblem.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "problem"), null));
      if (problem.TAG === "Ok") {
        var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
        if (comment.TAG === "Ok") {
          var isSuccessful = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSuccessful"), null));
          if (isSuccessful.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      createdAt: createdAt._0,
                      mobileStateUpdatedAt: mobileStateUpdatedAt._0,
                      problem: problem._0,
                      comment: comment._0,
                      isSuccessful: isSuccessful._0
                    }
                  };
          }
          var e = isSuccessful._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".isSuccessful" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = comment._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".comment" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = problem._0;
      return {
              TAG: "Error",
              _0: {
                path: ".problem" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = mobileStateUpdatedAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".mobileStateUpdatedAt" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function signatureTransition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "mobileStateUpdatedAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileStateUpdatedAt)
                  ],
                  [
                    "problem",
                    false,
                    Spice.optionToJson(ColiswebApi__Utils.TransitionProblem.t_encode, v.problem)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "isSuccessful",
                    false,
                    Spice.boolToJson(v.isSuccessful)
                  ]
                ]));
}

function signature_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "transition",
                    false,
                    Spice.optionToJson(signatureTransition_encode, v.transition)
                  ]
                ]));
}

function signature_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
  if (url.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var transition = Spice.optionFromJson(signatureTransition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transition"), null));
      if (transition.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  url: url._0,
                  name: name._0,
                  transition: transition._0
                }
              };
      }
      var e = transition._0;
      return {
              TAG: "Error",
              _0: {
                path: ".transition" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = url._0;
  return {
          TAG: "Error",
          _0: {
            path: ".url" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function timeSlot_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.end)
                  ]
                ]));
}

function timeSlot_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function skill_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var tag = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "tag"), null));
    if (tag.TAG === "Ok") {
      var description = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
      if (description.TAG === "Ok") {
        var isDefault = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isDefault"), Spice.boolFromJson), {
              TAG: "Ok",
              _0: false
            });
        if (isDefault.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    tag: tag._0,
                    description: description._0,
                    isDefault: isDefault._0
                  }
                };
        }
        var e = isDefault._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".isDefault" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = description._0;
      return {
              TAG: "Error",
              _0: {
                path: ".description" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = tag._0;
    return {
            TAG: "Error",
            _0: {
              path: ".tag" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function skill_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "tag",
                    false,
                    Spice.stringToJson(v.tag)
                  ],
                  [
                    "description",
                    false,
                    Spice.stringToJson(v.description)
                  ],
                  [
                    "isDefault",
                    false,
                    Spice.boolToJson(v.isDefault)
                  ]
                ]));
}

function skills_encode$1(v) {
  return Spice.arrayToJson(skill_encode$1, v);
}

function skills_decode$1(v) {
  return Spice.arrayFromJson(skill_decode$1, v);
}

function transition_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "event",
                    false,
                    ColiswebApi__V5_Utils.FullStatusProvider.t_encode(v.event)
                  ],
                  [
                    "dateTime",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.dateTime)
                  ],
                  [
                    "mobileDateTime",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.mobileDateTime)
                  ],
                  [
                    "problem",
                    false,
                    Spice.optionToJson(ColiswebApi__Utils.TransitionProblem.t_encode, v.problem)
                  ]
                ]));
}

function transition_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var $$event = ColiswebApi__V5_Utils.FullStatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "event"), null));
  if ($$event.TAG === "Ok") {
    var dateTime = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "dateTime"), null));
    if (dateTime.TAG === "Ok") {
      var mobileDateTime = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "mobileDateTime"), null));
      if (mobileDateTime.TAG === "Ok") {
        var problem = Spice.optionFromJson(ColiswebApi__Utils.TransitionProblem.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "problem"), null));
        if (problem.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    event: $$event._0,
                    dateTime: dateTime._0,
                    mobileDateTime: mobileDateTime._0,
                    problem: problem._0
                  }
                };
        }
        var e = problem._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".problem" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = mobileDateTime._0;
      return {
              TAG: "Error",
              _0: {
                path: ".mobileDateTime" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = dateTime._0;
    return {
            TAG: "Error",
            _0: {
              path: ".dateTime" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = $$event._0;
  return {
          TAG: "Error",
          _0: {
            path: ".event" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$14(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.DeliveryUuid.t_encode, v.uuid)
                  ],
                  [
                    "route",
                    false,
                    Spice.optionToJson(Identifiers.RouteId.t_encode, v.route)
                  ],
                  [
                    "routePlan",
                    false,
                    Spice.optionToJson(Identifiers.RoutePlanId.t_encode, v.routePlan)
                  ],
                  [
                    "ref1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.ref2)
                  ],
                  [
                    "ref3",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref3)
                  ],
                  [
                    "ref4",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref4)
                  ],
                  [
                    "salesConsultantName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.salesConsultantName)
                  ],
                  [
                    "heftingOptions",
                    false,
                    Curry._1(ColiswebApi__V5_Shared.Delivery.HeftingOptions.t_encode, v.heftingOptions)
                  ],
                  [
                    "statusOriginator",
                    false,
                    ColiswebApi__V5_Utils.StatusOriginator.t_encode(v.statusOriginator)
                  ],
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Utils.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "distanceKm",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.distanceKm)
                  ],
                  [
                    "skills",
                    false,
                    Spice.arrayToJson(skill_encode$1, v.skills)
                  ],
                  [
                    "timeSlot",
                    false,
                    timeSlot_encode$1(v.timeSlot)
                  ],
                  [
                    "pickup",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Shared.Delivery.Point.t_encode, v.pickup)
                  ],
                  [
                    "shipping",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Shared.Delivery.Point.t_encode, v.shipping)
                  ],
                  [
                    "customerSatisfaction",
                    false,
                    Spice.optionToJson(customerSatisfaction_encode, v.customerSatisfaction)
                  ],
                  [
                    "priceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithoutTaxEur)
                  ],
                  [
                    "priceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceWithTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithoutTaxEur)
                  ],
                  [
                    "customerPriceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEurComposition",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(priceComposition_encode$1, param);
                          }), v.customerPriceWithoutTaxEurComposition)
                  ],
                  [
                    "packaging",
                    false,
                    Spice.optionToJson(packaging_encode$1, v.packaging)
                  ],
                  [
                    "pickupSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.pickupSignature)
                  ],
                  [
                    "shippingSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.shippingSignature)
                  ],
                  [
                    "returnSignature",
                    false,
                    Spice.optionToJson(signature_encode, v.returnSignature)
                  ],
                  [
                    "canUpdateSlot",
                    false,
                    Spice.boolToJson(v.canUpdateSlot)
                  ],
                  [
                    "canUpdatePickupAddress",
                    false,
                    Spice.boolToJson(v.canUpdatePickupAddress)
                  ],
                  [
                    "canUpdateShippingAddress",
                    false,
                    Spice.boolToJson(v.canUpdateShippingAddress)
                  ],
                  [
                    "deliveryStateTransitions",
                    false,
                    Spice.arrayToJson(transition_encode, v.deliveryStateTransitions)
                  ],
                  [
                    "isArchived",
                    false,
                    Spice.boolToJson(v.isArchived)
                  ],
                  [
                    "owner",
                    false,
                    Curry._1(ColiswebApi__V5_Shared.Delivery.Owner.t_encode, v.owner)
                  ],
                  [
                    "customerPricesFactor",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPricesFactor)
                  ],
                  [
                    "canReschedule",
                    false,
                    Spice.boolToJson(v.canReschedule)
                  ],
                  [
                    "canCancelDelivery",
                    false,
                    Spice.boolToJson(v.canCancelDelivery)
                  ],
                  [
                    "rescheduleDeliveryId",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryId.t_encode, v.rescheduleDeliveryId)
                  ],
                  [
                    "externalDeliveryCode",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.externalDeliveryCode)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function response_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var uuid = Curry._1(Identifiers.DeliveryUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
    if (uuid.TAG === "Ok") {
      var route = Spice.optionFromJson(Identifiers.RouteId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "route"), null));
      if (route.TAG === "Ok") {
        var routePlan = Spice.optionFromJson(Identifiers.RoutePlanId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "routePlan"), null));
        if (routePlan.TAG === "Ok") {
          var ref1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
          if (ref1.TAG === "Ok") {
            var ref2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
            if (ref2.TAG === "Ok") {
              var ref3 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref3"), null));
              if (ref3.TAG === "Ok") {
                var ref4 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref4"), null));
                if (ref4.TAG === "Ok") {
                  var salesConsultantName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "salesConsultantName"), null));
                  if (salesConsultantName.TAG === "Ok") {
                    var heftingOptions = Curry._1(ColiswebApi__V5_Shared.Delivery.HeftingOptions.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "heftingOptions"), null));
                    if (heftingOptions.TAG === "Ok") {
                      var statusOriginator = ColiswebApi__V5_Utils.StatusOriginator.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusOriginator"), null));
                      if (statusOriginator.TAG === "Ok") {
                        var statusProvider = ColiswebApi__V5_Utils.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
                        if (statusProvider.TAG === "Ok") {
                          var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
                          if (createdAt.TAG === "Ok") {
                            var distanceKm = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "distanceKm"), null));
                            if (distanceKm.TAG === "Ok") {
                              var skills = Spice.arrayFromJson(skill_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "skills"), null));
                              if (skills.TAG === "Ok") {
                                var timeSlot = timeSlot_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
                                if (timeSlot.TAG === "Ok") {
                                  var pickup = Spice.optionFromJson(ColiswebApi__V5_Shared.Delivery.Point.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickup"), null));
                                  if (pickup.TAG === "Ok") {
                                    var shipping = Spice.optionFromJson(ColiswebApi__V5_Shared.Delivery.Point.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shipping"), null));
                                    if (shipping.TAG === "Ok") {
                                      var customerSatisfaction = Spice.optionFromJson(customerSatisfaction_decode, Belt_Option.getWithDefault(Js_dict.get(v, "customerSatisfaction"), null));
                                      if (customerSatisfaction.TAG === "Ok") {
                                        var priceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEur"), null));
                                        if (priceWithoutTaxEur.TAG === "Ok") {
                                          var priceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithTaxEur"), null));
                                          if (priceWithTaxEur.TAG === "Ok") {
                                            var customerPriceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEur"), null));
                                            if (customerPriceWithoutTaxEur.TAG === "Ok") {
                                              var customerPriceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithTaxEur"), null));
                                              if (customerPriceWithTaxEur.TAG === "Ok") {
                                                var customerPriceWithoutTaxEurComposition = Spice.optionFromJson((function (param) {
                                                        return Spice.arrayFromJson(priceComposition_decode$1, param);
                                                      }), Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEurComposition"), null));
                                                if (customerPriceWithoutTaxEurComposition.TAG === "Ok") {
                                                  var packaging = Spice.optionFromJson(packaging_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "packaging"), null));
                                                  if (packaging.TAG === "Ok") {
                                                    var pickupSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickupSignature"), null));
                                                    if (pickupSignature.TAG === "Ok") {
                                                      var shippingSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shippingSignature"), null));
                                                      if (shippingSignature.TAG === "Ok") {
                                                        var returnSignature = Spice.optionFromJson(signature_decode, Belt_Option.getWithDefault(Js_dict.get(v, "returnSignature"), null));
                                                        if (returnSignature.TAG === "Ok") {
                                                          var canUpdateSlot = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canUpdateSlot"), null));
                                                          if (canUpdateSlot.TAG === "Ok") {
                                                            var canUpdatePickupAddress = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canUpdatePickupAddress"), null));
                                                            if (canUpdatePickupAddress.TAG === "Ok") {
                                                              var canUpdateShippingAddress = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canUpdateShippingAddress"), null));
                                                              if (canUpdateShippingAddress.TAG === "Ok") {
                                                                var deliveryStateTransitions = Spice.arrayFromJson(transition_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryStateTransitions"), null));
                                                                if (deliveryStateTransitions.TAG === "Ok") {
                                                                  var isArchived = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isArchived"), null));
                                                                  if (isArchived.TAG === "Ok") {
                                                                    var owner = Curry._1(ColiswebApi__V5_Shared.Delivery.Owner.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "owner"), null));
                                                                    if (owner.TAG === "Ok") {
                                                                      var customerPricesFactor = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPricesFactor"), null));
                                                                      if (customerPricesFactor.TAG === "Ok") {
                                                                        var canReschedule = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "canReschedule"), Spice.boolFromJson), {
                                                                              TAG: "Ok",
                                                                              _0: false
                                                                            });
                                                                        if (canReschedule.TAG === "Ok") {
                                                                          var canCancelDelivery = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "canCancelDelivery"), null));
                                                                          if (canCancelDelivery.TAG === "Ok") {
                                                                            var rescheduleDeliveryId = Spice.optionFromJson(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "rescheduleDeliveryId"), null));
                                                                            if (rescheduleDeliveryId.TAG === "Ok") {
                                                                              var externalDeliveryCode = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "externalDeliveryCode"), null));
                                                                              if (externalDeliveryCode.TAG === "Ok") {
                                                                                var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
                                                                                if (clientId.TAG === "Ok") {
                                                                                  return {
                                                                                          TAG: "Ok",
                                                                                          _0: {
                                                                                            id: id._0,
                                                                                            uuid: uuid._0,
                                                                                            route: route._0,
                                                                                            routePlan: routePlan._0,
                                                                                            ref1: ref1._0,
                                                                                            ref2: ref2._0,
                                                                                            ref3: ref3._0,
                                                                                            ref4: ref4._0,
                                                                                            salesConsultantName: salesConsultantName._0,
                                                                                            heftingOptions: heftingOptions._0,
                                                                                            statusOriginator: statusOriginator._0,
                                                                                            statusProvider: statusProvider._0,
                                                                                            createdAt: createdAt._0,
                                                                                            distanceKm: distanceKm._0,
                                                                                            skills: skills._0,
                                                                                            timeSlot: timeSlot._0,
                                                                                            pickup: pickup._0,
                                                                                            shipping: shipping._0,
                                                                                            customerSatisfaction: customerSatisfaction._0,
                                                                                            priceWithoutTaxEur: priceWithoutTaxEur._0,
                                                                                            priceWithTaxEur: priceWithTaxEur._0,
                                                                                            customerPriceWithoutTaxEur: customerPriceWithoutTaxEur._0,
                                                                                            customerPriceWithTaxEur: customerPriceWithTaxEur._0,
                                                                                            customerPriceWithoutTaxEurComposition: customerPriceWithoutTaxEurComposition._0,
                                                                                            packaging: packaging._0,
                                                                                            pickupSignature: pickupSignature._0,
                                                                                            shippingSignature: shippingSignature._0,
                                                                                            returnSignature: returnSignature._0,
                                                                                            canUpdateSlot: canUpdateSlot._0,
                                                                                            canUpdatePickupAddress: canUpdatePickupAddress._0,
                                                                                            canUpdateShippingAddress: canUpdateShippingAddress._0,
                                                                                            deliveryStateTransitions: deliveryStateTransitions._0,
                                                                                            isArchived: isArchived._0,
                                                                                            owner: owner._0,
                                                                                            customerPricesFactor: customerPricesFactor._0,
                                                                                            canReschedule: canReschedule._0,
                                                                                            canCancelDelivery: canCancelDelivery._0,
                                                                                            rescheduleDeliveryId: rescheduleDeliveryId._0,
                                                                                            externalDeliveryCode: externalDeliveryCode._0,
                                                                                            clientId: clientId._0
                                                                                          }
                                                                                        };
                                                                                }
                                                                                var e = clientId._0;
                                                                                return {
                                                                                        TAG: "Error",
                                                                                        _0: {
                                                                                          path: ".clientId" + e.path,
                                                                                          message: e.message,
                                                                                          value: e.value
                                                                                        }
                                                                                      };
                                                                              }
                                                                              var e$1 = externalDeliveryCode._0;
                                                                              return {
                                                                                      TAG: "Error",
                                                                                      _0: {
                                                                                        path: ".externalDeliveryCode" + e$1.path,
                                                                                        message: e$1.message,
                                                                                        value: e$1.value
                                                                                      }
                                                                                    };
                                                                            }
                                                                            var e$2 = rescheduleDeliveryId._0;
                                                                            return {
                                                                                    TAG: "Error",
                                                                                    _0: {
                                                                                      path: ".rescheduleDeliveryId" + e$2.path,
                                                                                      message: e$2.message,
                                                                                      value: e$2.value
                                                                                    }
                                                                                  };
                                                                          }
                                                                          var e$3 = canCancelDelivery._0;
                                                                          return {
                                                                                  TAG: "Error",
                                                                                  _0: {
                                                                                    path: ".canCancelDelivery" + e$3.path,
                                                                                    message: e$3.message,
                                                                                    value: e$3.value
                                                                                  }
                                                                                };
                                                                        }
                                                                        var e$4 = canReschedule._0;
                                                                        return {
                                                                                TAG: "Error",
                                                                                _0: {
                                                                                  path: ".canReschedule" + e$4.path,
                                                                                  message: e$4.message,
                                                                                  value: e$4.value
                                                                                }
                                                                              };
                                                                      }
                                                                      var e$5 = customerPricesFactor._0;
                                                                      return {
                                                                              TAG: "Error",
                                                                              _0: {
                                                                                path: ".customerPricesFactor" + e$5.path,
                                                                                message: e$5.message,
                                                                                value: e$5.value
                                                                              }
                                                                            };
                                                                    }
                                                                    var e$6 = owner._0;
                                                                    return {
                                                                            TAG: "Error",
                                                                            _0: {
                                                                              path: ".owner" + e$6.path,
                                                                              message: e$6.message,
                                                                              value: e$6.value
                                                                            }
                                                                          };
                                                                  }
                                                                  var e$7 = isArchived._0;
                                                                  return {
                                                                          TAG: "Error",
                                                                          _0: {
                                                                            path: ".isArchived" + e$7.path,
                                                                            message: e$7.message,
                                                                            value: e$7.value
                                                                          }
                                                                        };
                                                                }
                                                                var e$8 = deliveryStateTransitions._0;
                                                                return {
                                                                        TAG: "Error",
                                                                        _0: {
                                                                          path: ".deliveryStateTransitions" + e$8.path,
                                                                          message: e$8.message,
                                                                          value: e$8.value
                                                                        }
                                                                      };
                                                              }
                                                              var e$9 = canUpdateShippingAddress._0;
                                                              return {
                                                                      TAG: "Error",
                                                                      _0: {
                                                                        path: ".canUpdateShippingAddress" + e$9.path,
                                                                        message: e$9.message,
                                                                        value: e$9.value
                                                                      }
                                                                    };
                                                            }
                                                            var e$10 = canUpdatePickupAddress._0;
                                                            return {
                                                                    TAG: "Error",
                                                                    _0: {
                                                                      path: ".canUpdatePickupAddress" + e$10.path,
                                                                      message: e$10.message,
                                                                      value: e$10.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$11 = canUpdateSlot._0;
                                                          return {
                                                                  TAG: "Error",
                                                                  _0: {
                                                                    path: ".canUpdateSlot" + e$11.path,
                                                                    message: e$11.message,
                                                                    value: e$11.value
                                                                  }
                                                                };
                                                        }
                                                        var e$12 = returnSignature._0;
                                                        return {
                                                                TAG: "Error",
                                                                _0: {
                                                                  path: ".returnSignature" + e$12.path,
                                                                  message: e$12.message,
                                                                  value: e$12.value
                                                                }
                                                              };
                                                      }
                                                      var e$13 = shippingSignature._0;
                                                      return {
                                                              TAG: "Error",
                                                              _0: {
                                                                path: ".shippingSignature" + e$13.path,
                                                                message: e$13.message,
                                                                value: e$13.value
                                                              }
                                                            };
                                                    }
                                                    var e$14 = pickupSignature._0;
                                                    return {
                                                            TAG: "Error",
                                                            _0: {
                                                              path: ".pickupSignature" + e$14.path,
                                                              message: e$14.message,
                                                              value: e$14.value
                                                            }
                                                          };
                                                  }
                                                  var e$15 = packaging._0;
                                                  return {
                                                          TAG: "Error",
                                                          _0: {
                                                            path: ".packaging" + e$15.path,
                                                            message: e$15.message,
                                                            value: e$15.value
                                                          }
                                                        };
                                                }
                                                var e$16 = customerPriceWithoutTaxEurComposition._0;
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          path: ".customerPriceWithoutTaxEurComposition" + e$16.path,
                                                          message: e$16.message,
                                                          value: e$16.value
                                                        }
                                                      };
                                              }
                                              var e$17 = customerPriceWithTaxEur._0;
                                              return {
                                                      TAG: "Error",
                                                      _0: {
                                                        path: ".customerPriceWithTaxEur" + e$17.path,
                                                        message: e$17.message,
                                                        value: e$17.value
                                                      }
                                                    };
                                            }
                                            var e$18 = customerPriceWithoutTaxEur._0;
                                            return {
                                                    TAG: "Error",
                                                    _0: {
                                                      path: ".customerPriceWithoutTaxEur" + e$18.path,
                                                      message: e$18.message,
                                                      value: e$18.value
                                                    }
                                                  };
                                          }
                                          var e$19 = priceWithTaxEur._0;
                                          return {
                                                  TAG: "Error",
                                                  _0: {
                                                    path: ".priceWithTaxEur" + e$19.path,
                                                    message: e$19.message,
                                                    value: e$19.value
                                                  }
                                                };
                                        }
                                        var e$20 = priceWithoutTaxEur._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".priceWithoutTaxEur" + e$20.path,
                                                  message: e$20.message,
                                                  value: e$20.value
                                                }
                                              };
                                      }
                                      var e$21 = customerSatisfaction._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".customerSatisfaction" + e$21.path,
                                                message: e$21.message,
                                                value: e$21.value
                                              }
                                            };
                                    }
                                    var e$22 = shipping._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".shipping" + e$22.path,
                                              message: e$22.message,
                                              value: e$22.value
                                            }
                                          };
                                  }
                                  var e$23 = pickup._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".pickup" + e$23.path,
                                            message: e$23.message,
                                            value: e$23.value
                                          }
                                        };
                                }
                                var e$24 = timeSlot._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".timeSlot" + e$24.path,
                                          message: e$24.message,
                                          value: e$24.value
                                        }
                                      };
                              }
                              var e$25 = skills._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".skills" + e$25.path,
                                        message: e$25.message,
                                        value: e$25.value
                                      }
                                    };
                            }
                            var e$26 = distanceKm._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".distanceKm" + e$26.path,
                                      message: e$26.message,
                                      value: e$26.value
                                    }
                                  };
                          }
                          var e$27 = createdAt._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".createdAt" + e$27.path,
                                    message: e$27.message,
                                    value: e$27.value
                                  }
                                };
                        }
                        var e$28 = statusProvider._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".statusProvider" + e$28.path,
                                  message: e$28.message,
                                  value: e$28.value
                                }
                              };
                      }
                      var e$29 = statusOriginator._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".statusOriginator" + e$29.path,
                                message: e$29.message,
                                value: e$29.value
                              }
                            };
                    }
                    var e$30 = heftingOptions._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".heftingOptions" + e$30.path,
                              message: e$30.message,
                              value: e$30.value
                            }
                          };
                  }
                  var e$31 = salesConsultantName._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".salesConsultantName" + e$31.path,
                            message: e$31.message,
                            value: e$31.value
                          }
                        };
                }
                var e$32 = ref4._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".ref4" + e$32.path,
                          message: e$32.message,
                          value: e$32.value
                        }
                      };
              }
              var e$33 = ref3._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".ref3" + e$33.path,
                        message: e$33.message,
                        value: e$33.value
                      }
                    };
            }
            var e$34 = ref2._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".ref2" + e$34.path,
                      message: e$34.message,
                      value: e$34.value
                    }
                  };
          }
          var e$35 = ref1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".ref1" + e$35.path,
                    message: e$35.message,
                    value: e$35.value
                  }
                };
        }
        var e$36 = routePlan._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".routePlan" + e$36.path,
                  message: e$36.message,
                  value: e$36.value
                }
              };
      }
      var e$37 = route._0;
      return {
              TAG: "Error",
              _0: {
                path: ".route" + e$37.path,
                message: e$37.message,
                value: e$37.value
              }
            };
    }
    var e$38 = uuid._0;
    return {
            TAG: "Error",
            _0: {
              path: ".uuid" + e$38.path,
              message: e$38.message,
              value: e$38.value
            }
          };
  }
  var e$39 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$39.path,
            message: e$39.message,
            value: e$39.value
          }
        };
}

function exec$14(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$14, undefined, baseUrl$1(param[0], param[1]));
}

var Config$14 = {
  priceComposition_encode: priceComposition_encode$1,
  priceComposition_decode: priceComposition_decode$1,
  customerSatisfaction_encode: customerSatisfaction_encode,
  customerSatisfaction_decode: customerSatisfaction_decode,
  nullableDimensions_encode: nullableDimensions_encode,
  nullableDimensions_decode: nullableDimensions_decode,
  packaging_encode: packaging_encode$1,
  packaging_decode: packaging_decode$1,
  packetBarcode_encode: packetBarcode_encode,
  packetBarcode_decode: packetBarcode_decode,
  dimensions_encode: dimensions_encode,
  dimensions_decode: dimensions_decode,
  signature_encode: signature_encode,
  signature_decode: signature_decode,
  signatureTransition_encode: signatureTransition_encode,
  signatureTransition_decode: signatureTransition_decode,
  timeSlot_encode: timeSlot_encode$1,
  timeSlot_decode: timeSlot_decode$1,
  skills_encode: skills_encode$1,
  skills_decode: skills_decode$1,
  skill_encode: skill_encode$1,
  skill_decode: skill_decode$1,
  transition_encode: transition_encode,
  transition_decode: transition_decode,
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  exec: exec$14
};

var $$Request$14 = Toolkit__Request.Make({
      exec: exec$14
    });

var GetDelivery = {
  Config: Config$14,
  $$Request: $$Request$14
};

function t_encode$4(v) {
  switch (v) {
    case "store_not_found" :
        return "store_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "empty_response_from_packing" :
        return "empty_response_from_packing";
    case "package_too_big" :
        return "package_too_big";
    case "packet_too_big" :
        return "packet_too_big";
    case "distance_too_far" :
        return "distance_too_far";
    case "total_weight_too_heavy" :
        return "total_weight_too_heavy";
    case "too_heavy_single_packet" :
        return "too_heavy_single_packet";
    case "conditions_not_matched_for_delivery" :
        return "conditions_not_matched_for_delivery";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "not_configured_to_order_deliveries" :
        return "not_configured_to_order_deliveries";
    case "generated_calendar_is_empty" :
        return "generated_calendar_is_empty";
    case "too_heavy_packet" :
        return "too_heavy_packet";
    case "area_not_eligible" :
        return "area_not_eligible";
    case "unhandled" :
        return "unhandled";
    
  }
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("empty_response_from_packing" === v) {
          return {
                  TAG: "Ok",
                  _0: "empty_response_from_packing"
                };
        } else if ("package_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_too_big"
                };
        } else if ("packet_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "packet_too_big"
                };
        } else if ("distance_too_far" === v) {
          return {
                  TAG: "Ok",
                  _0: "distance_too_far"
                };
        } else if ("total_weight_too_heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "total_weight_too_heavy"
                };
        } else if ("too_heavy_single_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_single_packet"
                };
        } else if ("conditions_not_matched_for_delivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "conditions_not_matched_for_delivery"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("not_configured_to_order_deliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_configured_to_order_deliveries"
                };
        } else if ("generated_calendar_is_empty" === v) {
          return {
                  TAG: "Ok",
                  _0: "generated_calendar_is_empty"
                };
        } else if ("too_heavy_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_packet"
                };
        } else if ("area_not_eligible" === v) {
          return {
                  TAG: "Ok",
                  _0: "area_not_eligible"
                };
        } else if ("unhandled" === v) {
          return {
                  TAG: "Ok",
                  _0: "unhandled"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$2 = {
  t_encode: t_encode$4,
  t_decode: t_decode$4
};

function body_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startDate)
                  ],
                  [
                    "endDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.endDate)
                  ]
                ]));
}

function body_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startDate"), null));
  if (startDate.TAG === "Ok") {
    var endDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endDate"), null));
    if (endDate.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                startDate: startDate._0,
                endDate: endDate._0
              }
            };
    }
    var e = endDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endDate" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = startDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startDate" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$15(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "updateDeliverySlotRequestId",
                    false,
                    Spice.stringToJson(v.updateDeliverySlotRequestId)
                  ],
                  [
                    "calendar",
                    false,
                    Spice.arrayToJson(calendar_encode, v.calendar)
                  ]
                ]));
}

function response_decode$15(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var updateDeliverySlotRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "updateDeliverySlotRequestId"), null));
  if (updateDeliverySlotRequestId.TAG === "Ok") {
    var calendar = Spice.arrayFromJson(calendar_decode, Belt_Option.getWithDefault(Js_dict.get(v, "calendar"), null));
    if (calendar.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                updateDeliverySlotRequestId: updateDeliverySlotRequestId._0,
                calendar: calendar._0
              }
            };
    }
    var e = calendar._0;
    return {
            TAG: "Error",
            _0: {
              path: ".calendar" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = updateDeliverySlotRequestId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".updateDeliverySlotRequestId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$15(param) {
  return Axios.WithResult.post(undefined, body_encode$5(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$15, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$4(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "custom",
                          VAL: "unhandled"
                        };
                }
              }), baseUrl$1(param[0], param[1]) + "/getEligibleSlots");
}

var Config$15 = {
  body_encode: body_encode$5,
  body_decode: body_decode$5,
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  exec: exec$15
};

var $$Request$15 = Toolkit__Request.Make({
      exec: exec$15
    });

var GetEligibleTimeSlots$1 = {
  $$Error: $$Error$2,
  Config: Config$15,
  $$Request: $$Request$15
};

function t_encode$5(v) {
  switch (v) {
    case "the_request_is_expired" :
        return "the_request_is_expired";
    case "cannot_update_slot" :
        return "cannot_update_slot";
    case "update_slot_not_found" :
        return "update_slot_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "store_not_found" :
        return "store_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "empty_response_from_packing" :
        return "empty_response_from_packing";
    case "package_too_big" :
        return "package_too_big";
    case "packet_too_big" :
        return "packet_too_big";
    case "distance_too_far" :
        return "distance_too_far";
    case "total_weight_too_heavy" :
        return "total_weight_too_heavy";
    case "too_heavy_single_packet" :
        return "too_heavy_single_packet";
    case "conditions_not_matched_for_delivery" :
        return "conditions_not_matched_for_delivery";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "not_configured_to_order_deliveries" :
        return "not_configured_to_order_deliveries";
    case "slot_too_wide" :
        return "slot_too_wide";
    case "slot_too_narrow" :
        return "slot_too_narrow";
    case "slot_out_of_openings" :
        return "slot_out_of_openings";
    case "area_not_eligible" :
        return "area_not_eligible";
    
  }
}

function t_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("the_request_is_expired" === v) {
          return {
                  TAG: "Ok",
                  _0: "the_request_is_expired"
                };
        } else if ("cannot_update_slot" === v) {
          return {
                  TAG: "Ok",
                  _0: "cannot_update_slot"
                };
        } else if ("update_slot_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "update_slot_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("empty_response_from_packing" === v) {
          return {
                  TAG: "Ok",
                  _0: "empty_response_from_packing"
                };
        } else if ("package_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_too_big"
                };
        } else if ("packet_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "packet_too_big"
                };
        } else if ("distance_too_far" === v) {
          return {
                  TAG: "Ok",
                  _0: "distance_too_far"
                };
        } else if ("total_weight_too_heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "total_weight_too_heavy"
                };
        } else if ("too_heavy_single_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_single_packet"
                };
        } else if ("conditions_not_matched_for_delivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "conditions_not_matched_for_delivery"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("not_configured_to_order_deliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_configured_to_order_deliveries"
                };
        } else if ("slot_too_wide" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_too_wide"
                };
        } else if ("slot_too_narrow" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_too_narrow"
                };
        } else if ("slot_out_of_openings" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_out_of_openings"
                };
        } else if ("area_not_eligible" === v) {
          return {
                  TAG: "Ok",
                  _0: "area_not_eligible"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$3 = {
  t_encode: t_encode$5,
  t_decode: t_decode$5
};

function body_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "updateDeliverySlotRequestId",
                    false,
                    Spice.stringToJson(v.updateDeliverySlotRequestId)
                  ],
                  [
                    "slotOptionId",
                    false,
                    Spice.stringToJson(v.slotOptionId)
                  ]
                ]));
}

function body_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var updateDeliverySlotRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "updateDeliverySlotRequestId"), null));
  if (updateDeliverySlotRequestId.TAG === "Ok") {
    var slotOptionId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "slotOptionId"), null));
    if (slotOptionId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                updateDeliverySlotRequestId: updateDeliverySlotRequestId._0,
                slotOptionId: slotOptionId._0
              }
            };
    }
    var e = slotOptionId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".slotOptionId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = updateDeliverySlotRequestId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".updateDeliverySlotRequestId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$16 = Spice.unitToJson;

var response_decode$16 = Spice.unitFromJson;

function exec$16(param) {
  return Axios.WithResult.put(undefined, body_encode$6(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$16, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$5(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), baseUrl$1(param[0], param[1]) + "/updateSlot");
}

var Config$16 = {
  body_encode: body_encode$6,
  body_decode: body_decode$6,
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  exec: exec$16
};

var $$Request$16 = Toolkit__Request.Make({
      exec: exec$16
    });

var UpdateTimeSlot = {
  $$Error: $$Error$3,
  Config: Config$16,
  $$Request: $$Request$16
};

function t_encode$6(v) {
  switch (v) {
    case "line1" :
        return "line1";
    case "line2" :
        return "line2";
    case "city" :
        return "city";
    case "country" :
        return "country";
    case "society" :
        return "society";
    case "floor" :
        return "floor";
    case "state_or_province" :
        return "state_or_province";
    case "postal_code" :
        return "postal_code";
    case "starts_at" :
        return "starts_at";
    case "ends_at" :
        return "ends_at";
    case "lift_information" :
        return "lift_information";
    case "additional_informations" :
        return "additional_informations";
    case "administrative_area_code2" :
        return "administrative_area_code2";
    case "administrative_area_code3" :
        return "administrative_area_code3";
    
  }
}

function t_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("line1" === v) {
          return {
                  TAG: "Ok",
                  _0: "line1"
                };
        } else if ("line2" === v) {
          return {
                  TAG: "Ok",
                  _0: "line2"
                };
        } else if ("city" === v) {
          return {
                  TAG: "Ok",
                  _0: "city"
                };
        } else if ("country" === v) {
          return {
                  TAG: "Ok",
                  _0: "country"
                };
        } else if ("society" === v) {
          return {
                  TAG: "Ok",
                  _0: "society"
                };
        } else if ("floor" === v) {
          return {
                  TAG: "Ok",
                  _0: "floor"
                };
        } else if ("state_or_province" === v) {
          return {
                  TAG: "Ok",
                  _0: "state_or_province"
                };
        } else if ("postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "postal_code"
                };
        } else if ("starts_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "starts_at"
                };
        } else if ("ends_at" === v) {
          return {
                  TAG: "Ok",
                  _0: "ends_at"
                };
        } else if ("lift_information" === v) {
          return {
                  TAG: "Ok",
                  _0: "lift_information"
                };
        } else if ("additional_informations" === v) {
          return {
                  TAG: "Ok",
                  _0: "additional_informations"
                };
        } else if ("administrative_area_code2" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code2"
                };
        } else if ("administrative_area_code3" === v) {
          return {
                  TAG: "Ok",
                  _0: "administrative_area_code3"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var FieldName$1 = {
  t_encode: t_encode$6,
  t_decode: t_decode$6
};

function t_encode$7(v) {
  switch (v) {
    case "destination_address" :
        return "destination_address";
    case "origin_address" :
        return "origin_address";
    case "slot" :
        return "slot";
    
  }
}

function t_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("destination_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "destination_address"
                };
        } else if ("origin_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "origin_address"
                };
        } else if ("slot" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var ChangeType = {
  t_encode: t_encode$7,
  t_decode: t_decode$7
};

function t_encode$8(v) {
  switch (v) {
    case "admin" :
        return "admin";
    case "sav_admin" :
        return "sav_admin";
    case "client_admin" :
        return "client_admin";
    case "store_admin" :
        return "store_admin";
    case "transporter_admin" :
        return "transporter_admin";
    case "e_retailer" :
        return "e_retailer";
    case "carrier" :
        return "carrier";
    case "tech_admin" :
        return "tech_admin";
    case "super_admin" :
        return "super_admin";
    case "transporter_pole_admin" :
        return "transporter_pole_admin";
    case "customer" :
        return "customer";
    case "supplier" :
        return "supplier";
    
  }
}

function t_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "admin"
                };
        } else if ("sav_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "sav_admin"
                };
        } else if ("client_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_admin"
                };
        } else if ("store_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_admin"
                };
        } else if ("transporter_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter_admin"
                };
        } else if ("e_retailer" === v) {
          return {
                  TAG: "Ok",
                  _0: "e_retailer"
                };
        } else if ("carrier" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrier"
                };
        } else if ("tech_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "tech_admin"
                };
        } else if ("super_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "super_admin"
                };
        } else if ("transporter_pole_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter_pole_admin"
                };
        } else if ("customer" === v) {
          return {
                  TAG: "Ok",
                  _0: "customer"
                };
        } else if ("supplier" === v) {
          return {
                  TAG: "Ok",
                  _0: "supplier"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var UserRole = {
  t_encode: t_encode$8,
  t_decode: t_decode$8
};

function modification_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var fieldName = t_decode$6(Belt_Option.getWithDefault(Js_dict.get(v, "fieldName"), null));
  if (fieldName.TAG === "Ok") {
    var valueBefore = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "valueBefore"), null));
    if (valueBefore.TAG === "Ok") {
      var valueAfter = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "valueAfter"), null));
      if (valueAfter.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  fieldName: fieldName._0,
                  valueBefore: valueBefore._0,
                  valueAfter: valueAfter._0
                }
              };
      }
      var e = valueAfter._0;
      return {
              TAG: "Error",
              _0: {
                path: ".valueAfter" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = valueBefore._0;
    return {
            TAG: "Error",
            _0: {
              path: ".valueBefore" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = fieldName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".fieldName" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function modification_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "fieldName",
                    false,
                    t_encode$6(v.fieldName)
                  ],
                  [
                    "valueBefore",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.valueBefore)
                  ],
                  [
                    "valueAfter",
                    false,
                    Spice.stringToJson(v.valueAfter)
                  ]
                ]));
}

function modificationHistory_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var modifiedBy = Spice.optionFromJson(t_decode$8, Belt_Option.getWithDefault(Js_dict.get(v, "modifiedBy"), null));
    if (modifiedBy.TAG === "Ok") {
      var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
      if (email.TAG === "Ok") {
        var modificationType = t_decode$7(Belt_Option.getWithDefault(Js_dict.get(v, "modificationType"), null));
        if (modificationType.TAG === "Ok") {
          var modifications = Spice.arrayFromJson(modification_decode, Belt_Option.getWithDefault(Js_dict.get(v, "modifications"), null));
          if (modifications.TAG === "Ok") {
            var modificationReason = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "modificationReason"), null));
            if (modificationReason.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        createdAt: createdAt._0,
                        modifiedBy: modifiedBy._0,
                        email: email._0,
                        modificationType: modificationType._0,
                        modifications: modifications._0,
                        modificationReason: modificationReason._0
                      }
                    };
            }
            var e = modificationReason._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".modificationReason" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = modifications._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".modifications" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = modificationType._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".modificationType" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = email._0;
      return {
              TAG: "Error",
              _0: {
                path: ".email" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = modifiedBy._0;
    return {
            TAG: "Error",
            _0: {
              path: ".modifiedBy" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function modificationHistory_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "modifiedBy",
                    false,
                    Spice.optionToJson(t_encode$8, v.modifiedBy)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "modificationType",
                    false,
                    t_encode$7(v.modificationType)
                  ],
                  [
                    "modifications",
                    false,
                    Spice.arrayToJson(modification_encode, v.modifications)
                  ],
                  [
                    "modificationReason",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.modificationReason)
                  ]
                ]));
}

function response_encode$17(v) {
  return Spice.arrayToJson(modificationHistory_encode, v);
}

function response_decode$17(v) {
  return Spice.arrayFromJson(modificationHistory_decode, v);
}

function exec$17(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$17, undefined, baseUrl$1(param[0], param[1]) + "/modificationsHistory");
}

var Config$17 = {
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  modificationHistory_encode: modificationHistory_encode,
  modificationHistory_decode: modificationHistory_decode,
  modification_encode: modification_encode,
  modification_decode: modification_decode,
  exec: exec$17
};

var $$Request$17 = Toolkit__Request.Make({
      exec: exec$17
    });

var GetChangesHistory = {
  FieldName: FieldName$1,
  ChangeType: ChangeType,
  UserRole: UserRole,
  Config: Config$17,
  $$Request: $$Request$17
};

function packet_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.PacketId.t_encode, v.id)
                  ],
                  [
                    "description",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.description)
                  ],
                  [
                    "weightKg",
                    false,
                    Spice.stringToJson(v.weightKg)
                  ],
                  [
                    "heightCm",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.heightCm)
                  ],
                  [
                    "widthCm",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.widthCm)
                  ],
                  [
                    "lengthCm",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lengthCm)
                  ],
                  [
                    "volumeM3",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.volumeM3)
                  ]
                ]));
}

function packet_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.PacketId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var description = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "description"), null));
    if (description.TAG === "Ok") {
      var weightKg = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "weightKg"), null));
      if (weightKg.TAG === "Ok") {
        var heightCm = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "heightCm"), null));
        if (heightCm.TAG === "Ok") {
          var widthCm = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "widthCm"), null));
          if (widthCm.TAG === "Ok") {
            var lengthCm = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lengthCm"), null));
            if (lengthCm.TAG === "Ok") {
              var volumeM3 = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "volumeM3"), null));
              if (volumeM3.TAG === "Ok") {
                return {
                        TAG: "Ok",
                        _0: {
                          id: id._0,
                          description: description._0,
                          weightKg: weightKg._0,
                          heightCm: heightCm._0,
                          widthCm: widthCm._0,
                          lengthCm: lengthCm._0,
                          volumeM3: volumeM3._0
                        }
                      };
              }
              var e = volumeM3._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".volumeM3" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = lengthCm._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".lengthCm" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = widthCm._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".widthCm" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = heightCm._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".heightCm" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = weightKg._0;
      return {
              TAG: "Error",
              _0: {
                path: ".weightKg" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = description._0;
    return {
            TAG: "Error",
            _0: {
              path: ".description" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

function response_encode$18(v) {
  return Spice.arrayToJson(packet_encode, v);
}

function response_decode$18(v) {
  return Spice.arrayFromJson(packet_decode, v);
}

function exec$18(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$18, undefined, baseUrl$1(param[0], param[1]) + "/packets");
}

var Config$18 = {
  response_encode: response_encode$18,
  response_decode: response_decode$18,
  packet_encode: packet_encode,
  packet_decode: packet_decode,
  exec: exec$18
};

var $$Request$18 = Toolkit__Request.Make({
      exec: exec$18
    });

var GetPackets = {
  Config: Config$18,
  $$Request: $$Request$18
};

function picture_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.PictureId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
    if (url.TAG === "Ok") {
      var status = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "status"), null));
      if (status.TAG === "Ok") {
        var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
        if (createdAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    url: url._0,
                    status: status._0,
                    createdAt: createdAt._0
                  }
                };
        }
        var e = createdAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".createdAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = status._0;
      return {
              TAG: "Error",
              _0: {
                path: ".status" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = url._0;
    return {
            TAG: "Error",
            _0: {
              path: ".url" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function picture_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.PictureId.t_encode, v.id)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "status",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.status)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ]
                ]));
}

function response_encode$19(v) {
  return Spice.arrayToJson(picture_encode, v);
}

function response_decode$19(v) {
  return Spice.arrayFromJson(picture_decode, v);
}

function exec$19(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$19, undefined, baseUrl$1(param[0], param[1]) + "/pictures");
}

var Config$19 = {
  response_encode: response_encode$19,
  response_decode: response_decode$19,
  picture_encode: picture_encode,
  picture_decode: picture_decode,
  exec: exec$19
};

var $$Request$19 = Toolkit__Request.Make({
      exec: exec$19
    });

var GetPictures = {
  Config: Config$19,
  $$Request: $$Request$19
};

function comment_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.CommentId.t_encode, v.id)
                  ],
                  [
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function comment_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.CommentId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
    if (message.TAG === "Ok") {
      var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
      if (createdAt.TAG === "Ok") {
        var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
        if (updatedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    message: message._0,
                    createdAt: createdAt._0,
                    updatedAt: updatedAt._0
                  }
                };
        }
        var e = updatedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".updatedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = createdAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".createdAt" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = message._0;
    return {
            TAG: "Error",
            _0: {
              path: ".message" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$20(v) {
  return Spice.arrayToJson(comment_encode, v);
}

function response_decode$20(v) {
  return Spice.arrayFromJson(comment_decode, v);
}

function exec$20(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$20, undefined, baseUrl$1(param[0], param[1]) + "/comments");
}

var Config$20 = {
  response_encode: response_encode$20,
  response_decode: response_decode$20,
  comment_encode: comment_encode,
  comment_decode: comment_decode,
  exec: exec$20
};

var $$Request$20 = Toolkit__Request.Make({
      exec: exec$20
    });

var GetComments = {
  Config: Config$20,
  $$Request: $$Request$20
};

function t_encode$9(v) {
  switch (v) {
    case "no_floor_or_lift_has_been_specified" :
        return "no_floor_or_lift_has_been_specified";
    case "not_authorized_to_update_address" :
        return "not_authorized_to_update_address";
    case "client_not_found" :
        return "client_not_found";
    case "store_not_found" :
        return "store_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "empty_response_from_packing" :
        return "empty_response_from_packing";
    case "package_too_big" :
        return "package_too_big";
    case "packet_too_big" :
        return "packet_too_big";
    case "distance_too_far" :
        return "distance_too_far";
    case "total_weight_too_heavy" :
        return "total_weight_too_heavy";
    case "too_heavy_single_packet" :
        return "too_heavy_single_packet";
    case "conditions_not_matched_for_delivery" :
        return "conditions_not_matched_for_delivery";
    case "invalid_postal_code" :
        return "invalid_postal_code";
    case "not_configured_to_order_deliveries" :
        return "not_configured_to_order_deliveries";
    case "slot_too_wide" :
        return "slot_too_wide";
    case "slot_too_narrow" :
        return "slot_too_narrow";
    case "slot_out_of_openings" :
        return "slot_out_of_openings";
    case "out_of_area" :
        return "out_of_area";
    case "area_not_eligible" :
        return "area_not_eligible";
    case "unhandledError" :
        return "unhandledError";
    
  }
}

function t_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("no_floor_or_lift_has_been_specified" === v) {
          return {
                  TAG: "Ok",
                  _0: "no_floor_or_lift_has_been_specified"
                };
        } else if ("not_authorized_to_update_address" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_authorized_to_update_address"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("empty_response_from_packing" === v) {
          return {
                  TAG: "Ok",
                  _0: "empty_response_from_packing"
                };
        } else if ("package_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_too_big"
                };
        } else if ("packet_too_big" === v) {
          return {
                  TAG: "Ok",
                  _0: "packet_too_big"
                };
        } else if ("distance_too_far" === v) {
          return {
                  TAG: "Ok",
                  _0: "distance_too_far"
                };
        } else if ("total_weight_too_heavy" === v) {
          return {
                  TAG: "Ok",
                  _0: "total_weight_too_heavy"
                };
        } else if ("too_heavy_single_packet" === v) {
          return {
                  TAG: "Ok",
                  _0: "too_heavy_single_packet"
                };
        } else if ("conditions_not_matched_for_delivery" === v) {
          return {
                  TAG: "Ok",
                  _0: "conditions_not_matched_for_delivery"
                };
        } else if ("invalid_postal_code" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalid_postal_code"
                };
        } else if ("not_configured_to_order_deliveries" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_configured_to_order_deliveries"
                };
        } else if ("slot_too_wide" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_too_wide"
                };
        } else if ("slot_too_narrow" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_too_narrow"
                };
        } else if ("slot_out_of_openings" === v) {
          return {
                  TAG: "Ok",
                  _0: "slot_out_of_openings"
                };
        } else if ("out_of_area" === v) {
          return {
                  TAG: "Ok",
                  _0: "out_of_area"
                };
        } else if ("area_not_eligible" === v) {
          return {
                  TAG: "Ok",
                  _0: "area_not_eligible"
                };
        } else if ("unhandledError" === v) {
          return {
                  TAG: "Ok",
                  _0: "unhandledError"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$4 = {
  t_encode: t_encode$9,
  t_decode: t_decode$9
};

function t_encode$10(v) {
  if (v === "pickup") {
    return "pickup";
  } else {
    return "shipping";
  }
}

function t_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("pickup" === v) {
          return {
                  TAG: "Ok",
                  _0: "pickup"
                };
        } else if ("shipping" === v) {
          return {
                  TAG: "Ok",
                  _0: "shipping"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var AddressType = {
  t_encode: t_encode$10,
  t_decode: t_decode$10
};

function coordinates_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var lat = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lat"), null));
  if (lat.TAG === "Ok") {
    var lng = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lng"), null));
    if (lng.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                lat: lat._0,
                lng: lng._0
              }
            };
    }
    var e = lng._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lng" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = lat._0;
  return {
          TAG: "Error",
          _0: {
            path: ".lat" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function coordinates_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "lat",
                    false,
                    Spice.floatToJson(v.lat)
                  ],
                  [
                    "lng",
                    false,
                    Spice.floatToJson(v.lng)
                  ]
                ]));
}

function address_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "comment",
                    false,
                    Spice.stringToJson(v.comment)
                  ],
                  [
                    "coords",
                    false,
                    coordinates_encode(v.coords)
                  ],
                  [
                    "hasLift",
                    false,
                    ColiswebApi__V5_Utils.Lift.t_encode(v.hasLift)
                  ],
                  [
                    "floor",
                    false,
                    Spice.stringToJson(v.floor)
                  ]
                ]));
}

function contact_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "firstName",
                    false,
                    Spice.stringToJson(v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.stringToJson(v.lastName)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.stringToJson(v.phone1)
                  ]
                ]));
}

function contact_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var firstName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
  if (firstName.TAG === "Ok") {
    var lastName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
    if (lastName.TAG === "Ok") {
      var phone1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
      if (phone1.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  firstName: firstName._0,
                  lastName: lastName._0,
                  phone1: phone1._0
                }
              };
      }
      var e = phone1._0;
      return {
              TAG: "Error",
              _0: {
                path: ".phone1" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = lastName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lastName" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = firstName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".firstName" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function address_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var comment = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
          if (comment.TAG === "Ok") {
            var coords = coordinates_decode(Belt_Option.getWithDefault(Js_dict.get(v, "coords"), null));
            if (coords.TAG === "Ok") {
              var hasLift = ColiswebApi__V5_Utils.Lift.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "hasLift"), null));
              if (hasLift.TAG === "Ok") {
                var floor = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                if (floor.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            line1: line1._0,
                            city: city._0,
                            postalCode: postalCode._0,
                            country: country._0,
                            comment: comment._0,
                            coords: coords._0,
                            hasLift: hasLift._0,
                            floor: floor._0
                          }
                        };
                }
                var e = floor._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".floor" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = hasLift._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".hasLift" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = coords._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".coords" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = comment._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".comment" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function body_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    t_encode$10(v.type_)
                  ],
                  [
                    "contact",
                    false,
                    contact_encode(v.contact)
                  ],
                  [
                    "address",
                    false,
                    address_encode$5(v.address)
                  ]
                ]));
}

function body_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$10(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var contact = contact_decode(Belt_Option.getWithDefault(Js_dict.get(v, "contact"), null));
    if (contact.TAG === "Ok") {
      var address = address_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
      if (address.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  type_: type_._0,
                  contact: contact._0,
                  address: address._0
                }
              };
      }
      var e = address._0;
      return {
              TAG: "Error",
              _0: {
                path: ".address" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = contact._0;
    return {
            TAG: "Error",
            _0: {
              path: ".contact" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$2.path),
            message: e$2.message,
            value: e$2.value
          }
        };
}

var response_encode$21 = Spice.unitToJson;

var response_decode$21 = Spice.unitFromJson;

function exec$21(param) {
  return Axios.WithResult.put(undefined, body_encode$7(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$21, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$9(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "custom",
                          VAL: "unhandledError"
                        };
                }
              }), baseUrl$1(param[0], param[1]) + "/updateAddressPoint");
}

var Config$21 = {
  body_encode: body_encode$7,
  body_decode: body_decode$7,
  address_encode: address_encode$5,
  address_decode: address_decode$5,
  contact_encode: contact_encode,
  contact_decode: contact_decode,
  coordinates_encode: coordinates_encode,
  coordinates_decode: coordinates_decode,
  response_encode: response_encode$21,
  response_decode: response_decode$21,
  exec: exec$21
};

var $$Request$21 = Toolkit__Request.Make({
      exec: exec$21
    });

var UpdateLocation = {
  $$Error: $$Error$4,
  AddressType: AddressType,
  Config: Config$21,
  $$Request: $$Request$21
};

function body_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "message",
                    false,
                    Spice.stringToJson(v.message)
                  ]]));
}

function body_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var message = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
  if (message.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              message: message._0
            }
          };
  }
  var e = message._0;
  return {
          TAG: "Error",
          _0: {
            path: ".message" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$22 = Spice.unitToJson;

var response_decode$22 = Spice.unitFromJson;

function exec$22(param) {
  return Axios.WithResult.post(undefined, body_encode$8(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$22, undefined, baseUrl$1(param[0], param[1]) + "/comments");
}

var Config$22 = {
  body_encode: body_encode$8,
  body_decode: body_decode$8,
  response_encode: response_encode$22,
  response_decode: response_decode$22,
  exec: exec$22
};

var $$Request$22 = Toolkit__Request.Make({
      exec: exec$22
    });

var CreateComment = {
  Config: Config$22,
  $$Request: $$Request$22
};

var response_encode$23 = Spice.unitToJson;

var response_decode$23 = Spice.unitFromJson;

function exec$23(param) {
  return Axios.WithResult.put(undefined, {}, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$23, undefined, baseUrl$1(param[0], param[1]) + "/cancel");
}

var Config$23 = {
  response_encode: response_encode$23,
  response_decode: response_decode$23,
  exec: exec$23
};

var $$Request$23 = Toolkit__Request.Make({
      exec: exec$23
    });

var CancelDelivery = {
  Config: Config$23,
  $$Request: $$Request$23
};

function t_encode$11(v) {
  switch (v) {
    case "store_not_found" :
        return "store_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "not_authorized_to_reschedule" :
        return "not_authorized_to_reschedule";
    case "impossible_to_reschedule" :
        return "impossible_to_reschedule";
    
  }
}

function t_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("not_authorized_to_reschedule" === v) {
          return {
                  TAG: "Ok",
                  _0: "not_authorized_to_reschedule"
                };
        } else if ("impossible_to_reschedule" === v) {
          return {
                  TAG: "Ok",
                  _0: "impossible_to_reschedule"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$5 = {
  t_encode: t_encode$11,
  t_decode: t_decode$11
};

function body_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "startDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.startDate)
                  ],
                  [
                    "endDate",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.endDate)
                  ]
                ]));
}

function body_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var startDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "startDate"), null));
  if (startDate.TAG === "Ok") {
    var endDate = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "endDate"), null));
    if (endDate.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                startDate: startDate._0,
                endDate: endDate._0
              }
            };
    }
    var e = endDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".endDate" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = startDate._0;
  return {
          TAG: "Error",
          _0: {
            path: ".startDate" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$24(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "rescheduleDeliverySlotRequestId",
                    false,
                    Spice.stringToJson(v.rescheduleDeliverySlotRequestId)
                  ],
                  [
                    "calendar",
                    false,
                    Spice.arrayToJson(calendar_encode, v.calendar)
                  ]
                ]));
}

function response_decode$24(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var rescheduleDeliverySlotRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "rescheduleDeliverySlotRequestId"), null));
  if (rescheduleDeliverySlotRequestId.TAG === "Ok") {
    var calendar = Spice.arrayFromJson(calendar_decode, Belt_Option.getWithDefault(Js_dict.get(v, "calendar"), null));
    if (calendar.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                rescheduleDeliverySlotRequestId: rescheduleDeliverySlotRequestId._0,
                calendar: calendar._0
              }
            };
    }
    var e = calendar._0;
    return {
            TAG: "Error",
            _0: {
              path: ".calendar" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = rescheduleDeliverySlotRequestId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".rescheduleDeliverySlotRequestId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$24(param) {
  return Axios.WithResult.post(undefined, body_encode$9(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$24, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$11(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), baseUrl$1(param[0], param[1]) + "/reschedulingCalendar");
}

var Config$24 = {
  body_encode: body_encode$9,
  body_decode: body_decode$9,
  response_encode: response_encode$24,
  response_decode: response_decode$24,
  exec: exec$24
};

var $$Request$24 = Toolkit__Request.Make({
      exec: exec$24
    });

var GetReschedulingCalendar = {
  $$Error: $$Error$5,
  Config: Config$24,
  $$Request: $$Request$24
};

function t_encode$12(v) {
  switch (v) {
    case "store_not_found" :
        return "store_not_found";
    case "client_not_found" :
        return "client_not_found";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "reschedule_slot_not_found" :
        return "reschedule_slot_not_found";
    case "the_request_is_expired" :
        return "the_request_is_expired";
    case "rescheduling_not_authorized" :
        return "rescheduling_not_authorized";
    
  }
}

function t_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("store_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "store_not_found"
                };
        } else if ("client_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "client_not_found"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("reschedule_slot_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "reschedule_slot_not_found"
                };
        } else if ("the_request_is_expired" === v) {
          return {
                  TAG: "Ok",
                  _0: "the_request_is_expired"
                };
        } else if ("rescheduling_not_authorized" === v) {
          return {
                  TAG: "Ok",
                  _0: "rescheduling_not_authorized"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$6 = {
  t_encode: t_encode$12,
  t_decode: t_decode$12
};

function body_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "rescheduleDeliverySlotRequestId",
                    false,
                    Spice.stringToJson(v.rescheduleDeliverySlotRequestId)
                  ],
                  [
                    "slotOptionId",
                    false,
                    Spice.stringToJson(v.slotOptionId)
                  ],
                  [
                    "notifyPickup",
                    false,
                    Spice.boolToJson(v.notifyPickup)
                  ],
                  [
                    "notifyDropoff",
                    false,
                    Spice.boolToJson(v.notifyDropoff)
                  ]
                ]));
}

function body_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var rescheduleDeliverySlotRequestId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "rescheduleDeliverySlotRequestId"), null));
  if (rescheduleDeliverySlotRequestId.TAG === "Ok") {
    var slotOptionId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "slotOptionId"), null));
    if (slotOptionId.TAG === "Ok") {
      var notifyPickup = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "notifyPickup"), null));
      if (notifyPickup.TAG === "Ok") {
        var notifyDropoff = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "notifyDropoff"), null));
        if (notifyDropoff.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    rescheduleDeliverySlotRequestId: rescheduleDeliverySlotRequestId._0,
                    slotOptionId: slotOptionId._0,
                    notifyPickup: notifyPickup._0,
                    notifyDropoff: notifyDropoff._0
                  }
                };
        }
        var e = notifyDropoff._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".notifyDropoff" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = notifyPickup._0;
      return {
              TAG: "Error",
              _0: {
                path: ".notifyPickup" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = slotOptionId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".slotOptionId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = rescheduleDeliverySlotRequestId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".rescheduleDeliverySlotRequestId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$25(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveryId",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.deliveryId)
                  ]]));
}

function response_decode$25(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryId = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
  if (deliveryId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveryId: deliveryId._0
            }
          };
  }
  var e = deliveryId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$25(param) {
  return Axios.WithResult.post(undefined, body_encode$10(param[2]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$25, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = t_decode$12(match.data.type);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), baseUrl$1(param[0], param[1]) + "/reschedule");
}

var Config$25 = {
  body_encode: body_encode$10,
  body_decode: body_decode$10,
  response_encode: response_encode$25,
  response_decode: response_decode$25,
  exec: exec$25
};

var $$Request$25 = Toolkit__Request.Make({
      exec: exec$25
    });

var RescheduleDelivery = {
  $$Error: $$Error$6,
  Config: Config$25,
  $$Request: $$Request$25
};

function priceComposition_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "amount",
                    false,
                    Spice.floatToJson(v.amount)
                  ],
                  [
                    "label",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.label)
                  ]
                ]));
}

function priceComposition_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var amount = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "amount"), null));
  if (amount.TAG === "Ok") {
    var label = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                amount: amount._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = amount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".amount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$26(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "priceWithoutTaxEur",
                    false,
                    Spice.floatToJson(v.priceWithoutTaxEur)
                  ],
                  [
                    "priceWithTaxEur",
                    false,
                    Spice.floatToJson(v.priceWithTaxEur)
                  ],
                  [
                    "priceWithoutTaxEurComposition",
                    false,
                    Spice.arrayToJson(priceComposition_encode$2, v.priceWithoutTaxEurComposition)
                  ],
                  [
                    "customerPriceWithTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.customerPriceWithoutTaxEur)
                  ],
                  [
                    "customerPriceWithoutTaxEurComposition",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(priceComposition_encode$2, param);
                          }), v.customerPriceWithoutTaxEurComposition)
                  ]
                ]));
}

function response_decode$26(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var priceWithoutTaxEur = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEur"), null));
  if (priceWithoutTaxEur.TAG === "Ok") {
    var priceWithTaxEur = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "priceWithTaxEur"), null));
    if (priceWithTaxEur.TAG === "Ok") {
      var priceWithoutTaxEurComposition = Spice.arrayFromJson(priceComposition_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "priceWithoutTaxEurComposition"), null));
      if (priceWithoutTaxEurComposition.TAG === "Ok") {
        var customerPriceWithTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithTaxEur"), null));
        if (customerPriceWithTaxEur.TAG === "Ok") {
          var customerPriceWithoutTaxEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEur"), null));
          if (customerPriceWithoutTaxEur.TAG === "Ok") {
            var customerPriceWithoutTaxEurComposition = Spice.optionFromJson((function (param) {
                    return Spice.arrayFromJson(priceComposition_decode$2, param);
                  }), Belt_Option.getWithDefault(Js_dict.get(v, "customerPriceWithoutTaxEurComposition"), null));
            if (customerPriceWithoutTaxEurComposition.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        priceWithoutTaxEur: priceWithoutTaxEur._0,
                        priceWithTaxEur: priceWithTaxEur._0,
                        priceWithoutTaxEurComposition: priceWithoutTaxEurComposition._0,
                        customerPriceWithTaxEur: customerPriceWithTaxEur._0,
                        customerPriceWithoutTaxEur: customerPriceWithoutTaxEur._0,
                        customerPriceWithoutTaxEurComposition: customerPriceWithoutTaxEurComposition._0
                      }
                    };
            }
            var e = customerPriceWithoutTaxEurComposition._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".customerPriceWithoutTaxEurComposition" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = customerPriceWithoutTaxEur._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".customerPriceWithoutTaxEur" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = customerPriceWithTaxEur._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".customerPriceWithTaxEur" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = priceWithoutTaxEurComposition._0;
      return {
              TAG: "Error",
              _0: {
                path: ".priceWithoutTaxEurComposition" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = priceWithTaxEur._0;
    return {
            TAG: "Error",
            _0: {
              path: ".priceWithTaxEur" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = priceWithoutTaxEur._0;
  return {
          TAG: "Error",
          _0: {
            path: ".priceWithoutTaxEur" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function exec$26(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, {
                    slotOptionId: param[1],
                    deliveryRequestId: param[2]
                  }, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$26, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var match$1 = match.data.type;
                if (match$1 === "the_request_is_expired") {
                  return {
                          NAME: "custom",
                          VAL: "deliveryPriceExpired"
                        };
                } else {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
              }), "/clients/" + param[0] + "/getSlotOptionPriceComposition");
}

var Config$26 = {
  priceComposition_encode: priceComposition_encode$2,
  priceComposition_decode: priceComposition_decode$2,
  response_encode: response_encode$26,
  response_decode: response_decode$26,
  exec: exec$26
};

var $$Request$26 = Toolkit__Request.Make({
      exec: exec$26
    });

var GetSlotOptionPriceComposition = {
  Config: Config$26,
  $$Request: $$Request$26
};

var Delivery = {
  baseUrl: baseUrl$1,
  ModificationHistory: ModificationHistory,
  GetDelivery: GetDelivery,
  GetEligibleTimeSlots: GetEligibleTimeSlots$1,
  UpdateTimeSlot: UpdateTimeSlot,
  GetChangesHistory: GetChangesHistory,
  GetPackets: GetPackets,
  GetPictures: GetPictures,
  GetComments: GetComments,
  UpdateLocation: UpdateLocation,
  CreateComment: CreateComment,
  CancelDelivery: CancelDelivery,
  GetReschedulingCalendar: GetReschedulingCalendar,
  RescheduleDelivery: RescheduleDelivery,
  GetSlotOptionPriceComposition: GetSlotOptionPriceComposition
};

var Coordinates;

export {
  Coordinates ,
  config ,
  baseUrl ,
  DeliveryEligibility ,
  GetClient ,
  GetClientNames ,
  GetStores ,
  GetStoreAddressByStoreCode ,
  SearchStore ,
  SearchCustomer ,
  GetStates ,
  GetOrderDeliveriesForClient ,
  GetDeliveries ,
  PostExcelExport ,
  GetDeliveriesCountByStores ,
  Delivery ,
}
/* Request Not a pure module */
